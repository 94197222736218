.contact-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 2050px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contact-i .BG-elements {
  height: 3200px;
  align-self: center;
  position: absolute;
  top: 0;
  width: 768px;
}

.contact-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.contact-i .footer {
  height: 275px;
  left: 29px;
  position: absolute;
  top: 3163px;
  width: 709px;
}

.contact-i .if-container {
  width: 768px;
  position: relative;
  top: -330px;
}

.contact-i .start {
  height: 289px;
  left: 29px;
  position: absolute;
  top: 2525px;
  width: 709px;
}

.contact-i .mouse {
  height: 428px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 768px;
}

.contact-i .hero {
  height: 607px;
  position: absolute;
  top: 120px;
  width: 768px;
}

.contact-i .overlap-5 {
  height: 598px;
  position: relative;
}

.contact-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.contact-i .gradient-2 {
  height: 300px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 709px;
}

.contact-i .noize {
  height: 3524px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.contact-i .nav-spacer {
  height: 151px;
  margin-top: -43px;
  position: relative;
  width: 768px;
}

.contact-i .main {
  height: 427px;
  margin-top: -43px;
  position: relative;
  width: 760px;
}

.contact-i .ABOUT-US {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 137px;
  font-weight: 500;
  left: 66px;
  letter-spacing: -6.85px;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 122px;
  width: 623px;
}

.contact-i .section {
  align-self: center;
  width: 760px;
  height: 627px;
  margin-top: 13px;
  position: relative;
}

  .contact-i .overlap-2 {
    width: 670px;
    height: 472px;
    position: relative;
    top: 136px;
    left: 350px;
}

.contact-i .group {
  height: 471px;
  left: 280px;
  position: absolute;
  top: 1px;
  width: 390px;
}

.contact-i .overlap-3 {
  height: 471px;
  position: relative;
}

.contact-i .rectangle-2 {
  background-color: #170d5333;
  border-radius: 23px;
  filter: blur(43px);
  height: 454px;
  left: 15px;
  opacity: 0.5;
  position: absolute;
  top: 17px;
  width: 375px;
}

.contact-i .rectangle-3 {
  background-color: #ececec00;
  border-radius: 24px;
  width: 375px;
  height: 451px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-i .contact-block {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 396px;
  left: 35px;
  position: absolute;
  top: 28px;
  width: 303px;
}

.contact-i .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.contact-i .div-2 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 33px;
  letter-spacing: -0.4px;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  width: 103px;
}

.contact-i .rectangle-4 {
  background-color: #e5e4e4;
  border-radius: 6px;
  height: 33px;
  position: relative;
  width: 200px;
}

.contact-i .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  height: 84px;
  position: relative;
}

.contact-i .rectangle-5 {
  background-color: #e5e3e3;
  border-radius: 6px;
  height: 84px;
  position: relative;
  width: 200px;
}

.contact-i .read-button {
  all: unset;
  box-sizing: border-box;
  height: 28px;
  left: 144px;
  position: absolute;
  top: 359px;
  width: 161px;
  cursor: pointer;
}

.contact-i .read-the-docs-wrapper {
  background-color: #7f64fb;
  border-radius: 6px;
  height: 28px; 
  position: relative;
  width: 159px;
}

.contact-i .read-the-docs {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 151px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 4px;
}

.contact-i .TEXT-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 315px;
}

.contact-i .TEXT {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 34px 0px;
  position: relative;
  width: 259px;
}

.contact-i .text-wrapper-3 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  margin-top: -1px;
  position: relative;
  width: 259px;
}

.contact-i .our-team-can-help {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 259px;
}

.contact-i .main {
  width: 760px;
  height: 427px;
  position: relative;
}

.contact-i .ABOUT-US {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 137px;
  font-weight: 500;
  left: 66px;
  letter-spacing: -6.85px;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 122px;
  width: 623px;
}

.contact-i .group {
  height: 471px;
  left: 280px;
  position: absolute;
  top: 1px;
  width: 390px;
}

.contact-i .our-team-can-help ul {
  list-style-type: disc;
  padding-left: 20px;
}

.contact-i .our-team-can-help li {
  margin-bottom: 10px; /* Adjust this value to increase or decrease the spacing */
}

.contact-i .our-team-can-help .custom-margin {
  margin-top: 25px; /* Adjust this value to increase or decrease the spacing */
}

.contact-i .overlap-3 {
  height: 471px;
  position: relative;
}

.contact-i .rectangle-2 {
  background-color: #170d5333;
  border-radius: 23px;
  filter: blur(43px);
  height: 454px;
  left: 15px;
  opacity: 0.5;
  position: absolute;
  top: 17px;
  width: 375px;
}

.contact-i .rectangle-3 {
  background-color: #ededed;
  border-radius: 24px;
  height: 451px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.contact-i .contact-block {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 396px;
  left: 35px;
  position: absolute;
  top: 28px;
  width: 303px;
}

.contact-i .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.contact-i .div-2 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 33px;
  letter-spacing: -0.4px;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  width: 103px;
}

.contact-i .rectangle-4 {
  background-color: #e5e4e4;
  border-radius: 6px;
  height: 33px;
  position: relative;
  width: 200px;
}

.contact-i .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  height: 84px;
  position: relative;
}

.contact-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.contact-i .text-wrapper-2 {
  color: #000;
  font-size: 26px;
  font-weight: 400;
  font-family: "Titillium Web", Helvetica;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.contact-i .rectangle-5 {
  background-color: #e5e3e3;
  border-radius: 6px;
  height: 84px;
  position: relative;
  width: 200px;
}

.contact-i .TEXT-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 315px;
}

.contact-i .TEXT {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 34px 0px;
  position: relative;
  width: 259px;
}

.contact-i .text-wrapper-3 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  margin-top: -1px;
  position: relative;
  width: 259px;
}

.contact-i .our-team-can-help {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 259px;
}

.contact-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -50px;
}

.contact-i .TEXT-wrapper {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 75px;
  position: absolute;
  top: 140px;
  width: 315px;
}

.contact-i .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.contact-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.contact-i .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 315px;
  z-index: 9;
}

.contact-i .text-wrapper-19 {
  color: #272936;
}

.contact-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.contact-i .frame-7 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 409px;
  position: absolute;
  top: 140px;
  width: 284px;
}

.contact-i .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: -209px;
  position: relative;
  width: 493px;
}

.contact-i .line-4 {
    height: 1px;
    left: 75px;
    position: absolute;
    top: 74px;
    width: 618px;
  }