.error-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 1200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-i .BG-elements {
  height: 2013px;
  align-self: center;
  position: absolute;
  top: 0;
  width: 768px;
}

.error-i .overlap-4 {
  height: 1078px;
  position: relative;
}

.error-i .footer {
  height: 275px;
  left: 29px;
  position: absolute;
  top: 3163px;
  width: 709px;
}

.error-i .start {
  height: 289px;
  left: 29px;
  position: absolute;
  top: 2525px;
  width: 709px;
}

.error-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.error-i .rectangle-8 {
  height: 426px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 709px;
}

.error-i .gradient {
  height: 200px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.error-i .if-container {
  width: 768px;
  position: relative;
  top: 350px;
}

.error-i .mouse {
  height: 428px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 768px;
}

.error-i .hero {
  width: 709px;
  height: 957px;
  position: absolute;
  top: 121px;
  left: 205px;
}

.error-i .section-8 {
  height: 3786px;
  margin-top: -43px;
  position: relative;
  width: 764px;
}

.error-i .overlap-5 {
  height: 598px;
  position: relative;
}

.error-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: -175px;
}

.error-i .gradient-2 {
  height: 300px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 709px;
}

.error-i .noize {
  height: 3524px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.error-i .nav-spacer {
  height: 151px;
  margin-top: -43px;
  position: relative;
  width: 768px;
}



.error-i .overlap-11 {
  height: 1078px;
  position: relative;
}



.error-i .overlap-group-11 {
  height: 868px;
  position: relative;
}

.error-i .rectangle-14 {
  height: 866px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 709px;
}

.error-i .gragient-2 { 
  height: 868px;
  left: 0;
  position: absolute;
  top: 0;
  width: 709px;
}

.error-i .noize-2 {
  height: 1014px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.error-i .nav-spacer-4 {
  height: 175px;
  margin-top: -43px;
  position: relative;
  width: 768px;
}

.error-i .main-3 {
  height: 427px;
  margin-top: -43px;
  position: relative;
  width: 760px;
}

.error-i .overlap-12 {
  height: 752px;
  left: 66px;
  position: relative;
  top: 122px;
  width: 731px;
}

.error-i .group-4 {
  height: 657px;
  left: 74px;
  position: absolute;
  top: 95px;
  width: 657px;
}

.error-i .gummy-rayman-wrapper {
  background-image: url(/dist/img/gummy-rayman-adventures-sebastien-camrrubi-removebg-preview-1-2.webp); 
  background-position: 50%;
  background-size: cover;
  width: 678px;
  height: 676px;
  position: absolute;
  top: 80px;
  left: 5px;
}

.error-i .gummy-rayman {
  height: 372px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 304px;
  width: 349px;
}

.error-i .ABOUT-US-3 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 137px;
  font-weight: 500;
  left: 0;
  letter-spacing: -6.85px;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 0;
  white-space: nowrap;
  width: 355px;
}

.error-i .go-back-instance {
left: 5px !important;
position: absolute !important;
top: 213px !important;
display: flex; /* Use Flexbox */
justify-content: center; /* Align horizontally */
align-items: center; /* Align vertically */
width: 150px; /* Adjust the width as needed */
height: 40px; /* Adjust the height as needed */
background-color: transparent; /* Initial background color */ 
border: 0.5px solid #7F64FB; /* Add border */
border-radius: 10px;
padding: 10px 20px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.error-i .go-back-instance:hover {
background-color: #7F64FB; /* Hover background color */
}

.error-i .go-back-2-text {
  color: white;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
}

.error-i .text-wrapper-31 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 5px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  top: 168px;
  width: 504px;
}

.error-i .text-wrapper-32 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 34px;
  font-weight: 100;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 6px;
  width: 501px;
}

.error-i .NAV-4 {
  background-color: #f3f3f3;
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.error-i .frame-20 {
  align-items: center;
  display: flex;
  gap: 262px;
  left: 29px;
  position: relative;
  top: 47px;
  width: 709px;
}

.error-i .xion-logo-4 {
  height: 30.71px;
  position: relative;
  width: 156.98px;
}

.error-i .frame-21 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  margin-right: -1px;
  position: relative;
}

.error-i .GS-button-3 {
  all: unset;
  box-sizing: border-box;
  height: 39px;
  position: relative;
  width: 228.02px;
}

.error-i .overlap-group-12 {
  background-size: 100% 100%;
  height: 39px;
  position: relative;
  width: 226px;
}

.error-i .rectangle-15 {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 226px;
}

.error-i .get-started-7 {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 27px;
  left: 0;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 226px;
}

.error-i .div-7 {
  height: 39px;
  position: relative;
  width: 45px;
}

.error-i .shadows-5 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.error-i .shadows-6 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.error-i .rectangle-16 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.error-i .group-5 {
  height: 15px;
  left: 14px;
  position: absolute;
  top: 13px;
  width: 21px;
}


.error-i .line-4 {
  height: 1px;
  left: 75px;
  position: absolute;
  top: 74px;
  width: 618px;
}

.error-i .overlap-8 {
  height: 154px;
  left: 137px;
  position: absolute;
  top: 145px;
  width: 323px;
}

.error-i .text-wrapper-21 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 66px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 224px;
}

.error-i .text-wrapper-22 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 66px;
  font-weight: 500;
  height: 79px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 75px;
  white-space: nowrap;
  width: 323px;
}

.error-i .footer-section {
  height: 344px;
  position: relative;
  width: 768px;
}

.error-i .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 307px;
  left: 144px;
  position: relative;
  top: 41px;
}

.error-i .XG-logo {
  height: 31.84px;
  position: relative;
  width: 155.66px;
}

.error-i .sections {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
}

.error-i .div-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.error-i .text-wrapper-23 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.error-i .text-wrapper-24 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.error-i .follow-us {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  position: relative;
}

.error-i .follow-us-2 {
  height: 81.5px;
  position: relative;
  width: 98.78px;
}

.error-i .text-wrapper-25 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 68px;
}

.error-i .social {
  align-items: flex-start;
  display: inline-flex;
  gap: 7px;
  left: 0;
  position: absolute;
  top: 35px;
}

.error-i .telegram-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-left: -0.5px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.error-i .twitter-footer {
  height: 21px !important;
  margin-bottom: -0.5px !important;
  margin-top: -0.5px !important;
  position: relative !important;
  width: 21px !important;
}

.error-i .medium-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.error-i .linkedin-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-right: -0.5px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.error-i .text-wrapper-26 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.error-i .t-cs {
  height: 17.49px;
  position: relative;
  width: 61px;
}

.error-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.error-i .text-wrapper-27 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.error-i .all-rights-reserved {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
}

.error-i .NAV {
  background-color: #f3f3f3;
  height: 120px;
  left: 50%;
  position: fixed; /* Change from 'absolute' to 'fixed' */
  align-self: center;
  top: 0;
  transform: translateX(-50%); /* Center horizontally using transform */
  width: 768px;
  z-index: 1000; /* Add a high z-index to make sure it's above other content */
}

.error-i .frame-9 {
  align-items: center;
  display: flex;
  gap: 732px;
  left: 50px;
  position: relative;
  top: 47px;
  width: 1179px;
}

.error-i .xion-logo {
  height: 30.71px;
  position: relative;
  width: 156.98px;
}

.error-i .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  margin-right: -0.98px;
  position: relative;
}

.error-i .frame-wrapper {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.error-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.error-i .get-started-wrapper {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.error-i .get-started-3 {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.error-i .div-7 {
  height: 39px;
  position: relative;
  width: 45px;
}

.error-i .shadows {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.error-i .shadows-2 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.error-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.error-i .group-4 {
  height: 18px;
  left: 13px;
  position: absolute;
  top: 10px;
  width: 21px;
}
