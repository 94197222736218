.PaymentL-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3450px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PaymentL-m .BG-elements {
  height: 2926px;
  align-self: center;
  position: absolute;
  top: 0px;
  width: 379px;
}

.PaymentL-m .overlap-4 {
  height: 2925px;
  left: 14px;
  position: relative;
  top: 1px;
  width: 360px;
}

.PaymentL-m .footer {
  height: 195px;
  left: 17px;
  position: absolute;
  top: 2730px;
  width: 327px;
}

.PaymentL-m .start {
  height: 200px;
  left: 15px;
  position: absolute;
  top: 2530px;
  width: 327px;
}

.PaymentL-m .mf-container {
  width: 360px;
  position: relative;
}

.PaymentL-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PaymentL-m .rectangle-8 {
  height: 426px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 709px;
}

.PaymentL-m .gradient {
  height: 198px;
  left: 0;
  position: absolute;
  top: 0;
  width: 327px;
}

.PaymentL-m .hero {
  height: 795px;
  left: 17px;
  position: absolute;
  top: 120px;
  width: 327px;
}

.PaymentL-m .overlap-5 {
  height: 435px;
  position: relative;
}

.PaymentL-m .rectangle-9 {
  height: 435px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 327px;
}

.PaymentL-m .gradient-2 {
  height: 435px;
  left: 0;
  position: absolute;
  top: 0;
  width: 327px;
}

.PaymentL-m .noize {
  height: 2925px;
  left: 0;
  position: absolute;
  top: 0;
  width: 358px;
}

.PaymentL-m .nav-spacer {
  height: 175px;
  margin-top: -43px;
  position: relative;
  width: 360px;
}

.PaymentL-m .main {
  height: 384px;
  position: relative;
  width: 360px;
}

.PaymentL-m .overlap-6 {
  height: 654px;
  left: 137px;
  position: relative;
  top: 14px;
  width: 768px;
}

.PaymentL-m .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: 58px;
  left: 42px;
}

.PaymentL-m .HT {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 106px;
  position: relative;
  width: 289px;
}

.PaymentL-m .a-SIMPLE {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 33px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 289px;
}

.PaymentL-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 283.53px;
  margin-top: -16px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
  left: 19px;
}

.PaymentL-m .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 289px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.PaymentL-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 15px;
}

.PaymentL-m .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1630px;
  left: 66px;
}

.GS-button:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.PaymentL-m .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-m .element-of {
  height: 485px;
  left: 255px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 438px;
}

.PaymentL-m .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.PaymentL-m .TEXT-BLOCK {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 1582px;
  left: 23px;
  position: relative;
  top: 137px;
  width: 315px;
}

.PaymentL-m .line-2 {
  height: 1px;
  left: 17px;
  position: absolute;
  top: 74px;
  width: 326px;
}

.PaymentL-m .TEXT {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.PaymentL-m .frame-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 315px;
}

.PaymentL-m .component-4 {
  width: 243px;
  height: 96px;
}

.PaymentL-m .text-wrapper-15 {
  color: #7f64fb;
  font-family: "Titillium Web", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 20.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 315px;
}

.PaymentL-m .text-wrapper-16 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  position: relative;
  text-align: center;
  width: 315px;
}

.PaymentL-m .text-wrapper-17 {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  text-align: center;
  width: 315px;
}

.PaymentL-m .line-3 {
  height: 53.17px;
  object-fit: cover;
  position: relative;
  width: 5.33px;
}

.PaymentL-m .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.PaymentL-m .frame-49 {
  left: 0 !important;
  position: absolute !important;
  top: 66px !important; 
}

.PaymentL-m .frame-5 {
  background-size: 100% 100%;
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 250px;
}

.PaymentL-m .text-wrapper-18 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  left: 15px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 11px;
}

.PaymentL-m .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PaymentL-m .group-9 {
  height: 163px;
  left: 445px;
  overflow: hidden;
  position: absolute;
  top: 428px;
  width: 239px;
}

.PaymentL-m .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PaymentL-m .overlap-wrapper {
  height: 164px;
  left: 438px;
  overflow: hidden;
  position: absolute;
  top: 713px;
  width: 267px;
}

.PaymentL-m .overlap-7 {
  height: 166px;
  left: 1px;
  position: relative;
  top: -2px;
  width: 403px;
}

.PaymentL-m .asset {
  height: 119px;
  left: 11px;
  position: absolute;
  top: 24px;
  width: 382px;
}

.PaymentL-m .clip-path-group {
  height: 98px;
  left: 21px;
  position: absolute;
  top: 34px;
  width: 356px;
}

.PaymentL-m .frame-lock {
  height: 89px;
  left: 443px;
  position: absolute;
  top: 1287px;
  width: 243px;
}

.PaymentL-m .WEB {
  color: #1185c3;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 83px;
  font-weight: 400;
  height: 80px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000026;
  top: -1px;
  white-space: nowrap;
  width: 117px;
}

.PaymentL-m .frame-36 {
  left: 121px !important;
  position: absolute !important;
  top: 0 !important;
}

.PaymentL-m .component-3 {
  height: 58px;
  left: 445px;
  position: absolute;
  top: 1593px;
  width: 234px;
}

.PaymentL-m .component-instance {
  left: 445px !important;
  position: absolute !important;
  top: 1017px !important;
}

.PaymentL-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
}

.PaymentL-m .TEXT-wrapper {
  align-items: center;
  display: flex;
  gap: 70px;
  justify-content: center;
  left: 23px;
  position: absolute;
  top: 110px;
  width: 315px;
}

.PaymentL-m .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.PaymentL-m .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 315px;
}

.PaymentL-m .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.PaymentL-m .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  text-align: center;
  width: 315px;
  z-index: 9;
}

.PaymentL-m .text-wrapper-19 {
  color: #272936;
}

.PaymentL-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PaymentL-m .frame-7 {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 70px;
  left: 23px;
  position: absolute;
  top: 220px;
  width: 315px;
}

.PaymentL-m .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.PaymentL-m .line-4 {
  height: 1px;
  left: 163px;
  position: absolute;
  top: 74px;
  width: 948px;
}

.PaymentL-m .overlap-8 {
  height: 154px;
  left: 137px;
  position: absolute;
  top: 145px;
  width: 323px;
}

.PaymentL-m .text-wrapper-21 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 66px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 224px;
}

.PaymentL-m .text-wrapper-22 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 66px;
  font-weight: 500;
  height: 79px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 75px;
  white-space: nowrap;
  width: 323px;
}

.PaymentL-m .footer-section {
  height: 344px;
  position: relative;
  width: 768px;
}

.PaymentL-m .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 307px;
  left: 144px;
  position: relative;
  top: 41px;
}

.PaymentL-m .XG-logo {
  height: 31.84px;
  position: relative;
  width: 155.66px;
}

.PaymentL-m .sections {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
}

.PaymentL-m .div-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.PaymentL-m .text-wrapper-23 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.PaymentL-m .text-wrapper-24 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.PaymentL-m .follow-us {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  position: relative;
}

.PaymentL-m .follow-us-2 {
  height: 81.5px;
  position: relative;
  width: 98.78px;
}

.PaymentL-m .text-wrapper-25 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 68px;
}

.PaymentL-m .social {
  align-items: flex-start;
  display: inline-flex;
  gap: 7px;
  left: 0;
  position: absolute;
  top: 35px;
}

.PaymentL-m .telegram-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-left: -0.5px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.PaymentL-m .twitter-footer {
  height: 21px !important;
  margin-bottom: -0.5px !important;
  margin-top: -0.5px !important;
  position: relative !important;
  width: 21px !important;
}

.PaymentL-m .medium-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.PaymentL-m .linkedin-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-right: -0.5px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.PaymentL-m .text-wrapper-26 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.PaymentL-m .t-cs {
  height: 17.49px;
  position: relative;
  width: 61px;
}

.PaymentL-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.PaymentL-m .text-wrapper-27 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.PaymentL-m .all-rights-reserved {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
}

.PaymentL-m .NAV {
  background-color: #f3f3f3;
  height: 120px;
  left: 50%;
  position: fixed; /* Change from 'absolute' to 'fixed' */
  align-self: center;
  top: 0;
  transform: translateX(-50%); /* Center horizontally using transform */
  width: 768px;
  z-index: 1000; /* Add a high z-index to make sure it's above other content */
}

.PaymentL-m .frame-9 {
  align-items: center;
  display: flex;
  gap: 732px;
  left: 50px;
  position: relative;
  top: 47px;
  width: 1179px;
}

.PaymentL-m .xion-logo {
  height: 30.71px;
  position: relative;
  width: 156.98px;
}

.PaymentL-m .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  margin-right: -0.98px;
  position: relative;
}

.PaymentL-m .frame-wrapper {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.PaymentL-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PaymentL-m .get-started-wrapper {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.PaymentL-m .get-started-3 {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.PaymentL-m .div-7 {
  height: 39px;
  position: relative;
  width: 45px;
}

.PaymentL-m .shadows {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.PaymentL-m .shadows-2 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.PaymentL-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.PaymentL-m .group-4 {
  height: 18px;
  left: 13px;
  position: absolute;
  top: 10px;
  width: 21px;
}

.PaymentL-m .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-m .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 150px;
}

.PaymentL-m .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 350px !important;
  left: -70px;
}

.GS-button-3:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-3-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.PaymentL-m .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-m .PL {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 2200px;
}

.PaymentL-m .HCC {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 1700px;
}

.PaymentL-m .frame-3a {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.PaymentL-m .TEXTa {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: -270px;
}

.PaymentL-m .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  left: 35px;
}

.PaymentL-m .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  left: 35px;
  top: -8px;
}

.PaymentL-m .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}

.PaymentL-m .bullet-list {
  list-style-type: none; /* Remove default bullet style */
  /* Customize bullet styles here */
}

.PaymentL-m .bullet-list li {
  padding-left: 20px;
  font-size: 10px;
  position: relative;
}

.PaymentL-m .bullet-list li::before {
  content: "\2022"; /* Use the bullet character as content */
  position: absolute;
  left: 0;
  color: #439be4;; /* Bullet point color */
  font-size: 14px; /* Bullet point size */
  /* Add other bullet point styles as needed */
}


.PaymentL-m .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 45px;
}

.PaymentL-m .frame-3b {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 315px;
}

.PaymentL-m .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  left: 35px;
  top: 40px;
}

.PaymentL-m .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  top: 35px;
  left: 35px;
}

.PaymentL-m .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.PaymentL-m .text-containera {
  display: flex;
  flex-direction: column; /* Stacks .TEXTa and .TEXTb vertically */
  gap: 35px; /* Adjust this as needed for spacing */
  /* ... other styles for the container ... */
}

.PaymentL-m .text-container-b {
  display: flex;
  flex-direction: column; /* Stacks .TEXTa and .TEXTb vertically */
  gap: 35px; /* Adjust this as needed for spacing */
  /* ... other styles for the container ... */
}

.PaymentL-m .df-container {
  width: 1280px;
  position: relative;
  top: 0px;
}

.PaymentL-m .text-wrapper-444 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.PaymentL-m .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: 35px;
  position: relative !important;
  top: -500px !important;
}

.GS-button:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.PaymentL-m .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}


.PaymentL-m .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: 35px;
  position: relative !important;
  top: 70px !important;
}


.GS-button-2:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-2-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.PaymentL-m .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-m .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}