.read-button-m {
  border-radius: 10px;
  height: 39px;
  left: 20px;
  position: relative;
  width: calc(100% - 20px);
  cursor: pointer;
}

.read-button-m .text-wrapper {
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 226px;
}

.read-button-m.default {
  background-color: #7f64fb;
  top: 20px;
}

.read-button-m.variant-2 {
  border: 1px solid;
  border-color: #7f64fb;
  top: 79px;
}

.read-button-m.default .text-wrapper {
  color: #ffffff;
}

.read-button-m.variant-2 .text-wrapper {
  color: #7f64fb;
}
