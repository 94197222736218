.CS-button-d {
  border: 1px solid;
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
  cursor: pointer;
}

.CS-button-d .get-started-2 {
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.CS-button-d.property-1-1-default {
  border-color: #14a5ed;
}

.CS-button-d.property-1-1-variant-2 {
  border-color: #7f64fb;
}

.CS-button-d.property-1-1-default .get-started-2 {
  color: #14a5ed;
}

.CS-button-d.property-1-1-variant-2 .get-started-2 {
  color: #7f64fb;
}
