.Refer-i {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 73px;
  position: relative;
}

.Refer-i .header {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.Refer-i .banner {
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgb(29, 222, 184) 0%,
    rgb(0, 155, 255) 100%
  );
  height: 32px;
  position: relative;
  width: 100%;
}

.Refer-i .refer-earn-for-your {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 5px;
  /* left: 688px; */
  /* align-content: center; */
  width: 100%;
}

.Refer-i .text-wrapper {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}

.Refer-i .span {
  font-weight: 700;
}

.Refer-i .nav {
  align-self: stretch;
  background-color: #ffffff;
  height: 96px;
  position: relative;
  width: 100%;
}

.Refer-i .container {
  width: 100%;
  height: 48px;
  position: relative;
  top: 24px;
  /* left: 104px; */
}

.Refer-i .div {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 100%;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.3s;
  position: absolute;
  top: 11px;
  right: 242px;
}

.div:hover,
.div.clicked {
  font-weight: bold;
}

@keyframes flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.Refer-i .button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 15%;
  height: 48px;
  padding: 16px 0;
  display: flex;
  position: absolute;
  top: 0;
  right: 50px;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff);
  background-size: 200% 200%;
  animation: flow 4s linear infinite;
}

.Refer-i .button:hover,
.Refer-i .button.clicked {
  transform: scale(1.2);
}

.Refer-i .text-wrapper-2 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: -3px;
  margin-top: -5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.Refer-i .logo {
  /* width: 241px; */
  height: 48px;
  position: absolute;
  top: 0;
  left: 64px;
}

.Refer-i .overlap-group {
  background-image: url(../../../../static/img/vectorreferral.svg);
  background-size: 100% 100%;
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 48px;
}

.Refer-i .vector {
  height: 33px;
  left: 4px;
  position: absolute;
  top: 7px;
  width: 26px;
}

.Refer-i .img {
  height: 29px;
  left: 9px;
  position: absolute;
  top: 7px;
  width: 7px;
}

.Refer-i .vector-2 {
  height: 17px;
  left: 28px;
  position: absolute;
  top: 14px;
  width: 17px;
}

.Refer-i .REFER-EARN {
  color: #1ddeb8;
  font-family: "Titillium Web", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 35px;
  left: 58px;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 181px;
}

.Refer-i .text-wrapper-3 {
  font-style: italic;
  font-weight: 600;
}

.Refer-i .text-wrapper-4 {
  font-style: italic;
  font-weight: 700;
}

.Refer-i .main {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 50px;
  width: 768px;
  display: flex;
  position: relative;
}

.Refer-i .image {
  border-radius: 40px;
  height: 356px;
  overflow: hidden;
  position: relative;
  width: 768px;
}

.Refer-i .overlap-group-wrapper {
  height: 342px;
  left: -1px;
  position: relative;
  width: 768px;
}

.Refer-i .overlap-group-2 {
  height: 342px;
  position: relative;
}

.Refer-i .bright-cartoon {
  height: 334px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 768px;
}

.Refer-i .refer-earn {
  height: 125px;
  left: 0;
  position: absolute;
  top: 218px;
  width: 768px;
}

.Refer-i .invite-a-friend-to {
  align-self: stretch;
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 60px;
  font-weight: 900;
  height: 151px;
  letter-spacing: 0;
  line-height: 60px;
  position: relative;
  text-align: center;
}

.Refer-i .text-wrapper-5 {
  color: #0b2738;
}

.Refer-i .text-wrapper-6 {
  color: #009bff;
}

.Refer-i .text-wrapper-7 {
  color: #1ddeb8;
}

.Refer-i .p {
  align-self: stretch;
  color: #737373;
  font-family: "Titillium Web", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 35px;
  letter-spacing: 0;
  line-height: 36px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.Refer-i .how-to {
  height: 402px;
  position: relative;
  width: 668px;
}

.Refer-i .element {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 668px;
}

.Refer-i .div-wrapper {
  background-color: #8870d0;
  border-radius: 9999px;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.Refer-i .text-wrapper-8 {
  color: #ffffff;
  font-family: "Nimbus Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 16px;
  left: 11px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 9px;
  white-space: nowrap;
  width: 9px;
}

.Refer-i .text-wrapper-9 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 24px;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 610px;
}

.Refer-i .element-2 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 50px;
  width: 670px;
}

.Refer-i .text-wrapper-10 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 24px;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 612px;
}

.Refer-i .element-3 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 668px;
}

.Refer-i .element-4 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 150px;
  width: 670px;
}

.Refer-i .element-5 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 670px;
}

.Refer-i .element-6 {
  background-color: #8870d0;
  border-radius: 9999px;
  height: 32px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 32px;
}

.Refer-i .your-friend-has-to {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 34px;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 612px;
}

.Refer-i .element-7 {
  height: 36px;
  left: 0;
  position: absolute;
  top: 276px;
  width: 668px;
}

.Refer-i .text-wrapper-11 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 36px;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 610px;
}

.Refer-i .element-8 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 333px;
  width: 668px;
}

.Refer-i .button-2 {
  all: unset;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff);
  background-size: 200% 200%;
  animation: flow 4s linear infinite;
  border-radius: 8px;
  box-sizing: border-box;
  height: 56px;
  position: relative;
  width: 251.36px;
  cursor: pointer;
}

.Refer-i .button-2:hover,
.Refer-i .button-2.clicked {
  transform: scale(1.2);
}

.Refer-i .text-wrapper-12 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  left: 48px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 15px;
  white-space: nowrap;
  width: 156px;
}

.Refer-i .rules {
  width: 768px;
  height: 168px;
  position: relative;
  margin-bottom: 46px;
}

.Refer-i .text-wrapper-13 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 32px;
  left: 353px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: -1px;
  white-space: nowrap;
  width: 61px;
}

.Refer-i .frame {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 56px;
}

.Refer-i .background {
  background-color: #f2f2f2;
  border-radius: 12px;
  width: 245.33px;
  height: 140px;
  position: relative;
}

.Refer-i .the-person-you-refer {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 80px;
  left: 27px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 12px;
  width: 192px;
}

.Refer-i .your-referred-friend {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  height: 80px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 12px;
  left: 18px;
}

.Refer-i .the-you-and-your {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 192px;
  height: 60px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 14px;
  left: 27px;
}

.Refer-i .read-the-full-t-s-c {
  align-self: stretch;
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.Refer-i .text-wrapper-14 {
  color: #000000;
}

.Refer-i .text-wrapper-15 {
  color: #2482c5;
  text-decoration: underline;
}

.Refer-i .footer {
  align-items: flex-start;
  align-self: stretch;
  background-color: #0b2738;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 71px;
  padding: 51px 104px;
  position: relative;
  width: 100%;
}

.Refer-i .group {
  width: 100%;
  height: 64px;
  margin-right: -0.38px;
  position: relative;
}

.Refer-i .frame-2 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.Refer-i .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 14px;
  position: relative;
}

.Refer-i .logo-2 {
  height: 25.31px;
  position: relative;
  width: 236.88px;
}

.Refer-i .overlap-group-3 {
  background-image: url(../../../../static/img/vector-4r.svg);
  background-size: 100% 100%;
  width: 56px;
  height: 55px;
  position: absolute;
  top: -4px;
  left: -31px;
}

.Refer-i .vector-3 {
  height: 17px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 14px;
}

.Refer-i .vector-4 {
  height: 15px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 4px;
}

.Refer-i .vector-5 {
  height: 9px;
  left: 15px;
  position: absolute;
  top: 7px;
  width: 9px;
}

.Refer-i .REFER-EARN-2 {
  color: #1ddeb8;
  font-family: "Titillium Web", Helvetica;
  font-size: 23px;
  font-weight: 400;
  height: 18px;
  left: 30px;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 205px;
}

.Refer-i .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 96px;
  position: relative;
}

.Refer-i .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 157.01px;
}

.Refer-i .text-wrapper-16 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.Refer-i .text-wrapper-17 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
}

.Refer-i .frame-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 99.05px;
}

.Refer-i .frame-7 {
  flex: none;
  height: 24px;
  position: absolute;
  top: 218px;
  right: 6px;
}

.Refer-i .frame-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 135px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.Refer-i .fantasy-go-pty-ltd {
  color: #999;
  letter-spacing: 0;
  width: 209.01px;
  height: 135px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  padding-right: 5px;
}

.Refer-i .frame-9 {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 992px;
  display: flex;
  position: relative;
}

.Refer-i .DISCLAIMER {
  color: #999999;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 45px;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: -1px;
  position: relative;
  width: 768px;
}

.Refer-i .link {
  width: 94.95px;
  height: 15px;
  position: relative;
}

.Refer-i .national-responsible {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  /* width: 246px; */
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  /* top: -2px; */
  left: 0;
}

.Refer-i .national-gambling-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 102px;
  height: 15px;
  position: relative;
}

.Refer-i .national-gambling {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  /* width: 29px; */
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-i .financial-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.13px;
  height: 15px;
  position: relative;
}

.Refer-i .financial {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 9px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  white-space: nowrap;
}

.Refer-i .fantasygo-self-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 155.45px;
}

.Refer-i .fantasygo-self {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  /* width: 147px; */
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  /* top: -2px; */
  left: 9px;
}

.Refer-i .highlight {
  animation: flash 2s infinite;
}

@keyframes flash {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgb(0, 128, 255);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px #00ffae;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgb(0, 255, 47);
  }
}
