.CheckoutB-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3850px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CheckoutB-d .BG-elements {
  height: 3374px;
  align-self: center;
  position: absolute;
  top: 0;
  width: 1280px;
}

.CheckoutB-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.CheckoutB-d .footer {
  height: 315px;
  left: 50px;
  position: absolute;
  top: 3163px;
  width: 1179px;
}

.CheckoutB-d .start {
  height: 638px;
  left: 50px;
  position: absolute;
  top: 2525px;
  width: 1179px;
}

.CheckoutB-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.CheckoutB-d .rectangle-8 {
  height: 426px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 1179px;
}

.CheckoutB-d .gradient {
  height: 638px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1179px;
}

.CheckoutB-d .mouse {
  height: 428px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 1179px;
}

.CheckoutB-d .hero {
  height: 607px;
  left: 50px;
  position: absolute;
  top: 120px;
  width: 1180px;
}

.CheckoutB-d .overlap-5 {
  height: 598px;
  position: relative;
}

.CheckoutB-d .rectangle-9 {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1179px;
}

.CheckoutB-d .gradient-2 {
  height: 500px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1179px;
}

.CheckoutB-d .noize {
  height: 3524px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1280px;
}

.CheckoutB-d .nav-spacer {
  height: 151px;
  margin-top: -43px;
  position: relative;
  width: 1280px;
}

.CheckoutB-d .main {
  height: 574px;
  margin-top: -43px;
  position: relative;
  width: 1280px;
}

.CheckoutB-d .overlap-6 {
  height: 654px;
  left: 137px;
  position: relative;
  top: 14px;
  width: 1143px;
}

.CheckoutB-d .text-block {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 129px;
}

.CheckoutB-d .HT {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 200px;
  position: relative;
  width: 611px;
}

.CheckoutB-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.CheckoutB-d .FREE-SIMPLE {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 57px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  text-fill-color: transparent;
  width: 606.85px;
}

.CheckoutB-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -20px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.CheckoutB-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
  margin-top: 25px;
}

.CheckoutB-d .element-of {
  height: 654px;
  left: 543px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 600px;
}

.CheckoutB-d .section {
  align-self: center;
  height: 1785px;
  position: relative;
  width: 1280px;
}

.CheckoutB-d .TEXT-BLOCK {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 35px;
  height: 1525px;
  left: 163px;
  position: absolute;
  top: 207px;
}

.CheckoutB-d .TEXT {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 493px;
}

.CheckoutB-d .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 830px;
}

.CheckoutB-d .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.CheckoutB-d .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1005px;
  left: 750px;
}

.CheckoutB-d .text-wrapper-15 {
  color: #7f64fb;
  font-family: "Titillium Web", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 20.4px;
  margin-top: -1px;
  position: relative;
  width: 491px;
}

.CheckoutB-d .text-wrapper-16 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  position: relative;
  width: 493px;
}

.CheckoutB-d .text-wrapper-17 {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 491px;
}

.CheckoutB-d .line-3 {
  height: 53.17px;
  margin-left: -2.67px;
  object-fit: cover;
  position: relative;
  width: 5.33px;
}

.CheckoutB-d .frame-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  left: 600px;
  top: -40px;
}

.CheckoutB-d .frame-49 {
  left: 0 !important;
  position: absolute !important;
  top: 66px !important;
}

.CheckoutB-d .frame-5 {
  background-size: 100% 100%;
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 250px;
}

.CheckoutB-d .text-wrapper-18 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  left: 15px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 11px;
}

.CheckoutB-d .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.CheckoutB-d .group-9 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 250px;
  left: 600px;
}

.CheckoutB-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
  left: -20px;
}

.CheckoutB-d .overlap-wrapper {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 500px;
  left: 600px;
}

.CheckoutB-d .overlap-7 {
  height: 176px;
  position: relative;
  top: -2px;
  left: 0px;
}

.CheckoutB-d .asset {
  height: 119px;
  left: 11px;
  position: absolute;
  top: 24px;
  width: 382px;
}

.CheckoutB-d .clip-path-group {
  height: 98px;
  left: 21px;
  position: absolute;
  top: 34px;
  width: 356px;
}

.CheckoutB-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 752px;
}

.CheckoutB-d .WEB {
  color: #1185c3;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 83px;
  font-weight: 400;
  height: 80px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000026;
  top: -1px;
  white-space: nowrap;
  width: 117px;
}

.CheckoutB-d .frame-36 {
  left: 121px !important;
  position: absolute !important;
  top: 0 !important;
}

.CheckoutB-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1600px !important;
  left: 682px !important;
}

.CheckoutB-d .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1700px !important;
  left: 865px !important;
}

.GS-button:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.CheckoutB-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-d .component-instance {
  left: 846px;
  position: absolute;
  top: 1039px;
}

.CheckoutB-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
  top: 250px;
}

.CheckoutB-d .TEXT-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 70px;
  left: 163px;
  position: absolute;
  top: 140px;
}

.CheckoutB-d .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 493px;
}

.CheckoutB-d .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1px;
  position: relative;
  width: 493px;
}

.CheckoutB-d .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.CheckoutB-d .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 491px;
  z-index: 9;
}

.CheckoutB-d .text-wrapper-19 {
  color: #272936;
}

.CheckoutB-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.CheckoutB-d .frame-7 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 709px;
  position: absolute;
  top: 140px;
  width: 460px;
}

.CheckoutB-d .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: -33px;
  position: relative;
  width: 493px;
}

.CheckoutB-d .line-4 {
  height: 1px;
  left: 163px;
  position: absolute;
  top: 74px;
  width: 948px;
}

.CheckoutB-d .overlap-8 {
  height: 154px;
  left: 137px;
  position: absolute;
  top: 145px;
  width: 323px;
}

.CheckoutB-d .text-wrapper-21 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 66px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 224px;
}

.CheckoutB-d .text-wrapper-22 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 66px;
  font-weight: 500;
  height: 79px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 75px;
  white-space: nowrap;
  width: 323px;
}

.CheckoutB-d .footer-section {
  height: 344px;
  margin-top: -43px;
  position: relative;
  width: 1278px;
}

.CheckoutB-d .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 307px;
  left: 144px;
  position: relative;
  top: 41px;
}

.CheckoutB-d .XG-logo {
  height: 31.84px;
  position: relative;
  width: 155.66px;
}

.CheckoutB-d .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  margin-right: -0.98px;
  position: relative;
}

.CheckoutB-d .frame-wrapper {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.CheckoutB-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.CheckoutB-d .get-started-wrapper {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.CheckoutB-d .get-started-3 {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.CheckoutB-d .div-7 {
  height: 39px;
  position: relative;
  width: 45px;
}

.CheckoutB-d .shadows {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.CheckoutB-d .shadows-2 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.CheckoutB-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.CheckoutB-d .group-4 {
  height: 18px;
  left: 13px;
  position: absolute;
  top: 10px;
  width: 21px;
}

.CheckoutB-d .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 500px !important;
}

.GS-button-3:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-3-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.CheckoutB-d .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-d .PL {
  width: 492px;
  height: 731px;
  position: absolute;
  top: 1280px;
  left: 620px;
  z-index: 1;
}

.CheckoutB-d .HCC {
  width: 492px;
  height: 731px;
  position: absolute;
  top: 1280px;
  left: 145px;
  z-index: 1;
}

.CheckoutB-d .TEXTa {
  z-index: 2;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1650px;
  left: 190px;
}

.CheckoutB-d .frame-3a {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.CheckoutB-d .text-wrapper-15a {
  color: #7f64fb;
  font-family: "Titillium Web", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 20.4px;
  margin-top: -1px;
  position: relative;
  width: 491px;
}

.CheckoutB-d .text-wrapper-16a {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  position: relative;
  width: 493px;
}

.CheckoutB-d .text-wrapper-17a {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 400px;
}

.CheckoutB-d .bullet-list {
  list-style-type: none; /* Remove default bullet style */
  /* Customize bullet styles here */
}

.CheckoutB-d .bullet-list li {
  /* Add your bullet point text styles here */
  font-size: 14px; /* Bullet point text size */
  /* Add other styles as needed */
  position: relative; /* Make the list item a relative container */
  padding-left: 20px; /* Adjust the spacing between the bullet and text */

  /* Add your custom bullet color here */
}

.CheckoutB-d .bullet-list li::before {
  content: "\2022"; /* Use the bullet character as content */
  position: absolute;
  left: 0;
  color: #439be4;; /* Bullet point color */
  font-size: 14px; /* Bullet point size */
  /* Add other bullet point styles as needed */
}


.CheckoutB-d .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1650px;
  left: 665px;
}
.CheckoutB-d .frame-3b {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 491px;
}

.CheckoutB-d .text-wrapper-15b {
  color: #7f64fb;
  font-family: "Titillium Web", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 20.4px;
  margin-top: -1px;
  position: relative;
  width: 491px;
}

.CheckoutB-d .text-wrapper-16b {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  position: relative;
  width: 491px;
}

.CheckoutB-d .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-d .text-containera {
  display: flex;
  flex-direction: column; /* Stacks .TEXTa and .TEXTb vertically */
  gap: 35px; /* Adjust this as needed for spacing */
  /* ... other styles for the container ... */
}

.CheckoutB-d .text-container-b {
  display: flex;
  flex-direction: column; /* Stacks .TEXTa and .TEXTb vertically */
  gap: 35px; /* Adjust this as needed for spacing */
  /* ... other styles for the container ... */
}

.CheckoutB-d .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 830px;
}

.CheckoutB-d .df-container {
  width: 1280px;
  position: relative;
  top: 0px;
}

.CheckoutB-d .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1850px !important;
  left: 190px !important;
}

.GS-button:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.CheckoutB-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}


.CheckoutB-d .GS-button-2 {
  cursor: pointer;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1850px !important;
  left: 665px !important;
  z-index: 3;
}

.GS-button-2:hover {
  background-color: #7F64FB; /* Hover background color */
}

.GS-button-2-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.CheckoutB-d .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-d .text-wrapper-17-n {
    color: #272936;
    letter-spacing: -.35px;
    width: 650px;
    font-family: Titillium Web, Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    position: relative;
}

.CheckoutB-d .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
}

.CheckoutB-d .text-wrapper-44-2 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  left: 160px;
  top: 5px;
}

