.legal-i {
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  height: 11000px;
  position: relative;
  overflow: hidden;
}

.legal-i .BG-elements {
  height: 10877px;
  align-self: center;
  position: absolute;
  top: 0;
  width: 768px;
}

.legal-i .overlap-4 {
  height: 10877px;
  position: relative;
}

.legal-i .footer {
  height: 275px;
  left: 29px;
  position: absolute;
  top: 3163px;
  width: 709px;
}

.legal-i .start {
  height: 289px;
  left: 29px;
  position: absolute;
  top: 2525px;
  width: 709px;
}

.legal-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.legal-i .rectangle-8 {
  height: 426px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 709px;
}

.legal-i .gradient {
  height: 200px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.legal-i .if-container {
  width: 768px;
  position: relative;
  top: 100px;
}

.legal-i .mouse {
  height: 428px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 768px;
}

.legal-i .hero {
  height: 607px;
  position: absolute;
  top: 120px;
  width: 768px;
}

.legal-i .overlap-5 {
  height: 598px;
  position: relative;
}

.legal-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.legal-i .gradient-2 {
  height: 300px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 709px;
}

.legal-i .noize {
  height: 10877px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.legal-i .main-4 {
  height: 427px;
  margin-top: -43px;
  position: relative;
  width: 760px;
}

.legal-i .nav-spacer {
  height: 151px;
  margin-top: -43px;
  position: relative;
  width: 768px;
}

.legal-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -200px;
}

.legal-i .frame-22 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 73px;
  position: relative;
  top: 134px;
  width: 618px;
}

.legal-i .text-wrapper-33 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  height: 62px;
  letter-spacing: 0;
  line-height: 35.2px;
  margin-top: -1px;
  position: relative;
  width: 443px;
}

.legal-i .last-updated-2 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 11.3px;
  position: relative;
  width: 618px;
}

.legal-i .section-9 {
  height: 3551px;
  margin-top: -43px;
  position: relative;
  width: 768px;
}

.legal-i .frame-23 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 76px;
  position: relative;
  top: 96px;
}

.legal-i .last-updated-3 {
  color: #000000;
  font-family: "undefined-Regular", Helvetica;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 11.3px;
  position: relative;
  width: 617px;
}

.legal-i .section-10 {
  height: 2262px;
  margin-top: -43px;
  position: relative;
  width: 765px;
}

.legal-i .frame-24 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 450px;
  left: 73px;
}

.legal-i .PLEASE-READ-THE {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 11.3px;
  position: relative;
  width: 617px;
}

.legal-i .section-8 {
  height: 3786px;
  margin-top: -43px;
  position: relative;
  width: 764px;
}

.legal-i .TEXT-wrapper {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 75px;
  position: absolute;
  top: 140px;
  width: 315px;
}

.legal-i .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.legal-i .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-right: -178px;
  margin-top: -1px;
  position: relative;
  width: 493px;
}

.legal-i .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.legal-i .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 315px;
  z-index: 9;
}

.legal-i .text-wrapper-19 {
  color: #272936;
}

.legal-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.legal-i .frame-7 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 409px;
  position: absolute;
  top: 140px;
  width: 284px;
}

.legal-i .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: -209px;
  position: relative;
  width: 493px;
}


.legal-i .line-4 {
  height: 1px;
  left: 75px;
  position: absolute;
  top: 74px;
  width: 618px;
}

.legal-i .overlap-8 {
  height: 154px;
  left: 137px;
  position: absolute;
  top: 145px;
  width: 323px;
}

.legal-i .text-wrapper-21 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 66px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 224px;
}

.legal-i .text-wrapper-22 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 66px;
  font-weight: 500;
  height: 79px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 75px;
  white-space: nowrap;
  width: 323px;
}

.legal-i .footer-section {
  height: 344px;
  position: relative;
  width: 768px;
}

.legal-i .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 307px;
  left: 144px;
  position: relative;
  top: 41px;
}

.legal-i .sections {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
}

.legal-i .div-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.legal-i .text-wrapper-23 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.legal-i .text-wrapper-24 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.legal-i .follow-us {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  position: relative;
}

.legal-i .follow-us-2 {
  height: 81.5px;
  position: relative;
  width: 98.78px;
}

.legal-i .text-wrapper-25 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 68px;
}

.legal-i .social {
  align-items: flex-start;
  display: inline-flex;
  gap: 7px;
  left: 0;
  position: absolute;
  top: 35px;
}

.legal-i .telegram-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-left: -0.5px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.legal-i .twitter-footer {
  height: 21px !important;
  margin-bottom: -0.5px !important;
  margin-top: -0.5px !important;
  position: relative !important;
  width: 21px !important;
}

.legal-i .medium-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.legal-i .linkedin-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-right: -0.5px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.legal-i .text-wrapper-26 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.legal-i .t-cs {
  height: 17.49px;
  position: relative;
  width: 61px;
}

.legal-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.legal-i .text-wrapper-27 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.legal-i .all-rights-reserved {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
}

.legal-i .NAV {
  background-color: #f3f3f3;
  height: 120px;
  left: 50%;
  position: fixed; /* Change from 'absolute' to 'fixed' */
  align-self: center;
  top: 0;
  transform: translateX(-50%); /* Center horizontally using transform */
  width: 768px;
  z-index: 1000; /* Add a high z-index to make sure it's above other content */
}

.legal-i .ABOUT-US-4 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 137px;
  font-weight: 500;
  left: 66px;
  letter-spacing: -6.85px;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 123px;
  width: 623px;
}

.legal-i .frame-9 {
  align-items: center;
  display: flex;
  gap: 732px;
  left: 50px;
  position: relative;
  top: 47px;
  width: 1179px;
}

.legal-i .xion-logo {
  height: 30.71px;
  position: relative;
  width: 156.98px;
}

.legal-i .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  margin-right: -0.98px;
  position: relative;
}

.legal-i .frame-wrapper {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.legal-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.legal-i .get-started-wrapper {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.legal-i .get-started-3 {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.legal-i .div-7 {
  height: 39px;
  position: relative;
  width: 45px;
}

.legal-i .shadows {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.legal-i .shadows-2 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.legal-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.legal-i.group-4 {
  height: 18px;
  left: 13px;
  position: absolute;
  top: 10px;
  width: 21px;
}

.legal-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -200px;
}

.legal-i .TEXT-wrapper {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 75px;
  position: absolute;
  top: 140px;
  width: 315px;
}

.legal-i .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.legal-i .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-right: -178px;
  margin-top: -1px;
  position: relative;
  width: 493px;
}

.legal-i .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.legal-i .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 315px;
  z-index: 9;
}

.legal-i .text-wrapper-19 {
  color: #272936;
}

.legal-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.legal-i .frame-7 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 409px;
  position: absolute;
  top: 140px;
  width: 284px;
}

.legal-i .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: -209px;
  position: relative;
  width: 493px;
}
