:root {
  --carouselgray: #999;
}

.NAV-de {
  z-index: 999999;
  background-color: #f3f3f3;
  align-self: center;
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.frame-8-de {
  justify-content: center;
  align-items: center;
  gap: 732px;
  display: flex;
  position: relative;
  top: 47px;
}

.xion-logo-de {
  width: 156.98px;
  height: 30.71px;
  margin-left: -.49px;
  position: relative;
}

.frame-9-de {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.49px;
  display: inline-flex;
  position: relative;
}

.frame-wrapper-de {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.frame-10-de {
  background-image: url("shadows-10-2.6f49fd0b.png");
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.overlap-group-12-de {
  width: 226px;
  height: 40px;
  position: relative;
  top: -1px;
}

.rectangle-11-de {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 1px;
  left: 0;
}

.get-started-3-de {
  color: #439be4;
  letter-spacing: 3.24px;
  text-align: center;
  cursor: pointer;
  width: 226px;
  height: 39px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 0;
}

.get-started-3-de:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.div-5-de {
  width: 45px;
  height: 39px;
  position: relative;
}

.shadows-5-de {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.shadows-6-de {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-12-de {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-2-de {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.carousel-d {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 1428px;
  height: 560px;
  padding: 40px 64px;
  display: flex;
  position: relative;
}

.carousel-d .slides-box {
  flex: 1;
  align-self: center;
  width: auto;
  position: relative;
  overflow: hidden;
}

.carousel-d .slides-navigation {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-d .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-d .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-d .dot-indictaor {
  position: relative !important;
}

.CS-button-d {
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CS-button-d .get-started-2 {
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.CS-button-d.property-1-1-default {
  border-color: #14a5ed;
}

.CS-button-d.property-1-1-variant-2 {
  border-color: #7f64fb;
}

.CS-button-d.property-1-1-default .get-started-2 {
  color: #14a5ed;
}

.CS-button-d.property-1-1-variant-2 .get-started-2 {
  color: #7f64fb;
}

.element-dipad {
  width: 1138px;
  height: 490px;
  position: relative;
  top: 20px;
  left: 20px;
}

.element-d .desktop-shaddow-d {
  width: 686px;
  height: 490px;
  position: absolute;
  top: 0;
  left: 452px;
}

.element-d .asset-d {
  object-fit: cover;
  width: 776px;
  height: 740px;
  position: absolute;
  top: -20px;
}

.element-d .bullet-points-d {
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  display: inline-flex;
  position: absolute;
  top: 256px;
  left: -15px;
}

.element-d .create-d {
  cursor: pointer;
  width: 445px;
  height: 20.5px;
  margin-right: -2px;
  position: relative;
}

.element-d .create-one-time-or-d {
  color: #e8e8e8;
  letter-spacing: -.53px;
  white-space: nowrap;
  cursor: pointer;
  width: 408px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 21px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 35px;
}

.element-d .overlap-group-d {
  border-radius: 26.16px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 0;
}

.element-d .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.element-d .path-d {
  border-radius: inherit;
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.element-d .integrate {
  cursor: pointer;
  width: 443px;
  height: 20px;
  position: relative;
}

.element-d .text-wrapper-d {
  color: #e8e8e8;
  letter-spacing: 0;
  white-space: nowrap;
  cursor: pointer;
  width: 408px;
  height: 19px;
  font-family: Titillium Web, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 33px;
}

.element-d .div-d {
  width: 445px;
  height: 20px;
  margin-right: -2px;
  position: relative;
}

.element-d .p-d {
  color: #e8e8e8;
  letter-spacing: 0;
  white-space: nowrap;
  cursor: pointer;
  width: 408px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 35px;
}

.element-d .create-d.selected .create-one-time-or-d, .element-d .integrate.selected .text-wrapper-d, .element-d .div-d.selected .p-d, .element-d .div-d.selected .p-d, .element-d .create-d.selected .overlap-group-d .path-d {
  color: #14a5ed;
}

.GS-button-d {
  cursor: pointer;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.GS-button-d .get-started {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 0;
}

.GS-button-d.property-1-0-default {
  background-color: #14a5ed;
}

.GS-button-d.property-1-0-variant-2 {
  background-color: #7f64fb;
}

.read-button-d {
  cursor: pointer;
  z-index: 10001;
  border-radius: 10px;
  width: calc(100% - 20px);
  height: 39px;
  position: relative;
  left: 20px;
}

.read-button-d .text-wrapper-2 {
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.read-button-d.default {
  cursor: pointer;
  background-color: #7f64fb;
  top: 20px;
}

.read-button-d.variant-2 {
  cursor: pointer;
  border: 1px solid #7f64fb;
  top: 79px;
}

.read-button-d.default .text-wrapper-2 {
  color: #fff;
  cursor: pointer;
}

.read-button-d.variant-2 .text-wrapper-2 {
  color: #7f64fb;
  cursor: pointer;
}

.telegram {
  border: 1px;
  width: 28px;
  height: 28px;
  position: relative;
}

.telegram .overlap-group-wrapper {
  height: 28px;
}

.telegram .overlap-group {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.telegram .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.telegram .path {
  width: 15px;
  height: 13px;
  position: absolute;
  top: 9px;
  left: 6px;
}

.telegram .img {
  width: 8px;
  height: 10px;
  position: absolute;
  top: 11px;
  left: 10px;
}

.telegram .ellipse {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.twitter {
  width: 28px;
  height: 28px;
  position: relative;
  left: 20px;
}

.twitter .overlap-group-wrapper {
  height: 28px;
}

.twitter .overlap-group {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.twitter .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.twitter .ellipse {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.twitter .rectangle {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.twitter.default {
  top: 20px;
}

.twitter.variant-2 {
  top: 68px;
}

.medium {
  border: 1px;
  width: 28px;
  height: 28px;
  position: relative;
}

.medium .overlap-group-wrapper {
  width: 28px;
  height: 28px;
}

.medium .overlap-group {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.medium .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.medium .ellipse {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.medium .path {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 5px;
}

.medium .img {
  width: 6px;
  height: 11px;
  position: absolute;
  top: 9px;
  left: 17px;
}

.medium .path-2 {
  width: 2px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 23px;
}

.linkedin {
  border: 1px;
  width: 28px;
  height: 28px;
  position: relative;
}

.linkedin .linked-in {
  width: 28px;
  height: 28px;
}

.linkedin .overlap-group {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.linkedin .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.linkedin .ellipse {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.linkedin .path {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.df-container {
  width: 1280px;
  position: relative;
  top: -280px;
}

.footer-df {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 680px;
  left: 50px;
}

.start-df {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 1px;
  left: 50px;
}

.overlap-group-2-df {
  height: 250px;
  position: relative;
}

.rectangle-df {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.gradient-df {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.mouse-df {
  pointer-events: none;
  background-size: cover;
  width: 1179px;
  height: 428px;
  position: absolute;
}

.start-section-df {
  width: 1280px;
  height: 489px;
  margin-top: -43px;
  position: relative;
}

.start-image-df {
  object-fit: cover;
  width: 618px;
  height: 553px;
  position: absolute;
  top: -100px;
  left: 590px;
}

.CTA-buttons-2-df {
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 472px;
  display: flex;
  position: absolute;
  top: 330px;
  left: 90px;
}

.text-block-3-df {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 146px;
  left: 90px;
}

.overlap-group-10-df {
  height: 154px;
  position: relative;
  top: -1px;
}

.text-wrapper-49-df {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.text-wrapper-50-df {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.footer-section-df {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.frame-7-df {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 720px;
  left: 144px;
}

.XG-logo-df {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.sections-df {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.div-4-df {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.text-wrapper-51-df {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.text-wrapper-52-df {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  transition: color .3s;
  position: relative;
}

.text-wrapper-52-df:hover {
  color: #14a5ed;
}

.follow-us-df {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.follow-us-2-df {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.text-wrapper-53-df {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.address-wrapper-df {
  flex-direction: column;
  gap: 8px;
  padding-top: 96px;
  display: flex;
}

.address-df {
  color: #e8e8e8;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
}

.social-df {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.df-container .telegram-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  top: -1px;
  left: 0;
}

.df-container .telegram-footer-instance-df {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.df-container .twitter-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.df-container .twitter-footer-instance-df {
  left: unset !important;
  top: unset !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.df-container .medium-footer-instance-df {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.df-container .medium-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
}

.df-container .linkedin-footer-instance-df {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.df-container .linkedin-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  top: -1px;
  left: 0;
}

.text-wrapper-54-df {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.t-cs-df {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.overlap-group-11-df {
  height: 17px;
  margin-top: 100px;
  position: relative;
  top: -1px;
}

.text-wrapper-55-df {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -175px;
  left: -925px;
}

.all-rights-reserved-df {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -175px;
  left: -875px;
}

.company-address-df {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -125px;
  left: 0;
}

.license-df {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 7px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 40px;
  left: -930px;
}

.license-df a {
  color: #e8e8e8;
  text-decoration: none;
}

.element-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 7600px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 7533px;
  position: absolute;
  top: 0;
}

.element-d .D-banner {
  cursor: pointer;
  border-radius: 90px;
  width: 1150px;
  height: 190px;
  margin-top: 9px;
  margin-left: 65px;
}

.element-d .overlap {
  height: 7533px;
  position: relative;
}

.element-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 7170px;
  left: 50px;
}

.element-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 6532px;
  left: 50px;
}

.element-d .overlap-group-2 {
  height: 638px;
  position: relative;
}

.element-d .rectangle {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.element-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .mouse {
  pointer-events: none;
  background-size: cover;
  width: 1179px;
  height: 428px;
  position: absolute;
}

.element-d .element-ipad-d {
  width: 1180px;
  height: 852px;
  position: absolute;
  top: 3840px;
  left: 50px;
}

.element-d .overlap-2 {
  height: 852px;
  position: relative;
}

.element-d .frame-7-df {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 720px;
  left: 144px;
}

.element-d .img {
  object-fit: cover;
  width: 1180px;
  height: 850px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .img-2 {
  width: 1180px;
  height: 852px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .decrease {
  width: 1179px;
  height: 624px;
  position: absolute;
  top: 3324px;
  left: 50px;
}

.element-d .overlap-3 {
  height: 623px;
  position: relative;
}

.element-d .rectangle-2 {
  object-fit: cover;
  width: 1178px;
  height: 621px;
  position: absolute;
  top: 2px;
  left: 0;
}

.element-d .mouse-2 {
  width: 1179px;
  height: 622px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .hero {
  align-self: auto;
  width: 1180px;
  height: 1634px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.element-d .overlap-4 {
  height: 1634px;
  position: relative;
}

.element-d .rectangle-3 {
  object-fit: cover;
  width: 1179px;
  height: 1356px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .gradient-2 {
  width: auto;
  height: 1632px;
  position: absolute;
  top: 3px;
  left: 0;
}

.element-d .mouse-3 {
  width: auto;
  height: 1358px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .noize {
  width: 1280px;
  height: 7533px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.element-d .landing-p-section {
  width: 1280px;
  height: 714px;
  margin-top: -43px;
  position: relative;
}

.element-d .logos {
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  gap: 30px 25px;
  width: 1180px;
  padding: 0 30px;
  display: flex;
  position: absolute;
  top: 686px;
  left: 49px;
}

.element-d .asset-2 {
  width: 146px;
  height: 32px;
  position: relative;
}

.element-d .asset-3 {
  width: 63px;
  height: 32px;
  position: relative;
}

.element-d .asset-4, .element-d .asset-5 {
  width: 174px;
  height: 32px;
  position: relative;
}

.element-d .asset-6 {
  width: 151px;
  position: relative;
}

.element-d .TRUSTED-BY {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 1280px;
  font-family: Titillium Web, Helvetica;
  font-size: 29px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 622px;
  left: 0;
}

.element-d .span {
  font-weight: 100;
}

.element-d .text-wrapper-3 {
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
}

.element-d .text-wrapper-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 1023px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 28.8px;
  position: absolute;
  top: 520px;
  left: 128px;
}

.element-d .element-menu {
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  width: 1023px;
  display: flex;
  position: absolute;
  top: 415px;
  left: 128px;
}

.element-d .INCREASING-REVENUE-wrapper {
  background-image: url("desktop-block-header.1513672d.png");
  background-size: 100% 100%;
  width: 233.28px;
  height: 51.68px;
  margin-bottom: -.34px;
  margin-left: -.36px;
  position: relative;
}

.element-d .text-wrapper-5 {
  color: #14a5ed;
  letter-spacing: .8px;
  text-align: center;
  width: 199px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 17px;
}

.element-d .div-wrapper {
  background-image: url("desktop-block-header.1513672d.png");
  background-size: 100% 100%;
  width: 233.28px;
  height: 51.68px;
  margin-bottom: -.34px;
  position: relative;
}

.element-d .SEAMLESS {
  color: #14a5ed;
  letter-spacing: .8px;
  text-align: center;
  width: 199px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 18px;
}

.element-d .BUILDING-LOYALTY {
  color: #14a5ed;
  letter-spacing: 2.4px;
  text-align: center;
  width: 198px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 18px;
}

.element-d .PAYMENTS-REWARDS {
  color: #f3f3f3;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 1280px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 82px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 264px;
  left: 0;
}

.element-d .text-wrapper-6 {
  font-weight: 500;
}

.element-d .text-wrapper-7 {
  font-family: Titillium Web, Helvetica;
  font-size: 95px;
  font-weight: 100;
}

.element-d .MAKIN-CRYPTO {
  color: #f3f3f3;
  text-align: center;
  white-space: nowrap;
  width: 1280px;
  height: 101px;
  font-family: Titillium Web, Helvetica;
  font-size: 122px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 152px;
  left: 0;
}

.element-d .text-wrapper-8 {
  letter-spacing: 0;
  font-weight: 100;
}

.element-d .text-wrapper-9 {
  letter-spacing: -18.3px;
  font-weight: 100;
}

.element-d .text-wrapper-10 {
  letter-spacing: 0;
  font-family: Oswald, Helvetica;
  font-size: 109px;
  font-weight: 500;
}

.element-d .top-ipad-section {
  width: 1280px;
  height: 799px;
  margin-top: -43px;
  position: relative;
}

.element-d .overlap-5 {
  width: 1140px;
  height: 742px;
  position: relative;
  top: 182px;
  left: 110px;
}

.element-d .bottom-left-shadow {
  object-fit: cover;
  width: 520px;
  height: 279px;
  position: absolute;
  top: 463px;
  left: 444px;
}

.element-d .component {
  position: absolute !important;
  top: 0 !important;
  left: 2px !important;
}

.element-d .one {
  width: 786px !important;
  height: 790px !important;
  top: -70px !important;
}

.element-d .WITH-OUR-FREE-SIMPLE {
  width: 446px;
  height: 235px;
  position: absolute;
  top: 2px;
  left: 0;
}

.element-d .overlap-6 {
  height: 236px;
  position: relative;
  top: -1px;
}

.element-d .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  width: 443px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 3px;
}

.element-d .frame {
  width: 443px;
  height: 181px;
  position: absolute;
  top: 55px;
  left: 0;
}

.element-d .overlap-group-3 {
  height: 181px;
  position: relative;
  top: -1px;
}

.element-d .payment-solution-you {
  color: #fff;
  width: 443px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: 50px;
  position: absolute;
  top: 75px;
  left: 0;
}

.element-d .text-wrapper-12 {
  letter-spacing: -1.25px;
}

.element-d .text-wrapper-13 {
  letter-spacing: 0;
  line-height: 47.5px;
}

.element-d .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 333px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 57px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 2px;
  -webkit-background-clip: text !important;
}

.element-d .wallet-section {
  align-self: center;
  height: 652px;
  margin-top: -43px;
  display: flex;
  position: relative;
}

.element-d .overlap-7 {
  width: 1085px;
  height: 649px;
  position: relative;
  top: -27px;
}

.element-d .wallet-image {
  object-fit: cover;
  width: 553px;
  height: 649px;
  position: absolute;
  top: 0;
  left: 532px;
}

.element-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 545px;
  display: flex;
  position: absolute;
  top: 160px;
  left: 0;
}

.element-d .a-SEAMLESS-SUITE-OF {
  color: #000;
  width: 545px;
  height: 139px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 41px;
  font-weight: 400;
  line-height: 46.5px;
  position: relative;
}

.element-d .text-wrapper-14 {
  letter-spacing: -2.46px;
  font-weight: 100;
}

.element-d .text-wrapper-15 {
  letter-spacing: -1.23px;
  font-weight: 100;
}

.element-d .text-wrapper-16 {
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: 40.9px;
}

.element-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.5px;
  width: 491px;
  height: 106px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.element-d .frame-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element-d .text-wrapper-18 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-d .text-wrapper-19 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-d .currencies-section {
  width: 1280px;
  height: 1180px;
  margin-top: -43px;
  position: relative;
}

.element-d .crystal {
  width: 194px;
  height: 30px;
  position: absolute;
  top: 1111px;
  left: 138px;
}

.element-d .overlap-group-4 {
  width: 118px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 76px;
}

.element-d .rectangle-4 {
  height: 80px;
  position: absolute;
  top: 0;
  left: -15px;
}

.element-d .text-wrapper-20 {
  color: #000;
  letter-spacing: 0;
  width: 34px;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 19px;
  left: 76px;
}

.element-d .POWERED-BY {
  color: #000;
  letter-spacing: 0;
  width: 68px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 0;
}

.element-d .text-wrapper-21 {
  font-family: Oswald, Helvetica;
  font-size: 10px;
  font-weight: 500;
}

.element-d .element-black-text-blocks {
  justify-content: center;
  align-items: flex-start;
  gap: 41px;
  width: 1159px;
  display: flex;
  position: absolute;
  top: 924px;
  left: 61px;
}

.element-d .b {
  width: 359px;
  height: 167.5px;
  position: relative;
}

.element-d .overlap-8 {
  width: 359px;
  height: 168px;
  position: relative;
}

.element-d .element-button {
  width: 359px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .overlap-group-5 {
  height: 168px;
  position: relative;
}

.element-d .rectangle-5 {
  filter: blur(28px);
  opacity: .5;
  background: radial-gradient(50% 50%, #0000003b 0%, #0000009b 64.81%);
  border-radius: 10px;
  width: 350px;
  height: 140px;
  position: absolute;
  top: 27px;
  left: 9px;
}

.element-d .rectangle-6 {
  background: radial-gradient(50% 50%, #020202 0%, #000 69.79% 100%);
  border-radius: 10px;
  width: 350px;
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 1px 5px #fff9, inset 0 -8px 36px #0000004c;
}

.element-d .text-wrapper-22 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 320px;
  font-family: Titillium Web, Helvetica;
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 59px;
  left: 14px;
}

.element-d .text-wrapper-23 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 235px;
  font-family: Titillium Web, Helvetica;
  font-size: 21px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 57px;
}

.element-d .we-adhere-to-BSA-CIP {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 320px;
  font-family: Titillium Web, Helvetica;
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 58px;
  left: 15px;
}

.element-d .text-wrapper-24 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 21px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 20px;
  left: 90px;
}

.element-d .our-screening {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 320px;
  font-family: Titillium Web, Helvetica;
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 16px;
}

.element-d .text-wrapper-25 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Titillium Web, Helvetica;
  font-size: 21px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 67px;
}

.element-d .security-text {
  width: 1114px;
  height: 190px;
  position: absolute;
  top: 674px;
  left: 83px;
}

.element-d .text-wrapper-26 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1114px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 91px;
  left: 0;
}

.element-d .SECURITY-AND {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1114px;
  font-family: Titillium Web, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-d .text-wrapper-27 {
  font-family: Oswald, Helvetica;
  font-size: 31px;
  font-weight: 500;
}

.element-d .overlap-9 {
  width: 1280px;
  height: 377px;
  position: absolute;
  top: 289px;
  left: 0;
}

.element-d .carousel-instance {
  width: 1280px !important;
  height: 377px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.element-d .design-component-instance-node {
  padding: unset !important;
  gap: 6px !important;
}

.element-d .coins-overlay {
  width: 1280px;
  height: 193px;
  position: absolute;
  top: 64px;
  left: 0;
}

.element-d .rectangle-7 {
  -webkit-backdrop-filter: blur(6px) brightness();
  backdrop-filter: blur(6px) brightness();
  background: linear-gradient(#ffffff08 0%, #fff0 100%);
  border: .76px solid #0000;
  border-image: linear-gradient(#f5fbf270, #f5fbf200) 1;
  border-radius: 11.42px;
  width: 354px;
  height: 193px;
  position: absolute;
  top: 0;
  left: 926px;
}

.element-d .rectangle-8 {
  -webkit-backdrop-filter: blur(6px) brightness();
  backdrop-filter: blur(6px) brightness();
  background: linear-gradient(#ffffff08 0%, #fff0 100%);
  border: .76px solid #0000;
  border-image: linear-gradient(#f5fbf270, #f5fbf200) 1;
  border-radius: 11.42px;
  width: 334px;
  height: 193px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .text-header {
  width: 1011px;
  height: 174px;
  position: absolute;
  top: 115px;
  left: 134px;
}

.element-d .expand-your-reach {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1011px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 116px;
  left: 0;
}

.element-d .text-wrapper-28 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
}

.element-d .text-wrapper-29 {
  font-weight: 700;
}

.element-d .header {
  background-color: #0000;
  width: 824px;
  height: 83px;
  position: absolute;
  top: 0;
  left: 105px;
}

.element-d .GET-PAID-IN {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  font-family: Titillium Web, Helvetica;
  font-size: 60px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 50px;
}

.element-d .text-wrapper-30 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 60px;
  font-weight: 100;
}

.element-d .text-wrapper-31 {
  font-size: 58px;
}

.element-d .text-wrapper-32 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 505px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 54px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 319px;
  -webkit-background-clip: text !important;
}

.element-d .costs-section {
  align-self: center;
  width: 1280px;
  height: 568px;
  position: relative;
}

.element-d .text-wrapper-33 {
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
  width: 1114px;
  font-family: Titillium Web, Helvetica;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 500px;
  left: 83px;
}

.element-d .element-white-blocks {
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 959px;
  display: flex;
  position: absolute;
  top: 374px;
  left: 160px;
}

.element-d .overlap-group-wrapper {
  width: 293px;
  height: 87px;
  position: relative;
}

.element-d .overlap-group-6 {
  border-radius: 14px;
  height: 87px;
  position: relative;
}

.element-d .shadows {
  background: linear-gradient(#fff 0% 100%);
  border-radius: 14px;
  width: 293px;
  height: 87px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: inset 6px 8px 8px #aeaec08c, inset -6px -6px 6px #ffffffb2;
}

.element-d .text-wrapper-34 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 163px;
  font-family: Titillium Web, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: 65px;
}

.element-d .shadows-2 {
  background: linear-gradient(#fff 0% 100%);
  border-radius: 14px;
  width: 293px;
  height: 87px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: inset 6px 8px 8px #aeaec08c, inset -6px -6px 6px #ffffffb2;
}

.element-d .text-wrapper-35 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 34px;
}

.element-d .text-wrapper-36 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 180px;
  font-family: Titillium Web, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 23px;
  left: 57px;
}

.element-d .header-text {
  width: 1114px;
  height: 160px;
  position: absolute;
  top: 172px;
  left: 83px;
}

.element-d .cut-your-transaction {
  color: #fff;
  letter-spacing: -.4px;
  text-align: center;
  width: 1114px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 104px;
  left: 0;
}

.element-d .DECREASE-YOUR-COSTS {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 1114px;
  font-family: Titillium Web, Helvetica;
  font-size: 60px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-d .text-wrapper-37 {
  font-family: Oswald, Helvetica;
  font-size: 54px;
  font-weight: 500;
}

.element-d .bottom-ipad-section {
  width: 1278px;
  height: 796px;
  margin-top: -43px;
  position: relative;
}

.element-d .overlap-10 {
  width: 846px;
  height: 747px;
  position: absolute;
  top: 189px;
  left: -118px;
}

.element-d .read-button-instance {
  width: 226px;
  position: absolute;
  top: 562px;
  left: 257px;
}

.element-d .overlap-wrapper {
  width: 846px;
  height: 747px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .overlap-11 {
  height: 747px;
  position: relative;
}

.element-d .group {
  width: 747px;
  height: 840px;
  position: absolute;
  top: -47px;
  left: 47px;
  transform: rotate(90deg);
}

.element-d .overlap-group-7 {
  height: 723px;
  position: relative;
}

.element-d .shadows-3 {
  width: 716px;
  height: 747px;
  position: absolute;
  top: -8px;
  left: 15px;
  transform: rotate(-90deg);
}

.element-d .df-container {
  width: 1280px;
  position: relative;
  top: -200px;
}

.element-d .frame-3 {
  background-color: #fff;
  border-radius: 29px;
  width: 493px;
  height: 688px;
  position: absolute;
  top: 0;
  left: 14px;
  box-shadow: 80px 80px 150px #00000040, 20px 20px 50px #00000059;
}

.element-d .API-VID-TO-PLACE {
  object-fit: cover;
  width: 688px;
  height: 493px;
  position: absolute;
  top: 14px;
  left: 152px;
}

.element-d .API-GIF-SCREEN {
  border-radius: 40px;
  width: 682px;
  height: 485px;
  position: absolute;
  top: 17px;
  left: 138px;
}

.element-d .asset-7 {
  object-fit: cover;
  width: 700px;
  height: 501px;
  position: absolute;
  top: 11px;
  left: 146px;
}

.element-d .text-block-2 {
  flex-direction: column;
  align-items: flex-end;
  gap: 34px;
  width: 391px;
  height: 598px;
  display: flex;
  position: absolute;
  top: 150px;
  left: 775px;
}

.element-d .frame-4 {
  flex-direction: column;
  align-items: flex-end;
  height: 189px;
  margin-left: -63px;
  display: inline-flex;
  position: relative;
}

.element-d .SUPERCHARGING-CRYPTO {
  color: #faf8f8;
  letter-spacing: 0;
  text-align: right;
  width: 391px;
  height: 106px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 46.2px;
  font-weight: 100;
  line-height: 48px;
  position: relative;
}

.element-d .WITH-STATE-OF-THE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  text-align: right;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 454px;
  height: 154px;
  margin-top: -9px;
  margin-bottom: -61px;
  font-family: Oswald, Helvetica;
  font-size: 43px;
  font-weight: 500;
  line-height: 46.3px;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-d .text-wrapper-38 {
  color: #e9edf3;
  letter-spacing: 0;
}

.element-d .text-wrapper-39 {
  color: #e9edf3;
  letter-spacing: -.86px;
}

.element-d .frame-5 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 19px;
  width: 345px;
  padding: 0 0 13px;
  display: flex;
  position: relative;
}

.element-d .API-integration {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: right;
  width: 389px;
  margin-top: -1px;
  margin-left: -44px;
  font-family: Titillium Web, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: 23px;
  position: relative;
}

.element-d .text-wrapper-40 {
  font-size: 20px;
  line-height: 29px;
}

.element-d .text-wrapper-41 {
  font-size: 20px;
  line-height: 24px;
}

.element-d .div-2 {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: right;
  width: 389px;
  margin-left: -44px;
  font-family: Titillium Web, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: 23px;
  position: relative;
}

.element-d .iframe-section {
  width: 1279px;
  height: 788px;
  margin-top: -43px;
  position: relative;
}

.element-d .overlap-12 {
  width: 618px;
  height: 927px;
  position: absolute;
  top: 94px;
  left: 486px;
}

.element-d .right-text {
  width: 272px;
  height: 87px;
  position: absolute;
  top: 351px;
  left: 342px;
}

.element-d .xionpay {
  object-fit: cover;
  width: 151px;
  height: 33px;
  position: absolute;
  top: 55px;
  left: 0;
}

.element-d .text-wrapper-42 {
  color: #000;
  letter-spacing: 5.25px;
  width: 400px;
  font-family: Oswald, Helvetica;
  font-size: 35px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-d .phone {
  width: 308px;
  height: 615px;
  position: relative;
  top: 0;
  left: 0;
}

.element-d .overlap-group-8 {
  width: 490px;
  height: 650px;
  position: relative;
  top: 114px;
}

.element-d .shadows-4 {
  width: 519px;
  height: 811px;
  position: absolute;
  top: 2px;
  left: 5px;
}

.element-d .frame-6 {
  opacity: .8;
  z-index: 5;
  background-image: url("desktopphone.2736e718.png");
  border-radius: 50px;
  width: 308px;
  height: 615px;
  position: absolute;
  top: 1px;
}

.element-d .IFRAME-DESKTOP {
  z-index: 9;
  border-radius: 34px;
  width: 283px;
  height: 594px;
  position: absolute;
  top: 7px;
  left: 10px;
}

.element-d .i-f {
  width: 308px;
  height: 615px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .left-text {
  width: 273px;
  height: 58px;
  position: absolute;
  top: 442px;
  left: 178px;
}

.element-d .line {
  object-fit: cover;
  width: 176px;
  height: 4px;
  position: absolute;
  top: 56px;
  left: 94px;
}

.element-d .text-wrapper-43 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 273px;
  font-family: Titillium Web, Helvetica;
  font-size: 38px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-d .loyalty-CTA-section {
  width: 1280px;
  height: 862px;
  margin-top: -43px;
  position: relative;
}

.element-d .CTA-section {
  width: 1280px;
  height: 241px;
  position: absolute;
  top: 588px;
  left: 0;
}

.element-d .CTA-shaddow {
  mix-blend-mode: multiply;
  object-fit: cover;
  width: 596px;
  height: 48px;
  position: absolute;
  top: 193px;
  left: 342px;
}

.element-d .enjoy-the-freedom-of {
  color: #1185c3;
  letter-spacing: 2.04px;
  text-align: center;
  width: 1280px;
  font-family: Titillium Web, Helvetica;
  font-size: 34px;
  font-weight: 400;
  line-height: 42.7px;
  position: absolute;
  top: 72px;
  left: 0;
}

.element-d .CTA-buttons {
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 472px;
  display: flex;
  position: absolute;
  top: 0;
  left: 404px;
}

.element-d .loyalty-section {
  width: 1280px;
  height: 272px;
  position: absolute;
  top: 162px;
  left: 0;
}

.element-d .element-black-blocks {
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 1114px;
  display: flex;
  position: absolute;
  top: 229px;
  left: 83px;
}

.element-d .b-2 {
  width: 359px;
  height: 101px;
  margin-left: -21.5px;
  position: relative;
}

.element-d .div-3 {
  width: 359px;
  height: 101px;
  position: relative;
}

.element-d .element-d-button {
  width: 359px;
  height: 101px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .overlap-group-9 {
  height: 101px;
  position: relative;
}

.element-d .rectangle-9 {
  filter: blur(28px);
  opacity: .5;
  background: radial-gradient(50% 50%, #0000003b 0%, #0000 64.81%);
  border-radius: 10px;
  width: 350px;
  height: 85px;
  position: absolute;
  top: 16px;
  left: 9px;
}

.element-d .rectangle-10 {
  background: radial-gradient(50% 50%, #020202 0%, #000 69.79% 100%);
  border-radius: 10px;
  width: 350px;
  height: 85px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 1px 5px #fff9, inset 0 -8px 36px #0000004c;
}

.element-d .monetize-blogs-apps {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 262px;
  height: 59px;
  font-family: Titillium Web, Helvetica;
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
  line-height: 27.5px;
  position: absolute;
  top: 12px;
  left: 44px;
}

.element-d .works-with-any-all {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 262px;
  height: 59px;
  font-family: Titillium Web, Helvetica;
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
  line-height: 27.5px;
  position: absolute;
  top: 11px;
  left: 44px;
}

.element-d .b-3 {
  width: 359px;
  height: 101px;
  margin-right: -21.5px;
  position: relative;
}

.element-d .activate-loyalty {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 262px;
  height: 59px;
  font-family: Titillium Web, Helvetica;
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
  line-height: 27.5px;
  position: absolute;
  top: 10px;
  left: 44px;
}

.element-d .strengthen-your {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1280px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 106px;
  left: 0;
}

.element-d .headline {
  width: 686px;
  height: 83px;
  position: absolute;
  top: 0;
  left: 301px;
}

.element-d .text-wrapper-44 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 216px;
  font-family: Titillium Web, Helvetica;
  font-size: 60px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-d .text-wrapper-45 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 459px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 54px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 227px;
  -webkit-background-clip: text !important;
}

.element-d .cash-back-section {
  width: 1280px;
  height: 663px;
  margin-top: -43px;
  position: relative;
}

.element-d .overlap-13 {
  width: 1075px;
  height: 463px;
  position: relative;
  top: 126px;
  left: 70px;
}

.element-d .copy-block {
  flex-direction: column;
  align-items: flex-end;
  gap: 22px;
  width: 426px;
  display: flex;
  position: absolute;
  top: 50px;
  left: 649px;
}

.element-d .CASH-BACK {
  flex-direction: column;
  align-items: flex-end;
  height: 134px;
  display: inline-flex;
  position: relative;
}

.element-d .text-wrapper-46 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 313px;
  height: 82px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 60px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-d .text-wrapper-47 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: right;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 309px;
  height: 79px;
  margin-top: -17px;
  margin-bottom: -9px;
  font-family: Oswald, Helvetica;
  font-size: 54px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-d .text-wrapper-48 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 407px;
  font-family: Titillium Web, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-d .cash-back-image {
  mix-blend-mode: multiply;
  object-fit: cover;
  width: 668px;
  height: 463px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .start-section {
  width: 1280px;
  height: 489px;
  margin-top: -43px;
  position: relative;
}

.element-d .start-image {
  object-fit: cover;
  width: 618px;
  height: 553px;
  position: absolute;
  top: -102px;
  left: 641px;
}

.element-d .CTA-buttons-2 {
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 472px;
  display: flex;
  position: absolute;
  top: 330px;
  left: 141px;
}

.element-d .text-block-3 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 146px;
  left: 137px;
}

.element-d .overlap-group-10 {
  height: 154px;
  position: relative;
  top: -1px;
}

.element-d .text-wrapper-49 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .text-wrapper-50 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.element-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.element-d .frame-7 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.element-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.element-d .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.element-d .div-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.element-d .text-wrapper-51 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-d .text-wrapper-52 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-d .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.element-d .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.element-d .text-wrapper-53 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.element-d .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.element-d .telegram-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.element-d .twitter-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.element-d .medium-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.element-d .linkedin-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.element-d .text-wrapper-54 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.element-d .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.element-d .overlap-group-11 {
  height: 17px;
  position: relative;
  top: -1px;
}

.element-d .text-wrapper-55 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.element-d .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.element-d .frame-8 {
  justify-content: center;
  align-items: center;
  gap: 732px;
  display: flex;
  position: relative;
  top: 47px;
}

.element-d .xion-logo {
  width: 156.98px;
  height: 30.71px;
  margin-left: -.49px;
  position: relative;
}

.element-d .frame-9 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.49px;
  display: inline-flex;
  position: relative;
}

.element-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.element-d .frame-10 {
  background-image: url("shadows-10-2.6f49fd0b.png");
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.element-d .overlap-group-12 {
  width: 226px;
  height: 40px;
  position: relative;
  top: -1px;
}

.element-d .rectangle-11 {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 1px;
  left: 0;
}

.element-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  text-align: center;
  cursor: pointer;
  width: 226px;
  height: 39px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 0;
}

.element-d .get-started-3:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.element-d .div-5 {
  width: 45px;
  height: 39px;
  position: relative;
}

.element-d .shadows-5 {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.element-d .shadows-6 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .rectangle-12 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-d .group-2 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.carousel-i {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 1428px;
  height: 560px;
  padding: 40px 64px;
  display: flex;
  position: relative;
}

.carousel-i .slides-box {
  flex: 1;
  align-self: center;
  width: 780px;
  position: relative;
}

.carousel-i .slides-navigation {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-i .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-i .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-i .dot-indictaor {
  position: relative !important;
}

.CS-button-i {
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CS-button-i .get-started-2 {
  letter-spacing: 0;
  text-align: center;
  width: 168px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.CS-button-i.property-1-1-default {
  border-color: #14a5ed;
}

.CS-button-i.property-1-1-variant-2 {
  border-color: #7f64fb;
}

.CS-button-i.property-1-1-default .get-started-2 {
  color: #14a5ed;
}

.CS-button-i.property-1-1-variant-2 .get-started-2 {
  color: #7f64fb;
}

.element-iipad {
  width: 723px;
  height: 355px;
  position: relative;
  top: 20px;
}

.element-iipad .ipad-shaddow-i {
  width: 427px;
  height: 305px;
  position: absolute;
  top: 25px;
  left: 275px;
}

.element-iipad .asset-i {
  object-fit: cover;
  width: 427px;
  height: 305px;
  position: absolute;
}

.element-iipad .bullet-points-i {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 250px;
  height: 123px;
  display: flex;
  position: absolute;
  top: 183px;
  left: 11px;
}

.element-iipad .create-i {
  cursor: pointer;
  width: 437px;
  height: 20.5px;
  margin-right: -187px;
  position: relative;
}

.element-iipad .create-one-time-or-i {
  color: #e8e8e8;
  letter-spacing: 0;
  cursor: pointer;
  width: 408px;
  height: 19px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 27px;
}

.element-iipad .overlap-group-i {
  border-radius: 26.16px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-iipad .shadows-m-i {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.element-iipad .path-i {
  border-radius: inherit;
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.element-iipad .div-i {
  cursor: pointer;
  width: 437px;
  height: 20px;
  margin-right: -187px;
  position: relative;
}

.element-iipad .text-wrapper-i {
  color: #e8e8e8;
  letter-spacing: 0;
  cursor: pointer;
  width: 408px;
  height: 19px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 27px;
}

.element-iipad .p-i {
  color: #e8e8e8;
  letter-spacing: 0;
  cursor: pointer;
  width: 408px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 27px;
}

.element-iipad .create-i.selected .create-one-time-or-i, .element-iipad .div-i.selected .text-wrapper-i, .element-iipad .div-i.selected .p-i, .element-iipad .div-i.selected .p-i {
  color: #14a5ed;
}

.GS-button-i {
  cursor: pointer;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.GS-button-i .get-started {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 168px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.GS-button-i.property-1-0-default {
  background-color: #14a5ed;
}

.GS-button-i.property-1-0-variant-2 {
  background-color: #7f64fb;
}

.read-button-i {
  cursor: pointer;
  z-index: 2000;
  border-radius: 10px;
  width: calc(100% - 20px);
  height: 39px;
  position: relative;
  left: 20px;
}

.read-button-i .text-wrapper-2 {
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.read-button-i.default {
  background-color: #7f64fb;
  top: 20px;
}

.read-button-i.variant-2 {
  border: 1px solid #7f64fb;
  top: 79px;
}

.read-button-i.default .text-wrapper-2 {
  color: #fff;
}

.read-button-i.variant-2 .text-wrapper-2 {
  color: #7f64fb;
}

.element-ipad {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 5391px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-ipad .BG-elements {
  align-self: center;
  width: 768px;
  height: 7539px;
  position: absolute;
  top: 0;
}

.element-ipad .I-banner {
  cursor: pointer;
  border-radius: 45px;
  width: 685px;
  height: 100px;
  margin-top: -80px;
  margin-left: 40px;
}

.element-ipad .overlap {
  height: 5361px;
  position: relative;
  top: 1px;
}

.element-ipad .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 5057px;
  left: 29px;
}

.element-ipad .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 4768px;
  left: 29px;
}

.element-ipad .overlap-group-2 {
  height: 289px;
  position: relative;
}

.element-ipad .rectangle {
  object-fit: cover;
  width: 709px;
  height: 286px;
  position: absolute;
  top: 3px;
  left: 0;
}

.element-ipad .gradient {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .mouse {
  width: 709px;
  height: 288px;
  position: absolute;
  top: 1px;
  left: 0;
}

.element-ipad .element-nd-ipad {
  width: 710px;
  height: 574px;
  position: absolute;
  top: 2700px;
  left: 29px;
}

.element-ipad .overlap-2 {
  height: 574px;
  position: relative;
}

.element-ipad .img {
  object-fit: cover;
  width: 709px;
  height: 571px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .gradient-2 {
  width: 709px;
  height: 574px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .mouse-2 {
  width: 708px;
  height: 574px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .decrease {
  width: 710px;
  height: 464px;
  position: absolute;
  top: 2300px;
  left: 28px;
}

.element-ipad .overlap-3 {
  height: 464px;
  position: relative;
}

.element-ipad .rectangle-2 {
  object-fit: cover;
  width: 709px;
  height: 462px;
  position: absolute;
  top: 2px;
  left: 0;
}

.element-ipad .mouse-3 {
  width: 710px;
  height: 464px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .hero {
  width: 709px;
  height: 957px;
  position: absolute;
  top: 120px;
  left: 29px;
}

.element-ipad .overlap-4 {
  height: 1200px;
  position: relative;
}

.element-ipad .rectangle-3 {
  object-fit: cover;
  width: 709px;
  height: 954px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .gragient {
  width: 709px;
  height: 1200px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .mouse-4 {
  width: 709px;
  height: 957px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .noize {
  width: 768px;
  height: 5361px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .nav-spacer {
  width: 768px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .landing-p-section {
  width: 766px;
  height: 497px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .overlap-5 {
  width: 767px;
  height: 64px;
  position: absolute;
  top: 485px;
  left: -1px;
}

.element-ipad .logos {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px 20px;
  width: 708px;
  padding: 0 30px;
  display: flex;
  position: absolute;
  top: 41px;
  left: 29px;
}

.element-ipad .asset-2 {
  width: 105px;
  height: 23px;
  position: relative;
}

.element-ipad .asset-3 {
  width: 46px;
  height: 23px;
  position: relative;
}

.element-ipad .asset-4 {
  width: 121.5px;
  height: 23px;
  position: relative;
}

.element-ipad .asset-5 {
  width: 125px;
  height: 23px;
  position: relative;
}

.element-ipad .asset-6 {
  width: 115px;
  position: relative;
}

.element-ipad .TRUSTED-BY {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 767px;
  font-family: Titillium Web, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .span {
  font-weight: 100;
}

.element-ipad .text-wrapper-3 {
  font-family: Oswald, Helvetica;
  font-size: 16px;
  font-weight: 500;
}

.element-ipad .text-wrapper-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 766px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 20.4px;
  position: absolute;
  top: 410px;
  left: 0;
}

.element-ipad .element-menu {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px 10px;
  width: 706px;
  display: flex;
  position: absolute;
  top: 255px;
  left: 30px;
}

.element-ipad .div-wrapper {
  background-image: url("desktop-block-header.1513672d.png");
  background-size: 100% 100%;
  width: 233.28px;
  height: 51.68px;
  position: relative;
}

.element-ipad .text-wrapper-5 {
  color: #14a5ed;
  letter-spacing: .8px;
  text-align: center;
  width: 199px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 17px;
}

.element-ipad .element-2 {
  background-image: url("desktop-block-header.1513672d.png");
  background-size: 100% 100%;
  width: 233.28px;
  height: 51.68px;
  margin-bottom: -.34px;
  position: relative;
}

.element-ipad .SEAMLESS {
  color: #14a5ed;
  letter-spacing: .8px;
  text-align: center;
  width: 199px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 18px;
}

.element-ipad .BUILDING-LOYALTY {
  color: #14a5ed;
  letter-spacing: 2.4px;
  text-align: center;
  width: 198px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 18px;
}

.element-ipad .overlap-6 {
  width: 766px;
  height: 134px;
  position: absolute;
  top: 97px;
  left: 0;
}

.element-ipad .PAYMENTS-REWARDS {
  color: #f3f3f3;
  letter-spacing: 0;
  text-align: center;
  width: 766px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 53px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 54px;
  left: 0;
}

.element-ipad .text-wrapper-6 {
  font-weight: 500;
}

.element-ipad .text-wrapper-7 {
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
}

.element-ipad .MAKIN-CRYPTO {
  color: #f3f3f3;
  text-align: center;
  white-space: nowrap;
  width: 766px;
  height: 68px;
  font-family: Titillium Web, Helvetica;
  font-size: 86px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -25px;
  left: 0;
}

.element-ipad .text-wrapper-8 {
  letter-spacing: 0;
  font-weight: 100;
}

.element-ipad .text-wrapper-9 {
  letter-spacing: -12.9px;
  font-weight: 100;
}

.element-ipad .text-wrapper-10 {
  letter-spacing: 0;
  font-family: Oswald, Helvetica;
  font-size: 73px;
  font-weight: 500;
}

.element-ipad .top-ipad-section {
  width: 765px;
  height: 512px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .overlap-7 {
  width: 723px;
  height: 479px;
  position: relative;
  top: 141px;
  left: 49px;
}

.element-ipad .bottom-left-shadow {
  object-fit: cover;
  width: 288px;
  height: 159px;
  position: absolute;
  top: 320px;
  left: 272px;
}

.element-ipad .component {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.element-ipad .one {
  width: 513px !important;
  height: 605px !important;
  top: -70px !important;
}

.element-ipad .WITH-OURFREE-SIMPLE {
  width: 259px;
  height: 136px;
  position: absolute;
  top: 32px;
  left: 11px;
}

.element-ipad .overlap-8 {
  width: 253px;
  height: 136px;
  position: relative;
  top: 20px;
}

.element-ipad .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  width: 252px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 1px;
}

.element-ipad .payment-solution-you {
  color: #fff;
  width: 252px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: 28px;
  position: absolute;
  top: 67px;
  left: 0;
}

.element-ipad .text-wrapper-12 {
  letter-spacing: -1.54px;
}

.element-ipad .text-wrapper-13 {
  letter-spacing: 0;
  line-height: 26.6px;
}

.element-ipad .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 239px;
  font-family: Oswald, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 1px;
  -webkit-background-clip: text !important;
}

.element-ipad .wallet-section {
  align-self: center;
  width: 780px;
  height: 476px;
  margin-top: -43px;
  display: flex;
  position: relative;
}

.element-ipad .wallet-image {
  object-fit: cover;
  width: 327px;
  height: 384px;
  position: absolute;
  top: 52px;
  left: 402px;
}

.element-ipad .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 312px;
  height: 267px;
  display: flex;
  position: absolute;
  top: 122px;
  left: 62px;
}

.element-ipad .a-SEAMLESS-SUITE-OF {
  color: #000;
  width: 325px;
  height: 75px;
  margin-top: -1px;
  margin-right: -13px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 28.4px;
  position: relative;
}

.element-ipad .text-wrapper-14 {
  letter-spacing: -.75px;
  font-weight: 100;
}

.element-ipad .text-wrapper-15 {
  letter-spacing: 1.98px;
  font-family: Oswald, Helvetica;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
}

.element-ipad .text-wrapper-16 {
  letter-spacing: 2.16px;
  font-family: Oswald, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 27.2px;
}

.element-ipad .text-wrapper-17 {
  color: #272936;
  letter-spacing: 0;
  width: 312px;
  height: 56px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  position: relative;
}

.element-ipad .frame {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  width: 312px;
  display: flex;
  position: relative;
}

.element-ipad .text-wrapper-18 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-ipad .text-wrapper-19 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-ipad .currencies-section {
  width: 762px;
  height: 837px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .crystal-wrapper {
  width: 144px;
  height: 25px;
  position: absolute;
  top: 763px;
  left: 71px;
}

.element-ipad .crystal {
  width: 148px;
  height: 25px;
  position: relative;
}

.element-ipad .overlap-group-3 {
  width: 97px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 47px;
}

.element-ipad .rectangle-4 {
  height: 45px;
  position: absolute;
  top: 0;
  left: -10px;
}

.element-ipad .text-wrapper-20 {
  color: #000;
  letter-spacing: 0;
  width: 29px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 17px;
  left: 65px;
}

.element-ipad .POWERED-BY {
  color: #000;
  letter-spacing: 0;
  width: 41px;
  font-family: Titillium Web, Helvetica;
  font-size: 7px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 0;
}

.element-ipad .text-wrapper-21 {
  font-size: 8px;
  font-weight: 100;
}

.element-ipad .text-wrapper-22 {
  font-family: Oswald, Helvetica;
  font-size: 6px;
  font-weight: 500;
}

.element-ipad .element-black-blocks {
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
  width: 667px;
  display: flex;
  position: absolute;
  top: 650px;
  left: 48px;
}

.element-ipad .b {
  width: 221px;
  height: 106px;
  margin-left: -10px;
  position: relative;
}

.element-ipad .overlap-9 {
  width: 217px;
  height: 106px;
  position: relative;
}

.element-ipad .element-button {
  width: 217px;
  height: 106px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .overlap-group-4 {
  height: 106px;
  position: relative;
}

.element-ipad .rectangle-5 {
  filter: blur(28px);
  opacity: .5;
  background: radial-gradient(50% 50%, #0000003b 0%, #0000 64.81%);
  border-radius: 10px;
  width: 211px;
  height: 89px;
  position: absolute;
  top: 17px;
  left: 6px;
}

.element-ipad .rectangle-6 {
  background: radial-gradient(50% 50%, #000 0% 69.79%, #000 100%);
  border-radius: 10px;
  width: 211px;
  height: 89px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 1px 5px #fff9, inset 0 -8px 36px #0000004c;
}

.element-ipad .text-wrapper-23 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 167px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  line-height: 13.3px;
  position: absolute;
  top: 35px;
  left: 22px;
}

.element-ipad .text-wrapper-24 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 211px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 0;
}

.element-ipad .overlap-wrapper {
  width: 221px;
  height: 106px;
  position: relative;
}

.element-ipad .we-adhere-to-BSA-CIP {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 191px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  line-height: 13.3px;
  position: absolute;
  top: 33px;
  left: 13px;
}

.element-ipad .text-wrapper-25 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 211px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.element-ipad .overlap-group-wrapper {
  width: 221px;
  height: 106px;
  margin-right: -14px;
  position: relative;
}

.element-ipad .our-screening {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 175px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  line-height: 13.3px;
  position: absolute;
  top: 34px;
  left: 19px;
}

.element-ipad .overlap-10 {
  width: 1280px;
  height: 560px;
  position: absolute;
  top: 50px;
  left: -259px;
}

.element-ipad .security-header-text {
  width: 709px;
  height: 117px;
  position: absolute;
  top: 443px;
  left: 285px;
}

.element-ipad .text-wrapper-26 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 644px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 33px;
}

.element-ipad .SECURITY-AND {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 709px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-ipad .text-wrapper-27 {
  font-family: Oswald, Helvetica;
  font-size: 22px;
  font-weight: 500;
}

.element-ipad .carousel-instance {
  gap: 23px !important;
  width: 1280px !important;
  height: 377px !important;
  padding: 66px 131px !important;
  position: absolute !important;
  top: 75px !important;
}

.element-ipad .design-component-instance-node {
  padding: unset !important;
  gap: 6px !important;
}

.element-ipad .coins-overlay {
  width: 762px;
  height: 156px;
  position: absolute;
  top: 163px;
  left: 259px;
}

.element-ipad .overlap-11 {
  width: 245px;
  height: 156px;
  position: absolute;
  top: 0;
  left: 599px;
}

.element-ipad .rectangle-7 {
  width: 166px;
  height: 156px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .rectangle-8 {
  filter: blur(4px);
  background: linear-gradient(#f3f3f3 0%, #f3f3f300 100%);
  width: 242px;
  height: 130px;
  position: absolute;
  top: 13px;
  left: 3px;
}

.element-ipad .overlap-12 {
  width: 246px;
  height: 154px;
  position: absolute;
  top: 0;
  left: -94px;
}

.element-ipad .rectangle-9 {
  -webkit-backdrop-filter: blur(6px) brightness();
  backdrop-filter: blur(6px) brightness();
  background: linear-gradient(#ffffff08 0%, #fff0 100%);
  border: .76px solid #0000;
  border-image: linear-gradient(#f5fbf270, #f5fbf200) 1;
  border-radius: 11.42px;
  width: 236px;
  height: 154px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .rectangle-10 {
  filter: blur(4px);
  background: linear-gradient(#f3f3f3 0%, #f3f3f300 100%);
  width: 236px;
  height: 130px;
  position: absolute;
  top: 12px;
  left: 10px;
}

.element-ipad .header-text {
  width: 642px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 319px;
}

.element-ipad .expand-your-reach {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 642px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 83px;
  left: 0;
}

.element-ipad .text-wrapper-28 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
}

.element-ipad .text-wrapper-29 {
  font-weight: 700;
}

.element-ipad .header {
  width: 505px;
  height: 79px;
  position: absolute;
  top: 0;
  left: 69px;
}

.element-ipad .overlap-group-5 {
  height: 79px;
  position: relative;
  top: -1px;
}

.element-ipad .GET-PAID-IN {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 204px;
  font-family: Titillium Web, Helvetica;
  font-size: 38px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 11px;
}

.element-ipad .text-wrapper-30 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 302px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 203px;
  -webkit-background-clip: text !important;
}

.element-ipad .costs-section {
  align-self: center;
  width: 780px;
  height: 568px;
  margin-top: -10px;
  position: relative;
}

.element-ipad .text-wrapper-31 {
  color: #fff;
  letter-spacing: 1.24px;
  text-align: center;
  width: 644px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 374px;
  left: 62px;
}

.element-ipad .element-white-blocks {
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 277px;
  left: 82px;
}

.element-ipad .b-2 {
  width: 189.99px;
  height: 55.82px;
  position: relative;
}

.element-ipad .overlap-group-6 {
  border-radius: 10px;
  width: 188px;
  height: 56px;
  position: relative;
}

.element-ipad .shadows {
  background: linear-gradient(#fff 0% 100%);
  border-radius: 10px;
  width: 188px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: inset 6px 8px 8px #aeaec08c, inset -6px -6px 6px #ffffffb2;
}

.element-ipad .text-wrapper-32 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 105px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 42px;
}

.element-ipad .shadows-2 {
  background: linear-gradient(#fff 0% 100%);
  border-radius: 10px;
  width: 188px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: inset 6px 8px 8px #aeaec08c, inset -6px -6px 6px #ffffffb2;
}

.element-ipad .text-wrapper-33 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 144px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 17px;
  left: 22px;
}

.element-ipad .b-3 {
  width: 189.99px;
  height: 55.82px;
  margin-right: -2px;
  position: relative;
}

.element-ipad .text-wrapper-34 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 116px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 37px;
}

.element-ipad .text-block-2 {
  width: 663px;
  height: 132px;
  position: absolute;
  top: 113px;
  left: 50px;
}

.element-ipad .cut-your-transaction {
  color: #fff;
  letter-spacing: -.28px;
  text-align: center;
  width: 644px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 83px;
  left: 12px;
}

.element-ipad .DECREASE-YOUR-COSTS {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 663px;
  font-family: Titillium Web, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-ipad .text-wrapper-35 {
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
}

.element-ipad .bottom-ipad-section {
  width: 765px;
  height: 414px;
  margin-top: -10px;
  position: relative;
}

.element-ipad .overlap-13 {
  width: 474px;
  height: 499px;
  position: absolute;
  top: -20px;
  left: 7px;
}

.element-ipad .read-button-instance {
  border-radius: 6px !important;
  width: 159px !important;
  height: 27px !important;
  position: absolute !important;
  top: 381px !important;
  left: 70px !important;
}

.element-ipad .read-button-2 {
  width: 159px !important;
  font-size: 14px !important;
  top: 6px !important;
}

.element-ipad .ipad-shaddow-2 {
  width: 474px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .overlap-14 {
  height: 499px;
  position: relative;
}

.element-ipad .group {
  width: 495px;
  height: 466px;
  position: absolute;
  top: 18px;
  left: -11px;
  transform: rotate(90deg);
}

.element-ipad .overlap-group-7 {
  height: 466px;
  position: relative;
}

.element-ipad .shadows-3 {
  width: 176px;
  height: 175px;
  position: absolute;
  top: 4px;
  left: 319px;
  transform: rotate(-90deg);
}

.element-ipad .frame-2 {
  border-radius: 20px;
  width: 334px;
  height: 466px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .API-VID-TO-PLACE {
  background-color: #000;
  border-radius: 25px;
  width: 466px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 4px;
}

.element-ipad .API-GIF-SCREEN {
  border-radius: 25px;
  width: 462px;
  height: 328px;
  position: absolute;
  top: 10px;
}

.element-ipad .asset-7 {
  object-fit: cover;
  width: 474px;
  height: 339px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .text-block-3 {
  flex-direction: column;
  align-items: flex-end;
  gap: 18px;
  width: 210px;
  height: 419px;
  display: flex;
  position: absolute;
  top: -68px;
  left: 498px;
}

.element-ipad .frame-3 {
  flex-direction: column;
  align-items: flex-end;
  width: 210px;
  height: 146px;
  display: flex;
  position: relative;
}

.element-ipad .SUPERCHARGING-CRYPTO {
  color: #faf8f8;
  letter-spacing: 0;
  text-align: right;
  width: 225px;
  height: 92px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 25px;
  position: relative;
}

.element-ipad .text-wrapper-36 {
  line-height: 26px;
}

.element-ipad .text-wrapper-37 {
  font-size: 30.2px;
  line-height: 31.4px;
}

.element-ipad .WITH-STATE-OF-THE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  text-align: right;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 210px;
  height: 154px;
  margin-top: -9px;
  margin-bottom: -90px;
  font-family: Oswald, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: 30.2px;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-ipad .text-wrapper-38 {
  color: #e9edf3;
  letter-spacing: 0;
}

.element-ipad .text-wrapper-39 {
  color: #e9edf3;
  letter-spacing: -.56px;
}

.element-ipad .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 13px;
  width: 217px;
  margin-bottom: -13px;
  margin-left: -7px;
  padding: 0 0 13px;
  display: flex;
  position: relative;
}

.element-ipad .API-integration {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: right;
  width: 217px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element-ipad .text-wrapper-40 {
  font-size: 12px;
  line-height: 17.4px;
}

.element-ipad .text-wrapper-41 {
  font-size: 12px;
  line-height: 14.4px;
}

.element-ipad .SDK-integration {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: right;
  width: 216px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element-ipad .iframe-integration {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: right;
  width: 217px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element-ipad .iframe-section {
  width: 770px;
  height: 732px;
  margin-top: -43px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.element-ipad .overlap-15 {
  width: 489px;
  height: 661px;
  position: absolute;
  top: 168px;
  left: 260px;
}

.element-ipad .right-text {
  width: 221px;
  height: 61px;
  position: absolute;
  top: 212px;
  left: 268px;
}

.element-ipad .xionpay {
  width: 122px;
  height: 27px;
  position: absolute;
  top: 34px;
  left: 1px;
}

.element-ipad .OUR-CHECKOUT {
  color: #000;
  white-space: nowrap;
  width: 221px;
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: 27px;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-ipad .text-wrapper-42 {
  letter-spacing: 3.9px;
}

.element-ipad .text-wrapper-43 {
  letter-spacing: -1.04px;
}

.element-ipad .IPHONE {
  width: 250px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .overlap-16 {
  width: 410px;
  height: 500px;
  position: relative;
}

.element-ipad .group-2 {
  width: 415px;
  height: 660px;
  position: absolute;
  top: 1px;
  left: 2px;
}

.element-ipad .frame-wrapper {
  background-image: url("shadows-3.95bfb656.png");
  background-size: 100% 100%;
  width: 426px;
  height: 671px;
  position: relative;
  top: -1px;
}

.element-ipad .frame-5 {
  background-image: url("ipadphone.98e78db8.png");
  width: 250px;
  height: 500px;
  position: relative;
  top: 1px;
}

.element-ipad .IFRAME-IPAD {
  height: px;
  border-radius: 30px;
  width: 485px;
  position: absolute;
  top: 134px;
  left: -117px;
  transform: rotate(90deg);
}

.element-ipad .i-f {
  width: 250px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .left-text {
  width: 202px;
  height: 40px;
  position: absolute;
  top: 380px;
  left: 39px;
}

.element-ipad .line {
  width: 167px;
  height: 4px;
  position: absolute;
  top: 38px;
  left: 33px;
}

.element-ipad .text-wrapper-44 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 202px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: 26.3px;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-ipad .loyalty-CTA-section {
  width: 765px;
  height: 536px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .CTA-section {
  width: 765px;
  height: 217px;
  position: absolute;
  top: 350px;
  left: 1px;
}

.element-ipad .CTA-shaddow {
  mix-blend-mode: multiply;
  object-fit: cover;
  width: 303px;
  height: 19px;
  position: absolute;
  top: 167px;
  left: 231px;
}

.element-ipad .enjoy-the-freedom-of {
  color: #1185c3;
  letter-spacing: 1.32px;
  text-align: center;
  width: 765px;
  font-family: Titillium Web, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 27.6px;
  position: absolute;
  top: 91px;
  left: 0;
}

.element-ipad .CTA-buttons {
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 472px;
  display: flex;
  position: absolute;
  top: 46px;
  left: 146px;
}

.element-ipad .design-component-instance-node-2 {
  border-radius: 6px !important;
  width: 168px !important;
  height: 29px !important;
}

.element-ipad .design-component-instance-node-3 {
  width: 168px !important;
  font-size: 15px !important;
  top: 6px !important;
}

.element-ipad .loyalty-section {
  width: 765px;
  height: 188px;
  position: absolute;
  top: 162px;
  left: 1px;
}

.element-ipad .element-black-blocks-2 {
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 765px;
  display: flex;
  position: absolute;
  top: 89px;
  left: 0;
}

.element-ipad .b-4 {
  width: 218.19px;
  height: 80px;
  position: relative;
}

.element-ipad .overlap-17 {
  width: 216px;
  height: 80px;
  position: relative;
}

.element-ipad .element-d-button {
  width: 216px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .overlap-group-8 {
  height: 80px;
  position: relative;
}

.element-ipad .rectangle-11 {
  filter: blur(28px);
  opacity: .5;
  background: radial-gradient(50% 50%, #0000003b 0%, #0000 64.81%);
  border-radius: 10px;
  width: 211px;
  height: 67px;
  position: absolute;
  top: 13px;
  left: 6px;
}

.element-ipad .rectangle-12 {
  background: radial-gradient(50% 50%, #000 0% 69.79%, #000 100%);
  border-radius: 10px;
  width: 211px;
  height: 67px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 1px 5px #fff9, inset 0 -8px 36px #0000004c;
}

.element-ipad .monetize-blogs-apps {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 166px;
  height: 47px;
  font-family: Titillium Web, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 22.5px;
  position: absolute;
  top: 10px;
  left: 23px;
}

.element-ipad .works-with-any-all {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 158px;
  height: 47px;
  font-family: Titillium Web, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 22.5px;
  position: absolute;
  top: 10px;
  left: 26px;
}

.element-ipad .activate-loyalty {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 158px;
  height: 47px;
  font-family: Titillium Web, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 22.5px;
  position: absolute;
  top: 9px;
  left: 26px;
}

.element-ipad .header-text-2 {
  width: 644px;
  height: 114px;
  position: absolute;
  top: -71px;
  left: 60px;
}

.element-ipad .strengthen-your {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 644px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 72px;
  left: 0;
}

.element-ipad .header-2 {
  width: 426px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 104px;
}

.element-ipad .text-wrapper-45 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 137px;
  font-family: Titillium Web, Helvetica;
  font-size: 38px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-ipad .text-wrapper-46 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 280px;
  height: 39px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 146px;
  -webkit-background-clip: text !important;
}

.element-ipad .cash-back-section {
  width: 767px;
  height: 359px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .text-block-4 {
  flex-direction: column;
  align-items: flex-end;
  gap: 22px;
  width: 281px;
  display: flex;
  position: absolute;
  top: 122px;
  left: 424px;
}

.element-ipad .CASH-BACK {
  flex-direction: column;
  align-items: flex-end;
  height: 72px;
  margin-left: -32px;
  display: inline-flex;
  position: relative;
}

.element-ipad .text-wrapper-47 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 313px;
  height: 37px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-ipad .text-wrapper-48 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: right;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 309px;
  height: 72px;
  margin-top: -5px;
  font-family: Oswald, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-ipad .text-wrapper-49 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 292px;
  margin-left: -11px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-ipad .cash-back-image {
  mix-blend-mode: multiply;
  object-fit: cover;
  width: 379px;
  height: 263px;
  position: absolute;
  top: 106px;
  left: 32px;
}

.element-ipad .start-section {
  width: 765px;
  height: 381px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .overlap-18 {
  width: 706px;
  height: 361px;
  position: relative;
  top: 36px;
  left: 58px;
}

.element-ipad .start-image {
  object-fit: cover;
  width: 404px;
  height: 361px;
  position: absolute;
  top: 0;
  left: 302px;
}

.element-ipad .CTA-buttons-2 {
  align-items: flex-start;
  gap: 20px;
  width: 356px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 2px;
}

.element-ipad .text-block-5 {
  width: 332px;
  height: 123px;
  position: absolute;
  top: 140px;
  left: 0;
}

.element-ipad .overlap-group-9 {
  height: 123px;
  position: relative;
  top: -1px;
}

.element-ipad .text-wrapper-50 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 318px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .text-wrapper-51 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 332px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 50px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 44px;
  left: 0;
  -webkit-background-clip: text !important;
}

.element-ipad .footer-section {
  width: 643px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.element-ipad .frame-6 {
  align-items: flex-start;
  gap: 109px;
  width: 643px;
  display: flex;
  position: relative;
  top: 89px;
}

.element-ipad .XG-logo {
  width: 130px;
  height: 26.59px;
  position: relative;
}

.element-ipad .sections {
  flex: none;
  align-items: flex-start;
  gap: 37px;
  display: inline-flex;
  position: relative;
}

.element-ipad .div-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.element-ipad .text-wrapper-52 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-ipad .text-wrapper-53 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-ipad .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.element-ipad .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.element-ipad .text-wrapper-54 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.element-ipad .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 36px;
  left: 0;
}

.element-ipad .telegram-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.element-ipad .twitter-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.element-ipad .medium-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.element-ipad .linkedin-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.element-ipad .text-wrapper-55 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.element-ipad .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.element-ipad .overlap-group-10 {
  height: 17px;
  position: relative;
  top: -1px;
}

.element-ipad .text-wrapper-56 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.element-ipad .NAV {
  background-color: #f3f3f3;
  width: 768px;
  height: 120px;
  position: absolute;
  top: 0;
  left: calc(50% - 384px);
}

.element-ipad .frame-7 {
  align-items: center;
  gap: 262px;
  width: 709px;
  display: flex;
  position: relative;
  top: 47px;
  left: 29px;
}

.element-ipad .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.element-ipad .frame-8 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -1px;
  display: inline-flex;
  position: relative;
}

.element-ipad .GS-button-2 {
  width: 228.02px;
  height: 39px;
  position: relative;
}

.element-ipad .overlap-group-11 {
  background-image: url("shadows-10-9.4c71b635.png");
  background-size: 100% 100%;
  width: 226px;
  height: 39px;
  position: relative;
}

.element-ipad .rectangle-13 {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  text-align: center;
  cursor: pointer;
  width: 226px;
  height: 27px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 0;
}

.element-ipad .get-started-3:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.element-ipad .div-3 {
  width: 45px;
  height: 39px;
  position: relative;
}

.element-ipad .shadows-4 {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.element-ipad .shadows-5 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .rectangle-14 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad .group-3 {
  width: 21px;
  height: 15px;
  position: absolute;
  top: 13px;
  left: 14px;
}

.NAV-ip {
  z-index: 999999;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.frame-7-nav-ip {
  align-items: center;
  gap: 262px;
  width: 709px;
  display: flex;
  position: relative;
  top: 47px;
  left: 29px;
}

.xion-logo-ip {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.frame-8-ip {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -1px;
  display: inline-flex;
  position: relative;
}

.GS-button-2-ip {
  width: 228.02px;
  height: 39px;
  position: relative;
}

.overlap-group-11-ip {
  background-image: url("shadows-10-9.4c71b635.png");
  background-size: 100% 100%;
  width: 226px;
  height: 39px;
  position: relative;
}

.rectangle-13-ip {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.get-started-3-ip {
  color: #439be4;
  letter-spacing: 3.24px;
  text-align: center;
  cursor: pointer;
  width: 226px;
  height: 27px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 0;
}

.get-started-3-ip:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.div-3-ip {
  width: 45px;
  height: 39px;
  position: relative;
}

.shadows-4-ip {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.shadows-5-ip {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-14-ip {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-3-ip {
  width: 21px;
  height: 15px;
  position: absolute;
  top: 13px;
  left: 14px;
}

.telegram {
  width: 29px;
  height: 29px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.twitter {
  width: 28px;
  height: 28px;
  position: relative;
  left: 20px;
}

.twitter .overlap-group-wrapper {
  height: 28px;
}

.twitter .overlap-group {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.twitter .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.twitter .ellipse {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.twitter .rectangle {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.twitter.default {
  top: 20px;
}

.twitter.variant-2 {
  top: 68px;
}

.medium {
  width: 29px;
  height: 29px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.linkedin {
  width: 29px;
  height: 29px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.if-container {
  width: 768px;
  position: relative;
  top: -280px;
}

.footer-if {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 43px;
}

.start-if {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 1px;
  left: 29px;
}

.overlap-group-2-if {
  height: 289px;
  position: relative;
}

.rectangle-if {
  object-fit: cover;
  width: 709px;
  height: 286px;
  position: absolute;
  top: 341px;
  left: 0;
}

.gradient-if {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 341px;
  left: 0;
}

.mouse-if {
  width: 709px;
  height: 288px;
  position: absolute;
  top: 341px;
  left: 0;
}

.start-section-if {
  width: 765px;
  height: 381px;
  margin-top: -43px;
  position: relative;
}

.overlap-18-if {
  width: 706px;
  height: 361px;
  position: relative;
  top: 36px;
  left: 38px;
}

.start-image-if {
  object-fit: cover;
  width: 404px;
  height: 361px;
  position: absolute;
  top: 0;
  left: 302px;
}

.CTA-buttons-2-if {
  align-items: flex-start;
  gap: 20px;
  width: 356px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 2px;
}

.text-block-5-if {
  width: 332px;
  height: 123px;
  position: absolute;
  top: 140px;
  left: 0;
}

.overlap-group-9-if {
  height: 123px;
  position: relative;
  top: -1px;
}

.text-wrapper-50-if {
  color: #e9edf3;
  letter-spacing: 0;
  width: 318px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.text-wrapper-51-if {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 332px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 50px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 44px;
  left: 0;
  -webkit-background-clip: text !important;
}

.footer-section-if {
  width: 643px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.frame-6-if {
  justify-content: space-around;
  align-items: flex-start;
  gap: 85px;
  width: 643px;
  display: flex;
  position: relative;
  top: 80px;
  left: 45px;
}

.XG-logo-if {
  width: 130px;
  height: 26.59px;
  position: relative;
}

.sections-if {
  flex: none;
  align-items: flex-start;
  gap: 37px;
  display: inline-flex;
  position: relative;
}

.div-2-if {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.text-wrapper-52-if {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.text-wrapper-53-if {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  transition: color .3s;
  position: relative;
}

.text-wrapper-53-if:hover {
  color: #14a5ed;
}

.follow-us-if {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.follow-us-2-if {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.text-wrapper-54-if {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.address-wrapper-if {
  flex-direction: column;
  gap: 8px;
  padding-top: 96px;
  display: flex;
}

.address-if {
  color: #e8e8e8;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
}

.social-if {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 36px;
  left: 0;
}

.if-container .telegram-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  top: -1px;
  left: 0;
}

.if-container .telegram-footer-instance-if {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.if-container .twitter-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.if-container .twitter-footer-instance-if {
  left: unset !important;
  top: unset !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.if-container .medium-footer-instance-if {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.if-container .medium-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
}

.if-container .linkedin-footer-instance-if {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.if-container .linkedin-footer {
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  top: -1px;
  left: 0;
}

.text-wrapper-55-if {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.t-cs-if {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.overlap-group-10-if {
  height: 17px;
  position: relative;
  top: -1px;
}

.text-wrapper-56-if {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -85px;
  left: -530px;
}

.all-rights-reserved-if {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -85px;
  left: -480px;
}

.company-address-if {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -20px;
  left: 0;
}

.license-if {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 110px;
  left: -530px;
}

.license-if a {
  color: #e8e8e8;
  text-decoration: none;
}

.carousel-m {
  flex-direction: column;
  align-items: center;
  width: 1428px;
  height: 560px;
  padding: 40px 64px;
  display: flex;
  position: relative;
}

.carousel-m .slides-box {
  width: 360px;
  height: 229px;
  position: relative;
}

.carousel-m .slides-navigation {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-m .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-m .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.carousel-m .dot-indictaor-instance {
  left: unset !important;
  top: unset !important;
}

.carousel.three {
  top: 1282px;
  left: 16px;
}

.carousel.one {
  top: 16px;
}

.carousel.two {
  top: 649px;
  left: 16px;
}

.carousel.four {
  top: 1915px;
  left: 16px;
}

.carousel.five {
  top: 2548px;
  left: 16px;
}

.CS-button {
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CS-button .div {
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.CS-button.property-1-1-default {
  border-color: #14a5ed;
}

.CS-button.property-1-1-variant-2 {
  border-color: #7f64fb;
}

.CS-button.property-1-1-default .div {
  color: #14a5ed;
}

.CS-button.property-1-1-variant-2 .div {
  color: #7f64fb;
}

.GS-button {
  cursor: pointer;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.GS-button .get-started {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.GS-button.property-1-0-default {
  background-color: #14a5ed;
}

.GS-button.property-1-0-variant-2 {
  background-color: #7f64fb;
}

.read-button-m {
  cursor: pointer;
  border-radius: 10px;
  width: calc(100% - 20px);
  height: 39px;
  position: relative;
  left: 20px;
}

.read-button-m .text-wrapper {
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.read-button-m.default {
  background-color: #7f64fb;
  top: 20px;
}

.read-button-m.variant-2 {
  border: 1px solid #7f64fb;
  top: 79px;
}

.read-button-m.default .text-wrapper {
  color: #fff;
}

.read-button-m.variant-2 .text-wrapper {
  color: #7f64fb;
}

.element-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 4060px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 3921px;
  position: absolute;
  top: 0;
}

.element-m .M-banner {
  cursor: pointer;
  border-radius: 45px;
  width: 300px;
  height: 100px;
  margin-top: -58px;
  margin-left: 38px;
}

.element-m .overlap {
  width: 360px;
  height: 3920px;
  position: relative;
  top: 1px;
  left: 10px;
}

.element-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 3712px;
  left: 17px;
}

.element-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 3512px;
  left: 17px;
}

.element-m .overlap-group {
  background-image: url("rectangle-1960-2.ab72217a.png");
  background-position: 50%;
  background-size: cover;
  height: 198px;
  position: relative;
  top: 2px;
}

.element-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .element-ipad {
  width: 327px;
  height: 594px;
  display: flex;
  position: absolute;
  top: 2243px;
  left: 17px;
}

.element-m .overlap-2 {
  width: 327px;
  height: 594px;
  position: relative;
}

.element-m .rectangle {
  object-fit: cover;
  width: 327px;
  height: 592px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .img {
  object-fit: cover;
  width: 327px;
  height: 594px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .decrease {
  width: 327px;
  height: 386px;
  position: absolute;
  top: 1902px;
  left: 17px;
}

.element-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.element-m .overlap-3 {
  height: 795px;
  position: relative;
}

.element-m .rectangle-2 {
  object-fit: cover;
  width: 327px;
  height: 793px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .gradient-2 {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .noize {
  width: 360px;
  height: 3920px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.element-m .landing-p-section {
  width: 357px;
  height: 382px;
  margin-top: -43px;
  position: relative;
}

.element-m .overlap-4 {
  width: 357px;
  height: 89px;
  position: absolute;
  top: 290px;
  left: 0;
}

.element-m .logos {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 326px;
  padding: 0 10px;
  display: flex;
  position: absolute;
  top: 36px;
  left: 16px;
}

.element-m .asset {
  width: 89px;
  height: 19px;
  position: relative;
}

.element-m .asset-2 {
  width: 39px;
  height: 19px;
  position: relative;
}

.element-m .asset-3 {
  width: 104.83px;
  height: 19px;
  position: relative;
}

.element-m .asset-4 {
  width: 92px;
  height: 19px;
  position: relative;
}

.element-m .asset-5 {
  width: 104.83px;
  position: relative;
}

.element-m .TRUSTED-BY {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 357px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .span {
  font-weight: 100;
}

.element-m .text-wrapper-2 {
  font-family: Oswald, Helvetica;
  font-size: 14px;
  font-weight: 500;
}

.element-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 291px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 20.4px;
  position: absolute;
  top: 210px;
  left: 34px;
}

.element-m .overlap-5 {
  width: 357px;
  height: 114px;
  position: absolute;
  top: 66px;
  left: -7px;
}

.element-m .PAYMENTS-REWARDS {
  color: #f3f3f3;
  letter-spacing: 0;
  text-align: center;
  width: 374px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: 33px;
  position: absolute;
  top: 55px;
  left: 0;
}

.element-m .text-wrapper-3 {
  font-weight: 500;
  line-height: 27.1px;
}

.element-m .text-wrapper-4 {
  font-family: Titillium Web, Helvetica;
  font-size: 38px;
  font-weight: 100;
  line-height: 32.1px;
}

.element-m .MAKIN-CRYPTO {
  color: #f3f3f3;
  text-align: center;
  white-space: nowrap;
  width: 374px;
  height: 43px;
  font-family: Titillium Web, Helvetica;
  font-size: 45px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .text-wrapper-5 {
  letter-spacing: 0;
  font-weight: 100;
}

.element-m .text-wrapper-6 {
  letter-spacing: -6.75px;
  font-weight: 100;
}

.element-m .text-wrapper-7 {
  letter-spacing: 0;
  font-family: Oswald, Helvetica;
  font-size: 38px;
  font-weight: 500;
}

.element-m .top-ipad-section {
  width: 358px;
  height: 423px;
  margin-top: -43px;
  position: relative;
}

.element-m .bullet-points {
  flex-direction: column;
  align-items: center;
  gap: 11px;
  width: 266px;
  height: 122px;
  display: flex;
  position: absolute;
  top: 280px;
  left: 46px;
}

.element-m .text-wrapper-8 {
  color: #fffdff;
  letter-spacing: -.33px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-m .text-wrapper-9 {
  color: #fffdff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-m .WITH-OUR-FREE-SIMPLE {
  width: 333px;
  height: 136px;
  position: absolute;
  top: 130px;
  left: 16px;
}

.element-m .overlap-group-2 {
  width: 327px;
  height: 136px;
  position: relative;
}

.element-m .text-wrapper-10 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .payment-solution-you {
  color: #fff;
  text-align: center;
  width: 327px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: 28px;
  position: absolute;
  top: 67px;
  left: 0;
}

.element-m .text-wrapper-11 {
  letter-spacing: -1.54px;
}

.element-m .text-wrapper-12 {
  letter-spacing: 0;
  line-height: 26.6px;
}

.element-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 327px;
  font-family: Oswald, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 0;
  -webkit-background-clip: text !important;
}

.element-m .wallet-section {
  align-self: center;
  width: 374px;
  height: 368px;
  position: relative;
}

.element-m .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 312px;
  height: 224px;
  display: flex;
  position: relative;
  top: 101px;
  left: 24px;
}

.element-m .a-SEAMLESS-SUITE-OF {
  color: #000;
  text-align: center;
  width: 312px;
  height: 75px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.element-m .text-wrapper-13 {
  letter-spacing: -.66px;
  font-weight: 100;
}

.element-m .text-wrapper-14 {
  letter-spacing: 1.71px;
  font-family: Oswald, Helvetica;
  font-size: 19px;
  font-weight: 500;
  line-height: 21.6px;
}

.element-m .text-wrapper-15 {
  letter-spacing: 1.89px;
  font-family: Oswald, Helvetica;
  font-size: 21px;
  font-weight: 500;
  line-height: 23.8px;
}

.element-m .text-wrapper-16 {
  color: #272936;
  letter-spacing: 0;
  text-align: center;
  width: 312px;
  height: 61px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  position: relative;
}

.element-m .frame {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 312px;
  display: flex;
  position: relative;
}

.element-m .text-wrapper-17 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-m .text-wrapper-18 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-m .frame-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 312px;
  margin-bottom: -9px;
  display: flex;
  position: relative;
}

.element-m .text-wrapper-19 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-m .text-wrapper-20 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-m .currencies-section {
  width: 355px;
  height: 710px;
  margin-top: -70px;
  position: relative;
}

.element-m .overlap-6 {
  width: 332px;
  height: 153px;
  position: absolute;
  top: 557px;
  left: 18px;
}

.element-m .crystal {
  width: 122px;
  height: 22px;
  position: absolute;
  top: 99px;
  left: 182px;
}

.element-m .overlap-group-3 {
  width: 79px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 39px;
}

.element-m .rectangle-3 {
  height: 45px;
  position: absolute;
  top: 0;
  left: -8px;
}

.element-m .text-wrapper-21 {
  color: #000;
  letter-spacing: 0;
  width: 29px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 48px;
}

.element-m .POWERED-BY {
  color: #000;
  letter-spacing: 0;
  width: 34px;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 2px;
  left: 0;
}

.element-m .text-wrapper-22 {
  font-size: 7px;
  font-weight: 100;
}

.element-m .text-wrapper-23 {
  font-family: Oswald, Helvetica;
  font-size: 5px;
  font-weight: 500;
}

.element-m .element-black-blocks {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px 0;
  width: 332px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .b {
  width: 160.73px;
  height: 76.56px;
  position: relative;
}

.element-m .overlap-7 {
  width: 157px;
  height: 77px;
  position: relative;
}

.element-m .element-button {
  width: 157px;
  height: 77px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .overlap-group-4 {
  height: 77px;
  position: relative;
}

.element-m .rectangle-4 {
  filter: blur(28px);
  opacity: .5;
  background: radial-gradient(50% 50%, #0000003b 0%, #0000 64.81%);
  border-radius: 8px;
  width: 153px;
  height: 64px;
  position: absolute;
  top: 12px;
  left: 4px;
}

.element-m .rectangle-5 {
  background: radial-gradient(50% 50%, #000 0% 69.79%, #000 100%);
  border-radius: 8px;
  width: 153px;
  height: 64px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset 0 1px 5px #fff9, inset 0 -8px 36px #0000004c;
}

.element-m .text-wrapper-24 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 140px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-style: italic;
  font-weight: 700;
  line-height: 10.9px;
  position: absolute;
  top: 25px;
  left: 6px;
}

.element-m .text-wrapper-25 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 152px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 7px;
  left: 0;
}

.element-m .we-adhere-to-BSA-CIP {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 141px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-style: italic;
  font-weight: 700;
  line-height: 10.9px;
  position: absolute;
  top: 26px;
  left: 6px;
}

.element-m .our-screening {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 140px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-style: italic;
  font-weight: 700;
  line-height: 10.9px;
  position: absolute;
  top: 26px;
  left: 6px;
}

.element-m .security-text-block {
  width: 360px;
  height: 106px;
  position: absolute;
  top: 427px;
  left: -2px;
}

.element-m .text-wrapper-26 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 51px;
  left: 17px;
}

.element-m .SECURITY-AND {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 360px;
  font-family: Titillium Web, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-m .text-wrapper-27 {
  font-family: Oswald, Helvetica;
  font-size: 20px;
  font-weight: 500;
}

.element-m .overlap-8 {
  width: 360px;
  height: 310px;
  position: absolute;
  top: 61px;
  left: -2px;
}

.element-m .carousel-instance {
  width: 360px !important;
  height: 246px !important;
  position: absolute !important;
  top: 124px !important;
  left: 0 !important;
}

.element-m .design-component-instance-node {
  width: 360px !important;
  margin-bottom: -63px !important;
  margin-left: -65.5px !important;
  margin-right: -63.5px !important;
}

.element-m .carousel-2 {
  margin-bottom: -64px !important;
  margin-left: -.5px !important;
  margin-right: -.5px !important;
}

.element-m .coins-overlay {
  width: 359px;
  height: 54px;
  position: absolute;
  top: 192px;
  left: 0;
}

.element-m .rectangle-6 {
  filter: blur(4px);
  background: linear-gradient(#f3f3f3 0%, #f3f3f300 100%);
  width: 63px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 296px;
}

.element-m .rectangle-7 {
  filter: blur(4px);
  background: linear-gradient(#f3f3f3 0%, #f3f3f300 100%);
  width: 63px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.element-m .overlap-wrapper {
  width: 357px;
  height: 143px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .overlap-9 {
  height: 143px;
  position: relative;
}

.element-m .expand-your-reach {
  width: 329px;
  height: 62px;
  position: absolute;
  top: 81px;
  left: 16px;
}

.element-m .expand-your-reach-2 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .text-wrapper-28 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
}

.element-m .text-wrapper-29 {
  font-weight: 700;
}

.element-m .header {
  width: 357px;
  height: 89px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .overlap-group-5 {
  height: 89px;
  position: relative;
  top: -1px;
}

.element-m .GET-PAID-IN {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 357px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .text-wrapper-30 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 374px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 0;
  -webkit-background-clip: text !important;
}

.element-m .costs-section {
  align-self: center;
  width: 348px;
  height: 445px;
  position: relative;
  bottom: 12px;
  left: -6px;
}

.element-m .text-wrapper-31 {
  color: #fff;
  letter-spacing: 1.14px;
  text-align: center;
  width: 302px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 368px;
  left: 29px;
}

.element-m .element-white-blocks {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 10px 14px;
  width: 360px;
  height: 95px;
  display: flex;
  position: absolute;
  top: 255px;
  left: 0;
}

.element-m .overlap-group-wrapper {
  width: 139.18px;
  height: 40.57px;
  position: relative;
}

.element-m .overlap-group-6 {
  width: 137px;
  height: 41px;
  position: relative;
}

.element-m .shadows {
  background: linear-gradient(#fff 0% 100%);
  border-radius: 8px;
  width: 137px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 6px 8px 8px #aeaec08c, inset -6px -6px 6px #ffffffb2;
}

.element-m .text-wrapper-32 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 137px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.element-m .div-wrapper {
  width: 138.73px;
  height: 40.57px;
  position: relative;
}

.element-m .overlap-10 {
  border-radius: 8px;
  width: 137px;
  height: 41px;
  position: relative;
}

.element-m .shadows-2 {
  background: linear-gradient(#fff 0% 100%);
  border-radius: 8px;
  width: 137px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: inset 6px 8px 8px #aeaec08c, inset -6px -6px 6px #ffffffb2;
}

.element-m .text-wrapper-33 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 150px;
  height: 21px;
  font-family: Titillium Web, Helvetica;
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: -6px;
}

.element-m .b-2 {
  width: 138.64px;
  height: 40.57px;
  margin-bottom: -.15px;
  position: relative;
}

.element-m .text-wrapper-34 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 136px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.element-m .text-block-2 {
  width: 327px;
  height: 106px;
  position: absolute;
  top: 115px;
  left: 17px;
}

.element-m .cut-your-transaction {
  color: #fff;
  letter-spacing: -.24px;
  text-align: center;
  width: 283px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 57px;
  left: 22px;
}

.element-m .DECREASE-YOUR-COSTS {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-m .text-wrapper-35 {
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
}

.element-m .bottom-ipad-section {
  width: 359px;
  height: 580px;
  margin-top: -43px;
  position: relative;
}

.element-m .read-button-instance {
  border-radius: 6px !important;
  width: 159px !important;
  height: 27px !important;
  position: absolute !important;
  top: 500px !important;
  left: 100px !important;
}

.element-m .read-button-2 {
  width: 159px !important;
  font-size: 14px !important;
  top: 6px !important;
}

.element-m .text-block-3 {
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 210px;
  height: 369px;
  display: flex;
  position: absolute;
  top: 67px;
  left: 80px;
}

.element-m .frame-3 {
  flex-direction: column;
  align-items: center;
  width: 210px;
  height: 146px;
  display: flex;
  position: relative;
}

.element-m .SUPERCHARGING-CRYPTO {
  color: #faf8f8;
  letter-spacing: 0;
  text-align: center;
  width: 330px;
  height: 92px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 25px;
  position: relative;
}

.element-m .text-wrapper-36 {
  line-height: 26px;
}

.element-m .text-wrapper-37 {
  font-size: 25px;
  line-height: 31.4px;
}

.element-m .WITH-STATE-OF-THE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 210px;
  height: 154px;
  margin-top: -9px;
  margin-bottom: -90px;
  font-family: Oswald, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: 30.2px;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-m .text-wrapper-38 {
  color: #e9edf3;
  letter-spacing: 0;
}

.element-m .text-wrapper-39 {
  color: #e9edf3;
  letter-spacing: -.56px;
}

.element-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 217px;
  margin-bottom: -63px;
  margin-left: -3.5px;
  margin-right: -3.5px;
  padding: 0 0 13px;
  display: flex;
  position: relative;
}

.element-m .API-integration {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: center;
  width: 217px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element-m .text-wrapper-40 {
  font-size: 12px;
  line-height: 17.4px;
}

.element-m .text-wrapper-41 {
  font-size: 12px;
  line-height: 14.4px;
}

.element-m .SDK-integration {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: center;
  width: 216px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element-m .iframe-integration {
  color: #faf7f7;
  letter-spacing: 0;
  text-align: center;
  width: 217px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element-m .loyalty-CTA-section {
  width: 360px;
  height: 515px;
  margin-top: -43px;
  position: relative;
}

.element-m .CTA-section {
  width: 359px;
  height: 171px;
  position: absolute;
  top: 400px;
  left: 1px;
}

.element-m .shaddow-image {
  mix-blend-mode: multiply;
  object-fit: cover;
  width: 236px;
  height: 19px;
  position: absolute;
  top: 135px;
  left: 62px;
}

.element-m .enjoy-the-freedom-of {
  color: #1185c3;
  letter-spacing: .9px;
  text-align: center;
  width: 359px;
  font-family: Titillium Web, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.8px;
  position: absolute;
  top: 81px;
  left: 0;
}

.element-m .CTA-buttons {
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 327px;
  display: flex;
  position: absolute;
  top: 39px;
  left: 16px;
}

.element-m .GS-button-instance {
  border-radius: 5px !important;
  width: 134px !important;
  height: 23px !important;
}

.element-m .GS-button-2 {
  width: 134px !important;
  font-size: 12px !important;
  top: 5px !important;
}

.element-m .CS-button-instance {
  border-radius: 5px !important;
  width: 133px !important;
  height: 23px !important;
}

.element-m .CS-button-2 {
  width: 133px !important;
  font-size: 12px !important;
  top: 4px !important;
}

.element-m .loyalty-section {
  width: 360px;
  height: 273px;
  position: absolute;
  top: 96px;
  left: 1px;
}

.element-m .element-black-blocks-2 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 10px 5px;
  width: 327px;
  display: flex;
  position: absolute;
  top: 155px;
  left: 18px;
}

.element-m .b-3 {
  width: 155.27px;
  height: 56.71px;
  position: relative;
}

.element-m .overlap-11 {
  width: 153px;
  height: 57px;
  position: relative;
}

.element-m .element-d-button {
  width: 153px;
  height: 57px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .overlap-group-7 {
  height: 57px;
  position: relative;
}

.element-m .rectangle-8 {
  filter: blur(28px);
  opacity: .5;
  background: radial-gradient(50% 50%, #0000003b 0%, #0000 64.81%);
  border-radius: 8px;
  width: 149px;
  height: 47px;
  position: absolute;
  top: 9px;
  left: 4px;
}

.element-m .rectangle-9 {
  background: radial-gradient(50% 50%, #000 0% 69.79%, #000 100%);
  border-radius: 8px;
  width: 149px;
  height: 47px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 1px 5px #fff9, inset 0 -8px 36px #0000004c;
}

.element-m .monetize-blogs-apps {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 118px;
  height: 33px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  line-height: 16.2px;
  position: absolute;
  top: 6px;
  left: 16px;
}

.element-m .works-with-any-all {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 112px;
  height: 33px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  line-height: 16.2px;
  position: absolute;
  top: 7px;
  left: 19px;
}

.element-m .b-4 {
  width: 155.27px;
  height: 56.72px;
  position: relative;
}

.element-m .activate-loyalty {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 130px;
  height: 33px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  line-height: 16.2px;
  position: absolute;
  top: 7px;
  left: 10px;
}

.element-m .text-block-4 {
  width: 360px;
  height: 126px;
  position: absolute;
  top: -5px;
  left: 0;
}

.element-m .overlap-12 {
  height: 125px;
  position: relative;
}

.element-m .strengthen-your {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 80px;
  left: 16px;
}

.element-m .header-2 {
  width: 360px;
  height: 82px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .overlap-group-8 {
  height: 82px;
  position: relative;
  top: -1px;
}

.element-m .text-wrapper-42 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 360px;
  height: 39px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 30px;
  left: 0;
  -webkit-background-clip: text !important;
}

.element-m .text-wrapper-43 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 352px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .cash-back-section {
  width: 358px;
  height: 249px;
  margin-top: -43px;
  position: relative;
}

.element-m .mf-container {
  width: 360px;
  position: relative;
  top: 100px;
}

.element-m .text-block-5 {
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 327px;
  display: flex;
  position: relative;
  top: 120px;
  left: 16px;
}

.element-m .CASH-BACK {
  flex-direction: column;
  align-items: center;
  height: 64px;
  display: inline-flex;
  position: relative;
}

.element-m .text-wrapper-44 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 313px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 28px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-m .text-wrapper-45 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 309px;
  height: 59px;
  margin-top: -12px;
  margin-bottom: -14px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-m .text-wrapper-46 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 304px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-m .start-section {
  width: 356px;
  height: 252px;
  margin-top: -43px;
  position: relative;
}

.element-m .overlap-13 {
  width: 328px;
  height: 238px;
  position: relative;
  top: 25px;
  left: 30px;
}

.element-m .phone-image {
  object-fit: cover;
  width: 243px;
  height: 238px;
  position: absolute;
  top: 0;
  left: 85px;
}

.element-m .text-wrapper-47 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 87px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 122px;
  left: 0;
}

.element-m .text-wrapper-48 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 140px;
  height: 35px;
  font-family: Oswald, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 153px;
  left: 0;
  -webkit-background-clip: text !important;
}

.element-m .footer-section {
  width: 298px;
  height: 196px;
  margin-top: -43px;
  position: relative;
}

.element-m .frame-5 {
  align-items: flex-start;
  gap: 56px;
  width: 293px;
  display: flex;
  position: relative;
  top: 95px;
  left: 3px;
}

.element-m .XG-logo {
  width: 107px;
  height: 21.89px;
  position: relative;
}

.element-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.element-m .follow-us-2 {
  width: 136px;
  height: 92.8px;
  margin-right: -6px;
  position: relative;
}

.element-m .overlap-group-9 {
  width: 61px;
  height: 17px;
  position: absolute;
  top: 75px;
  left: 69px;
}

.element-m .text-wrapper-49 {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 13px;
}

.element-m .text-wrapper-50 {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  width: 130px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 30px;
  left: 0;
}

.element-m .social {
  align-items: flex-start;
  gap: 16.5px;
  width: 130px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .telegram-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.element-m .twitter-footer-instance {
  left: unset !important;
  top: unset !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.element-m .design-component-instance-node-2 {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.element-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 360px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.element-m .frame-wrapper {
  background-color: #f3f3f3;
  width: 359px;
  height: 120px;
}

.element-m .frame-6 {
  align-items: center;
  gap: 75px;
  display: inline-flex;
  position: relative;
  top: 53px;
  left: 19px;
}

.element-m .xion-logo {
  width: 25.4px;
  height: 30.71px;
  position: relative;
}

.element-m .frame-7 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.element-m .GS-button-3 {
  width: 176px;
  height: 30px;
  position: relative;
}

.element-m .overlap-group-10 {
  background-image: url("shadows-10-11.62e2594f.png");
  background-size: 100% 100%;
  width: 174px;
  height: 30px;
  position: relative;
}

.element-m .rectangle-10 {
  border: 1px solid #439be4;
  border-radius: 6px;
  width: 174px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .get-started-2 {
  color: #439be4;
  letter-spacing: 2.34px;
  text-align: center;
  cursor: pointer;
  width: 174px;
  height: 30px;
  font-family: Oswald, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 0;
}

.element-m .get-started-2:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.element-m .burger {
  width: 34.62px;
  height: 30px;
  position: relative;
}

.element-m .overlap-14 {
  width: 35px;
  height: 30px;
  position: relative;
}

.element-m .shadows-3 {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 6px;
  width: 33px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.element-m .shadows-4 {
  width: 35px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .rectangle-11 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 1px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 6px;
  width: 35px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m .group {
  width: 18px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 9px;
}

.element-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.element-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.element-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.element-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.NAV-mo {
  z-index: 999999;
  background-color: #f3f3f3;
  align-self: center;
  width: 360px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.frame-wrapper-mo {
  background-color: #f3f3f3;
  width: 359px;
  height: 120px;
}

.frame-6-mo {
  align-items: center;
  gap: 75px;
  display: inline-flex;
  position: relative;
  top: 53px;
  left: 19px;
}

.xion-logo-mo {
  width: 25.4px;
  height: 30.71px;
  position: relative;
}

.frame-7-mo {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.GS-button-3-mo {
  width: 176px;
  height: 30px;
  position: relative;
}

.overlap-group-10-mo {
  background-image: url("shadows-10-11.62e2594f.png");
  background-size: 100% 100%;
  width: 174px;
  height: 30px;
  position: relative;
}

.rectangle-10-mo {
  border: 1px solid #439be4;
  border-radius: 6px;
  width: 174px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.get-started-2-mo {
  color: #439be4;
  letter-spacing: 2.34px;
  text-align: center;
  cursor: pointer;
  width: 174px;
  height: 30px;
  font-family: Oswald, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 0;
}

.get-started-2-mo:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.burger-mo {
  width: 34.62px;
  height: 30px;
  position: relative;
}

.overlap-14-mo {
  width: 35px;
  height: 30px;
  position: relative;
}

.shadows-3-mo {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 6px;
  width: 33px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.shadows-4-mo {
  width: 35px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-11-mo {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 1px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 6px;
  width: 35px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-mo {
  width: 18px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 9px;
}

.telegram {
  width: 29px;
  height: 29px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.twitter {
  width: 28px;
  height: 28px;
  position: relative;
  left: 20px;
}

.twitter .overlap-group-wrapper {
  height: 28px;
}

.twitter .overlap-group {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.twitter .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.twitter .ellipse {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.twitter .rectangle {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.twitter.default {
  top: 20px;
}

.twitter.variant-2 {
  top: 68px;
}

.medium {
  width: 29px;
  height: 29px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.linkedin {
  width: 29px;
  height: 29px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.mf-container {
  width: 360px;
  position: relative;
  top: 10px;
}

.footer-mf {
  width: 327px;
  height: 196px;
  margin-top: 1px;
  position: relative;
}

.start-mf {
  width: 327px;
  height: 200px;
  position: absolute;
  left: 17px;
}

.overlap-group-mf {
  background-image: url("rectangle-1960-2.ab72217a.png");
  background-position: 50%;
  background-size: cover;
  height: 198px;
  position: relative;
  top: 2px;
}

.gradient-mf {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.start-section-mf {
  width: 356px;
  height: 252px;
  position: relative;
  top: -265px;
}

.overlap-13-mf {
  width: 328px;
  height: 238px;
  position: relative;
  top: 25px;
  left: 30px;
}

.phone-image-mf {
  object-fit: cover;
  width: 243px;
  height: 238px;
  position: absolute;
  top: 15px;
  left: 65px;
}

.text-wrapper-47-mf {
  color: #e9edf3;
  letter-spacing: 0;
  width: 87px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 122px;
  left: -20px;
}

.text-wrapper-48-mf {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 140px;
  height: 35px;
  font-family: Oswald, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 153px;
  left: -20px;
  -webkit-background-clip: text !important;
}

.footer-section-mf {
  width: 298px;
  height: 196px;
  margin-top: 28px;
  position: relative;
}

.XG-logo-mf {
  width: 107px;
  height: 21.89px;
  position: relative;
  top: -225px;
  left: 20px;
}

.follow-us-mf {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
  top: -155px;
  left: 65px;
}

.follow-us-2-mf {
  width: 136px;
  height: 92.8px;
  margin-right: -6px;
  position: relative;
}

.overlap-group-9-mf {
  width: 61px;
  height: 17px;
  position: absolute;
  top: 75px;
  left: 69px;
}

.text-wrapper-49-mf {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -50px;
  left: -220px;
}

.all-rights-reserved-mf {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -50px;
  left: -170px;
}

.text-wrapper-50-mf {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  cursor: pointer;
  width: 130px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  transition: color .3s;
  position: absolute;
  top: 30px;
  left: 0;
}

.text-wrapper-50-mf:hover {
  color: #14a5ed;
}

.social-mf {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6.5px;
  width: 130px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.telegram-footer-instance-mf {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.twitter-footer-instance-mf, .medium-footer-instance-mf, .linkedin-footer-instance-mf {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.design-component-instance-node-2 {
  left: unset !important;
  top: unset !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.company-address-mf {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -10px;
  left: 0;
}

.license-mf {
  color: #e8e8e8;
  letter-spacing: 0;
  width: 190px;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50px;
  left: -220px;
}

.license-mf a {
  color: #e8e8e8;
  text-decoration: none;
}

.menu-d {
  z-index: 9999;
  background-color: #fff0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.BG-elements-d {
  width: 1280px;
  height: 800px;
  position: absolute;
  top: 0;
  left: calc(50% - 640px);
}

.NAV-d {
  flex: none;
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 31px 50px 15px;
  display: flex;
  position: relative;
}

.nav-bar-d {
  border-radius: 14px;
  justify-content: space-between;
  align-items: flex-start;
  width: 1179px;
  padding: 17.5px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.xion-logo-d {
  cursor: pointer;
  height: 30.71px;
  position: relative;
}

.menu-buttons-d {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.GS-BUTTON-d {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 226.5px;
  display: flex;
  position: relative;
}

.GS-button-d {
  width: 228.5px;
  height: 39px;
  margin-right: -2px;
  position: relative;
}

.div-d {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.shadows-d {
  width: 226px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-2-d {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.get-started-d {
  color: #439be4;
  letter-spacing: 3.24px;
  text-align: center;
  width: 226px;
  height: 38px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 0;
}

.get-started-d:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.div-2-d {
  width: 45px;
  height: 39px;
  position: relative;
}

.shadows-2-d {
  background: linear-gradient(#fff 0% 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.img-d {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-3-d {
  -webkit-backdrop-filter: blur(10px) brightness();
  border: 1px solid #14a5ed;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-d {
  cursor: pointer;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 9px;
  left: 13px;
}

.menu-section-d {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 27px;
  width: 100%;
  margin-top: -49px;
  padding: 119px 130px 0;
  display: flex;
  position: relative;
}

.head-line-d {
  flex: none;
  align-items: flex-start;
  gap: 9px;
  width: 1020px;
  display: flex;
  position: relative;
}

.text-wrapper-d {
  color: #e9edf3;
  letter-spacing: 0;
  width: 107px;
  height: 58px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 40px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.text-wrapper-2-d {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 340px;
  height: 45px;
  margin-top: -1px;
  font-family: Oswald, Helvetica;
  font-size: 35px;
  font-weight: 500;
  line-height: 59.2px;
  position: relative;
  -webkit-background-clip: text !important;
}

.blue-line-d {
  width: 1024px;
  height: 4px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.frame-d {
  flex: none;
  align-self: center;
  align-items: flex-start;
  gap: 268px;
  display: inline-flex;
  position: relative;
}

.ONE-CLICK-PAYMENT-d {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 21px;
  width: 389.6px;
  display: flex;
  position: relative;
}

.text-wrapper-3-d {
  color: #e8e8e8;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame-2-d {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 23px;
  width: 100%;
  display: flex;
  position: relative;
}

.b-d {
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: auto;
  height: 147.31px;
  padding: 0 4.87e-9px 0 .91px;
  display: flex;
  position: relative;
}

.img-2-d {
  object-fit: cover;
  width: 181.04px;
  height: 111.59px;
  transition: all .2s;
  display: block;
  position: relative;
}

.text-wrapper-4-d {
  color: #fff;
  letter-spacing: 0;
  width: auto;
  height: 19.89px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.b-2-d {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 18px;
  width: auto;
  height: 149.85px;
  display: flex;
  position: relative;
}

.text-wrapper-5-d {
  color: #fff;
  letter-spacing: 0;
  width: 181px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.b-3-d {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 18px;
  width: auto;
  height: 146.44px;
  display: flex;
  position: relative;
}

.frame-3-d {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.div-3-d {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  padding: 0 20px;
  display: inline-flex;
  position: relative;
}

.text-wrapper-6-d {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.text-wrapper-7-d {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.text-wrapper-7-d:hover {
  color: #14a5ed;
}

.follow-us-d {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6px 20px;
  width: 106px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.text-wrapper-8-d {
  color: #e8e8e8;
  letter-spacing: 0;
  width: 79px;
  margin-top: -1px;
  margin-right: -13px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame-4-d {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
  width: 79px;
  margin-right: -13px;
  display: flex;
  position: relative;
}

.telegram-instance-d {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.03px !important;
  height: 29.03px !important;
  margin-top: -.74px !important;
  margin-left: -.74px !important;
  position: relative !important;
}

.twitter-instance-d {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  height: 27.55px !important;
}

.design-component-instance-node-d {
  width: 28px !important;
}

.medium-instance-d {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.47px !important;
  height: 29.46px !important;
  margin-bottom: -.73px !important;
  margin-left: -.74px !important;
  position: relative !important;
}

.linkedin-instance-d {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.47px !important;
  height: 29.46px !important;
  margin-bottom: -.73px !important;
  position: relative !important;
}

.element-ipad-menu {
  z-index: 9999;
  background-color: #fff0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.element-ipad-menu .BG-elements {
  width: 768px;
  height: 1024px;
  position: absolute;
  top: 0;
  left: calc(50% - 384px);
}

.element-ipad-menu .NAV {
  flex: none;
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 31px 50px 15px;
  display: flex;
  position: relative;
}

.element-ipad-menu .nav-bar-imenu {
  border-radius: 14px;
  justify-content: space-between;
  align-items: flex-start;
  width: 708px;
  padding: 17.5px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-ipad-menu .xion-logo {
  cursor: pointer;
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.element-ipad-menu .menu-buttons {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.element-ipad-menu .GS-BUTTON {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 226.5px;
  display: flex;
  position: relative;
}

.element-ipad-menu .GS-button {
  width: 228.5px;
  height: 39px;
  margin-right: -2px;
  position: relative;
}

.element-ipad-menu .div {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.element-ipad-menu .shadows {
  width: 226px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad-menu .rectangle-2 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.element-ipad-menu .get-started {
  color: #439be4;
  letter-spacing: 3.24px;
  text-align: center;
  width: 226px;
  height: 38px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 0;
}

.element-ipad-menu .get-started:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.element-ipad-menu .div-2 {
  cursor: pointer;
  width: 45px;
  height: 39px;
  position: relative;
}

.element-ipad-menu .shadows-2 {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.element-ipad-menu .img {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad-menu .rectangle-3 {
  -webkit-backdrop-filter: blur(10px) brightness();
  border: 1px solid #14a5ed;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-ipad-menu .group {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 9px;
  left: 13px;
}

.element-ipad-menu .menu-section {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 27px;
  width: 100%;
  margin-top: -49px;
  padding: 119px 130px 0;
  display: flex;
  position: relative;
}

.element-ipad-menu .head-line {
  flex: none;
  align-items: flex-start;
  gap: 9px;
  width: 597px;
  margin-left: -44.5px;
  margin-right: -44.5px;
  display: flex;
  position: relative;
}

.element-ipad-menu .text-wrapper {
  color: #e9edf3;
  letter-spacing: 0;
  width: 107px;
  height: 58px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 40px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .text-wrapper-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 340px;
  height: 45px;
  margin-top: -1px;
  font-family: Oswald, Helvetica;
  font-size: 35px;
  font-weight: 500;
  line-height: 59.2px;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-ipad-menu .blue-line {
  width: 602.06px;
  height: 4px;
  margin-left: -47.03px;
  margin-right: -47.03px;
  position: relative;
}

.element-ipad-menu .frame-imenu {
  flex: none;
  align-self: center;
  align-items: flex-start;
  gap: 10px;
  margin-left: -48.5px;
  margin-right: -48.5px;
  display: inline-flex;
  position: relative;
}

.element-ipad-menu .ONE-CLICK-PAYMENT {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 21px;
  width: 235px;
  display: flex;
  position: relative;
}

.element-ipad-menu .text-wrapper-3 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: 235px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .frame-2 {
  flex-flow: column wrap;
  flex: 1;
  place-content: flex-start space-between;
  align-items: baseline;
  gap: 23px;
  height: 482px;
  display: flex;
  position: relative;
}

.element-ipad-menu .b {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 181.95px;
  height: 147.31px;
  padding: 0 4.87e-9px 0 .91px;
  display: flex;
  position: relative;
}

.element-ipad-menu .img-2 {
  object-fit: cover;
  width: 181.04px;
  height: 111.59px;
  transition: all .2s;
  position: relative;
}

.element-ipad-menu .text-wrapper-4 {
  color: #fff;
  letter-spacing: 0;
  height: 19.89px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .b-2 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 18px;
  width: 181.14px;
  height: 149.85px;
  margin-left: -181.95px;
  display: flex;
  position: relative;
}

.element-ipad-menu .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  width: 181px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .b-3 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 14px;
  width: 181.45px;
  height: 146.44px;
  margin-bottom: -7.6px;
  margin-left: -181.95px;
  display: flex;
  position: relative;
}

.element-ipad-menu .frame-3 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element-ipad-menu .div-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  padding: 0 20px;
  display: inline-flex;
  position: relative;
}

.element-ipad-menu .text-wrapper-6 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .text-wrapper-7 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .text-wrapper-7:hover {
  color: #14a5ed;
}

.element-ipad-menu .follow-us {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6px 20px;
  width: 106px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.element-ipad-menu .text-wrapper-8 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: 79px;
  margin-top: -1px;
  margin-right: -13px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .frame-4 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
  width: 79px;
  margin-right: -13px;
  display: flex;
  position: relative;
}

.element-ipad-menu .telegram-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.03px !important;
  height: 29.03px !important;
  margin-top: -.74px !important;
  margin-left: -.74px !important;
  position: relative !important;
}

.element-ipad-menu .twitter-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  height: 27.55px !important;
}

.element-ipad-menu .design-component-instance-node {
  width: 28px !important;
}

.element-ipad-menu .medium-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.47px !important;
  height: 29.46px !important;
  margin-bottom: -.73px !important;
  margin-left: -.74px !important;
  position: relative !important;
}

.element-ipad-menu .linkedin-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.47px !important;
  height: 29.46px !important;
  margin-bottom: -.73px !important;
  position: relative !important;
}

.element-m-menu-m {
  background-color: #0000;
  flex-direction: column;
  align-items: center;
  height: 760px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-m-menu-m .BG-elements {
  width: 360px;
  height: 660px;
  position: absolute;
  top: 100px;
  left: calc(50% - 180px);
}

.element-m-menu-m .NAV {
  flex: none;
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 31px 50px 15px;
  display: flex;
  position: relative;
}

.element-m-menu-m .nav-bar {
  border-radius: 14px;
  justify-content: space-between;
  align-items: flex-start;
  width: 325px;
  margin-left: -32.5px;
  margin-right: -32.5px;
  padding: 17.5px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-m-menu-m .xion-logo {
  width: 25.4px;
  height: 30.71px;
  position: relative;
}

.element-m-menu-m .menu-buttons {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.element-m-menu-m .GS-button {
  width: 176px;
  height: 29.92px;
  position: relative;
}

.element-m-menu-m .div {
  width: 174px;
  height: 30px;
  position: relative;
}

.element-m-menu-m .shadows {
  width: 173px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m-menu-m .rectangle-2 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 1.5px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 6px;
  width: 174px;
  height: 29px;
  position: absolute;
  top: 1px;
  left: 0;
  transform: rotate(-180deg);
}

.element-m-menu-m .get-started {
  color: #439be4;
  letter-spacing: 2.52px;
  text-align: center;
  width: 171px;
  height: 29px;
  font-family: Oswald, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 1px;
}

.element-m-menu-m .get-started:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.element-m-menu-m .burger-x {
  cursor: pointer;
  width: 34.62px;
  height: 30px;
  position: relative;
}

.element-m-menu.m .overlap {
  width: 35px;
  height: 30px;
  position: relative;
}

.element-m-menu-m .shadows-2 {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 6px;
  width: 35px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.element-m-menu-m .img {
  width: 35px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m-menu-m .rectangle-3 {
  border: 1px solid #14a5ed;
  border-radius: 6px;
  width: 35px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-m-menu-m .group {
  width: 16px;
  height: 15px;
  position: absolute;
  top: 7px;
  left: 10px;
}

.element-m-menu-m .menu-section-m {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 27px;
  width: 100%;
  margin-top: -49px;
  padding: 87px 130px 0;
  display: flex;
  position: relative;
}

.element-m-menu-m .head-line {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 291px;
  display: flex;
  position: relative;
}

.element-m-menu-m .text-wrapper {
  color: #e9edf3;
  letter-spacing: 0;
  text-align: center;
  width: 107px;
  height: 58px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-m-menu-m .text-wrapper-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 292px;
  height: 45px;
  margin-top: -27px;
  margin-right: -1px;
  font-family: Oswald, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: 47.4px;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-m-menu-m .blue-line {
  width: 270px;
  height: 4px;
  position: relative;
}

.element-m-menu-m .frame {
  flex: none;
  align-items: flex-start;
  width: 288px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.element-m-menu-m .ONE-CLICK-PAYMENT {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 21px 8px;
  width: 162px;
  display: flex;
  position: relative;
}

.element-m-menu-m .text-wrapper-3 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: 162px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-m-menu-m .frame-2 {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 161px;
  height: 482px;
  display: flex;
  position: relative;
}

.element-m-menu-m .b {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 161px;
  height: 127px;
  display: flex;
  position: relative;
}

.element-m-menu-m .img-2 {
  object-fit: cover;
  width: 150px;
  height: 92.46px;
  transition: all .2s;
  position: relative;
}

.element-m-menu-m .text-wrapper-4 {
  color: #fff;
  letter-spacing: 0;
  width: 148.3px;
  height: 19.89px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-m-menu-m .b-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 161px;
  height: 118px;
  display: flex;
  position: relative;
}

.element-m-menu-m .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  width: 151px;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.element-m-menu-m .b-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 161px;
  height: 127px;
  display: flex;
  position: relative;
}

.element-m-menu-m .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 22px;
  margin-right: -3px;
  display: inline-flex;
  position: relative;
}

.element-m-menu-m .products {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  padding: 0 0 0 14px;
  display: inline-flex;
  position: relative;
}

.element-m-menu-m .text-wrapper-6 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-m-menu-m .text-wrapper-7 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-m-menu-m .text-wrapper-7:hover {
  color: #14a5ed;
}

.element-m-menu-m .follow-us {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 6px 20px;
  width: 106px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.element-m-menu-m .text-wrapper-8 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: 79px;
  margin-top: -1px;
  margin-left: -6.5px;
  margin-right: -6.5px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element-m-menu-m .frame-4 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
  width: 79px;
  margin-left: -6.5px;
  margin-right: -6.5px;
  display: flex;
  position: relative;
}

.element-m-menu-m .telegram-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.03px !important;
  height: 29.03px !important;
  margin-top: -.74px !important;
  margin-left: -.74px !important;
  position: relative !important;
}

.element-m-menu-m .twitter-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  height: 27.55px !important;
}

.element-m-menu-m .design-component-instance-node {
  width: 28px !important;
}

.element-m-menu-m .medium-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.47px !important;
  height: 29.46px !important;
  margin-bottom: -.73px !important;
  margin-left: -.74px !important;
  position: relative !important;
}

.element-m-menu-m .linkedin-instance {
  cursor: pointer;
  left: unset !important;
  top: unset !important;
  width: 29.47px !important;
  height: 29.46px !important;
  margin-bottom: -.73px !important;
  position: relative !important;
}

.HC-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 4800px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.HC-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.HC-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.HC-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.HC-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.HC-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.HC-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.HC-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.HC-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.HC-d .overlap-5 {
  height: 598px;
  position: relative;
}

.HC-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.HC-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.HC-d .main {
  width: 1280px;
  height: 574px;
  margin-top: -43px;
  position: relative;
}

.HC-d .overlap-6 {
  width: 1143px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.HC-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 129px;
  left: 0;
}

.HC-d .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 200px;
  display: flex;
  position: relative;
}

.HC-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.HC-d .FREE-SIMPLE {
  flex: none;
  width: 606.85px;
  margin-top: -2px;
  position: relative;
}

.HC-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.HC-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.HC-d .element-of {
  object-fit: cover;
  width: 600px;
  height: 654px;
  position: absolute;
  top: 0;
  left: 543px;
}

.HC-d .section {
  align-self: center;
  width: 1280px;
  height: 1785px;
  position: relative;
}

.HC-d .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 1525px;
  display: inline-flex;
  position: absolute;
  top: 207px;
  left: 163px;
}

.HC-d .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.HC-d .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.HC-d .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1010px;
  left: 765px;
}

.HC-d .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.HC-d .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.HC-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-d .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.HC-d .frame-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 200px;
  left: 750px;
}

.HC-d .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.HC-d .frame-5 {
  background-image: url("mask-group.df46c7ad.png");
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.HC-d .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.HC-d .group-9 {
  position: absolute !important;
  top: 463px !important;
  left: 846px !important;
}

.HC-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 25px;
  left: -85px;
}

.HC-d .overlap-wrapper {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 726px;
  left: 760px;
}

.HC-d .overlap-7 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 20px;
}

.HC-d .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.HC-d .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.HC-d .anti-clockwise-instance {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 25px !important;
  left: 13px !important;
  transform: rotate(-165deg) !important;
}

.HC-d .design-component-instance-node {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 24px !important;
  left: 143px !important;
  transform: rotate(-129.01deg) !important;
}

.HC-d .anti-clockwise-2 {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 25px !important;
  left: 271px !important;
  transform: rotate(75deg) !important;
}

.HC-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1273px;
  left: 750px;
}

.HC-d .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.HC-d .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.HC-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1590px !important;
  left: 743px !important;
}

.HC-d .component-instance {
  position: absolute;
  top: 1039px;
  left: 846px;
}

.HC-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
  top: 1200px;
}

.HC-d .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.HC-d .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.HC-d .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.HC-d .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.HC-d .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-d .text-wrapper-19 {
  color: #272936;
}

.HC-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.HC-d .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.HC-d .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.HC-d .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.HC-d .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.HC-d .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.HC-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.HC-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.HC-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.HC-d .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.HC-d .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.HC-d .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.HC-d .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.HC-d .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.HC-d .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.HC-d .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.HC-d .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.HC-d .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.HC-d .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.HC-d .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.HC-d .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.HC-d .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.HC-d .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.HC-d .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.HC-d .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.HC-d .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 1280px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.HC-d .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.HC-d .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.HC-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.HC-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.HC-d .overlap-group-wrapper {
  background-image: url("shadows-10-1.ce0d78c2.svg");
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.HC-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.HC-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.HC-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.HC-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.HC-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.HC-d .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 500px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.HC-d .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.HC-d .PL {
  z-index: 1;
  width: 492px;
  height: 731px;
  position: absolute;
  top: 2200px;
  left: 620px;
}

.HC-d .HCC {
  z-index: 1;
  width: 492px;
  height: 731px;
  position: absolute;
  top: 2200px;
  left: 145px;
}

.HC-d .TEXTa {
  z-index: 2;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 2550px;
  left: 190px;
}

.HC-d .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.HC-d .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.HC-d .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.HC-d .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-d .bullet-list {
  list-style-type: none;
}

.HC-d .bullet-list li {
  padding-left: 20px;
  font-size: 14px;
  position: relative;
}

.HC-d .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.HC-d .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 2550px;
  left: 665px;
}

.HC-d .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 491px;
  display: inline-flex;
  position: relative;
}

.HC-d .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.HC-d .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.HC-d .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-d .text-containera, .HC-d .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.HC-d .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1750px;
}

.HC-d .df-container {
  width: 1280px;
  position: relative;
  top: 950px;
}

.HC-d .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 2800px !important;
  left: 190px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.HC-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.HC-d .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 2800px !important;
  left: 665px !important;
}

.HC-d .GS-button-2:hover {
  background-color: #7f64fb;
}

.HC-d .GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.HC-d .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.HC-d .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-d .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
}

.HC-d .text-wrapper-44-2 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
  left: 160px;
}

.HC-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 4630px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.HC-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.HC-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.HC-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.HC-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.HC-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.HC-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.HC-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-i .if-container {
  width: 768px;
  position: relative;
  top: -850px;
}

.HC-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.HC-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.HC-i .overlap-5 {
  height: 598px;
  position: relative;
}

.HC-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.HC-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.HC-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.HC-i .main {
  width: 768px;
  height: 574px;
  position: relative;
}

.HC-i .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.HC-i .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 55px;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: -65px;
}

.HC-i .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 100px;
  display: flex;
  position: relative;
}

.HC-i .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.HC-i .FREE-SIMPLE {
  width: 383.28px;
  height: 35.28px;
  position: relative;
}

.HC-i .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 386px;
  height: 39px;
  margin-top: -4px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.HC-i .p {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 74px;
  margin-bottom: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-i .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 225px;
}

.HC-i .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.HC-i .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
}

.HC-i .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.HC-i .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.HC-i .component-4 {
  width: 360px;
}

.HC-i .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.HC-i .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.HC-i .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-i .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.HC-i .frame-4 {
  height: 117px;
  position: absolute;
  top: 110px;
  left: 450px;
}

.HC-i .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.HC-i .frame-5 {
  background-image: url("mask-group.df46c7ad.png");
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-i .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.HC-i .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.HC-i .group-9 {
  width: 340px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 425px;
  overflow: hidden;
}

.HC-i .group-2 {
  height: 117px;
  position: absolute;
  top: 20px;
  left: 40px;
}

.HC-i .overlap-wrapper {
  height: 164px;
  position: absolute;
  top: 750px;
  left: 386px;
  overflow: hidden;
}

.HC-i .overlap-7 {
  height: 146px;
  position: relative;
  top: -2px;
  left: 35px;
}

.HC-i .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.HC-i .design-component-instance-node {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 24px !important;
  left: 143px !important;
  transform: rotate(-129.01deg) !important;
}

.HC-i .frame-lock {
  width: 360px;
  position: absolute;
  top: 1390px;
  left: 425px;
}

.HC-i .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.HC-i .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.HC-i .component-3 {
  width: 346px;
  position: absolute;
  top: 1700px;
  left: 435px;
}

.HC-i .component-instance {
  position: absolute !important;
  top: 1100px !important;
  left: 435px !important;
}

.HC-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -550px;
}

.HC-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.HC-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.HC-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.HC-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.HC-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-i .text-wrapper-19 {
  color: #272936;
}

.HC-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.HC-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.HC-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.HC-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.HC-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.HC-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.HC-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.HC-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.HC-i .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.HC-i .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.HC-i .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.HC-i .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.HC-i .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.HC-i .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.HC-i .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.HC-i .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.HC-i .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.HC-i .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.HC-i .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.HC-i .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.HC-i .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.HC-i .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.HC-i .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.HC-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.HC-i .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-i .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.HC-i .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.HC-i .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.HC-i .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.HC-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.HC-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.HC-i .overlap-group-wrapper {
  background-image: url("shadows-10-1.ce0d78c2.svg");
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.HC-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.HC-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.HC-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.HC-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.HC-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-i .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.HC-i .PL {
  z-index: 1;
  width: 400px;
  height: 639px;
  position: relative;
  top: -380px;
  left: 205px;
}

.HC-i .HCC {
  z-index: 1;
  width: 400px;
  height: 639px;
  position: relative;
  top: 490px;
  left: -150px;
}

.HC-i .TEXTa {
  z-index: 2;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 315px;
  display: flex;
  position: relative;
  top: -710px;
  left: -155px;
}

.HC-i .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.HC-i .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.HC-i .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.HC-i .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-i .bullet-list {
  list-style-type: none;
}

.HC-i .bullet-list li {
  padding-left: 20px;
  font-size: 13px;
  position: relative;
}

.HC-i .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.HC-i .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: relative;
  top: 160px;
  left: 520px;
}

.HC-i .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 491px;
  display: inline-flex;
  position: relative;
}

.HC-i .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.HC-i .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.HC-i .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
}

.HC-i .text-containera, .HC-i .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.HC-i .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 2150px;
  left: 70px;
}

.HC-i .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.HC-i .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: relative !important;
  top: 185px !important;
  left: 150px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.HC-i .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.HC-i .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-i .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
}

.HC-i .text-wrapper-44-2 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
  left: 160px;
}

.HC-i .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: relative !important;
  top: -690px !important;
  left: -200px !important;
}

.HC-i .GS-button:hover {
  background-color: #7f64fb;
}

.HC-i .GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.HC-i .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.HC-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 4680px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.HC-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.HC-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.HC-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.HC-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.HC-m .mf-container {
  width: 360px;
  position: relative;
  top: 1110px;
}

.HC-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.HC-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.HC-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.HC-m .overlap-5 {
  height: 435px;
  position: relative;
}

.HC-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.HC-m .main {
  width: 360px;
  height: 384px;
  position: relative;
}

.HC-m .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.HC-m .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: 58px;
  left: 40px;
}

.HC-m .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 289px;
  height: 106px;
  display: flex;
  position: relative;
}

.HC-m .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.HC-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  align-self: center;
  height: 94px;
  margin-top: -16px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  left: 4px;
  -webkit-background-clip: text !important;
}

.HC-m .FREE-SIMPLE-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  align-self: center;
  height: 94px;
  margin-top: -12px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  left: 4px;
  -webkit-background-clip: text !important;
}

.HC-m .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 289px;
  height: 39px;
  margin-bottom: -44px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -12px;
}

.HC-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 15px;
}

.HC-m .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 255px;
}

.HC-m .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.HC-m .TEXT-BLOCK {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: relative;
  top: 137px;
  left: 23px;
}

.HC-m .line-2 {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.HC-m .TEXT {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.HC-m .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.HC-m .component-4 {
  width: 243px;
  height: 96px;
}

.HC-m .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.HC-m .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.HC-m .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-m .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  position: relative;
}

.HC-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.HC-m .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.HC-m .frame-5 {
  background-image: url("mask-group.df46c7ad.png");
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.HC-m .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.HC-m .group-9 {
  width: 239px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 445px;
  overflow: hidden;
}

.HC-m .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.HC-m .overlap-wrapper {
  width: 267px;
  height: 164px;
  position: absolute;
  top: 713px;
  left: 438px;
  overflow: hidden;
}

.HC-m .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: -2px;
  left: 1px;
}

.HC-m .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.HC-m .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.HC-m .anti-clockwise-instance {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 25px !important;
  left: 13px !important;
  transform: rotate(-165deg) !important;
}

.HC-m .design-component-instance-node {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 24px !important;
  left: 143px !important;
  transform: rotate(-129.01deg) !important;
}

.HC-m .anti-clockwise-2 {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 25px !important;
  left: 271px !important;
  transform: rotate(75deg) !important;
}

.HC-m .frame-lock {
  width: 243px;
  height: 89px;
  position: absolute;
  top: 1287px;
  left: 443px;
}

.HC-m .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.HC-m .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.HC-m .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.HC-m .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.HC-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: 1100px;
}

.HC-m .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.HC-m .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.HC-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.HC-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.HC-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-m .text-wrapper-19 {
  color: #272936;
}

.HC-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.HC-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.HC-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.HC-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.HC-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.HC-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.HC-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.HC-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.HC-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.HC-m .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.HC-m .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.HC-m .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.HC-m .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.HC-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.HC-m .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.HC-m .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.HC-m .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.HC-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.HC-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.HC-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.HC-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.HC-m .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.HC-m .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.HC-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.HC-m .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.HC-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.HC-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.HC-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.HC-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.HC-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.HC-m .overlap-group-wrapper {
  background-image: url("shadows-10-1.ce0d78c2.svg");
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.HC-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.HC-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.HC-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.HC-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.HC-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.HC-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.HC-m .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.HC-m .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 400px;
}

.HC-m .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -70px;
  position: absolute !important;
  top: 350px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.HC-m .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.HC-m .PL {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 3420px;
}

.HC-m .HCC {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 2900px;
}

.HC-m .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.HC-m .TEXTa {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 740px;
}

.HC-m .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  left: 35px;
}

.HC-m .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  top: -8px;
  left: 35px;
}

.HC-m .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}

.HC-m .bullet-list {
  list-style-type: none;
}

.HC-m .bullet-list li {
  padding-left: 20px;
  font-size: 10px;
  position: relative;
}

.HC-m .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.HC-m .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 1070px;
}

.HC-m .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 315px;
  display: inline-flex;
  position: relative;
}

.HC-m .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  top: 40px;
  left: 35px;
}

.HC-m .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  top: 35px;
  left: 35px;
}

.HC-m .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.HC-m .text-containera, .HC-m .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.HC-m .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.HC-m .text-wrapper-444 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.HC-m .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -35px;
  position: relative !important;
  top: 615px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.HC-m .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.HC-m .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}

.HC-m .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -35px;
  position: relative !important;
  top: 1095px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.HC-m .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.Loyalty-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3850px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Loyalty-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.Loyalty-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.Loyalty-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.Loyalty-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.Loyalty-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.Loyalty-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.Loyalty-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.Loyalty-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.Loyalty-d .overlap-5 {
  height: 598px;
  position: relative;
}

.Loyalty-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.Loyalty-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.Loyalty-d .main {
  width: 1280px;
  height: 574px;
  margin-top: -43px;
  position: relative;
}

.Loyalty-d .overlap-6 {
  width: 1143px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.Loyalty-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 100px;
  display: inline-flex;
  position: absolute;
  top: 129px;
  left: 0;
}

.Loyalty-d .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 200px;
  display: flex;
  position: relative;
}

.Loyalty-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.Loyalty-d .a-SIMPLE-2 {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 2px;
}

.Loyalty-d .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 606.85px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 57px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.Loyalty-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -35px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.Loyalty-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.Loyalty-d .element-of {
  object-fit: cover;
  width: 600px;
  height: 654px;
  position: absolute;
  top: 0;
  left: 543px;
}

.Loyalty-d .section {
  align-self: center;
  width: 1280px;
  height: 1785px;
  position: relative;
}

.Loyalty-d .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 1525px;
  display: inline-flex;
  position: absolute;
  top: 207px;
  left: 163px;
}

.Loyalty-d .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.Loyalty-d .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.Loyalty-d .component-4 {
  width: 446px;
  height: 176px;
}

.Loyalty-d .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.Loyalty-d .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.Loyalty-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-d .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.Loyalty-d .frame-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 220px;
  left: 700px;
}

.Loyalty-d .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.Loyalty-d .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.Loyalty-d .div-8 {
  color: #0000;
  letter-spacing: -.35px;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-d .text-wrapper-119 {
  color: #14a5ed;
  font-weight: 700;
}

.Loyalty-d .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.Loyalty-d .group-9 {
  position: absolute !important;
  top: 520px !important;
  left: 700px !important;
}

.Loyalty-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
}

.Loyalty-d .overlap-wrapper {
  width: 446px;
  position: absolute;
  top: 726px;
  left: 710px;
}

.Loyalty-d .overlap-7 {
  width: 446px;
  height: 176px;
  position: relative;
  top: 95px;
  left: 1px;
}

.Loyalty-d .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.Loyalty-d .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.Loyalty-d .anti-clockwise-instance {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 25px !important;
  left: 13px !important;
  transform: rotate(-165deg) !important;
}

.Loyalty-d .design-component-instance-node {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 24px !important;
  left: 143px !important;
  transform: rotate(-129.01deg) !important;
}

.Loyalty-d .anti-clockwise-2 {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 25px !important;
  left: 271px !important;
  transform: rotate(75deg) !important;
}

.Loyalty-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1470px;
  left: 710px;
}

.Loyalty-d .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.Loyalty-d .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.Loyalty-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1810px !important;
  left: 700px !important;
}

.Loyalty-d .component-instance {
  position: absolute !important;
  top: 1145px !important;
  left: 715px !important;
}

.Loyalty-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
  top: 250px;
}

.Loyalty-d .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.Loyalty-d .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.Loyalty-d .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.Loyalty-d .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.Loyalty-d .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-d .text-wrapper-19 {
  color: #272936;
}

.Loyalty-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.Loyalty-d .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.Loyalty-d .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.Loyalty-d .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.Loyalty-d .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.Loyalty-d .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.Loyalty-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.Loyalty-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.Loyalty-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.Loyalty-d .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.Loyalty-d .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.Loyalty-d .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.Loyalty-d .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.Loyalty-d .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.Loyalty-d .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.Loyalty-d .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.Loyalty-d .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.Loyalty-d .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.Loyalty-d .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.Loyalty-d .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.Loyalty-d .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.Loyalty-d .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.Loyalty-d .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.Loyalty-d .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.Loyalty-d .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.Loyalty-d .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.Loyalty-d .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 1280px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.Loyalty-d .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.Loyalty-d .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.Loyalty-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.Loyalty-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.Loyalty-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.Loyalty-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.Loyalty-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.Loyalty-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.Loyalty-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.Loyalty-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.Loyalty-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3850px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Loyalty-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.Loyalty-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.Loyalty-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.Loyalty-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.Loyalty-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.Loyalty-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.Loyalty-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-i .if-container {
  width: 768px;
  position: relative;
  top: 125px;
}

.Loyalty-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.Loyalty-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.Loyalty-i .overlap-5 {
  height: 598px;
  position: relative;
}

.Loyalty-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.Loyalty-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.Loyalty-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.Loyalty-i .main {
  width: 768px;
  height: 574px;
  position: relative;
}

.Loyalty-i .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.Loyalty-i .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 82px;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: -65px;
}

.Loyalty-i .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 100px;
  display: flex;
  position: relative;
}

.Loyalty-i .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 10px;
}

.Loyalty-i .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 383.28px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.Loyalty-i .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 97px;
  margin-bottom: -36px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: 33px;
  position: relative;
}

.Loyalty-i .payment-solution-you-2 {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 97px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: 33px;
  position: relative;
  top: 2px;
}

.Loyalty-i .p {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 74px;
  margin-bottom: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-i .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 245px;
}

.Loyalty-i .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.Loyalty-i .TEXT-BLOCK {
  z-index: 5;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
}

.Loyalty-i .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.Loyalty-i .text-wrapper-119 {
  color: #14a5ed;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 700;
}

.Loyalty-i .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.Loyalty-i .component-4 {
  width: 346px;
  height: 176px;
}

.Loyalty-i .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.Loyalty-i .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.Loyalty-i .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-i .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.Loyalty-i .frame-4 {
  z-index: 5;
  width: 446px;
  height: 176px;
  position: absolute;
  top: 90px;
  left: 344px;
}

.Loyalty-i .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.Loyalty-i .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-i .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.Loyalty-i .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.Loyalty-i .group-9 {
  z-index: 1;
  width: 446px;
  height: 176px;
  position: relative;
  top: 428px;
  left: 365px;
  overflow: hidden;
}

.Loyalty-i .group-2 {
  width: 446px;
  height: 166px;
  position: absolute;
  top: 11px;
}

.Loyalty-i .overlap-wrapper {
  width: 446px;
  height: 500px;
  position: absolute;
  top: 830px;
  left: 370px;
  overflow: hidden;
}

.Loyalty-i .overlap-7 {
  width: 446px;
  height: 176px;
  position: relative;
  top: -2px;
  left: 1px;
}

.Loyalty-i .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.Loyalty-i .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.Loyalty-i .design-component-instance-node {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 24px !important;
  left: 143px !important;
  transform: rotate(-129.01deg) !important;
}

.Loyalty-i .frame-lock {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 1630px;
  left: 410px;
}

.Loyalty-i .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.Loyalty-i .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.Loyalty-i .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 2050px;
  left: 360px;
}

.Loyalty-i .component-instance {
  position: absolute !important;
  top: 1250px !important;
  left: 410px !important;
}

.Loyalty-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: 425px;
}

.Loyalty-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.Loyalty-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.Loyalty-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.Loyalty-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.Loyalty-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-i .text-wrapper-19 {
  color: #272936;
}

.Loyalty-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.Loyalty-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.Loyalty-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.Loyalty-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.Loyalty-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.Loyalty-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.Loyalty-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.Loyalty-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.Loyalty-i .XG-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.Loyalty-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.Loyalty-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.Loyalty-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.Loyalty-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.Loyalty-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.Loyalty-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.Loyalty-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.Loyalty-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-i .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.Loyalty-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3600px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Loyalty-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.Loyalty-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.Loyalty-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.Loyalty-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.Loyalty-m .mf-container {
  width: 360px;
  position: relative;
  top: 600px;
}

.Loyalty-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.Loyalty-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.Loyalty-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.Loyalty-m .overlap-5 {
  height: 435px;
  position: relative;
}

.Loyalty-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.Loyalty-m .main {
  width: 360px;
  height: 384px;
  position: relative;
}

.Loyalty-m .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.Loyalty-m .text-block {
  flex-direction: column;
  align-items: center;
  gap: 60px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: 25px;
  left: 43px;
}

.Loyalty-m .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 289px;
  height: 106px;
  display: flex;
  position: relative;
}

.Loyalty-m .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.Loyalty-m .a-SIMPLE-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.Loyalty-m .a-SIMPLE-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 129px;
  margin-top: -10px;
  margin-bottom: -70px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: 33px;
  position: relative;
  top: -10px;
}

.Loyalty-m .a-SIMPLE-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 129px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: 33px;
  position: relative;
  top: -8px;
}

.Loyalty-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  align-self: center;
  width: 283.53px;
  height: 94px;
  margin-top: -16px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  left: 4px;
  -webkit-background-clip: text !important;
}

.Loyalty-m .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 129px;
  margin-top: -15px;
  margin-bottom: -70px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: 33px;
  position: relative;
}

.Loyalty-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 45px;
}

.Loyalty-m .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 255px;
}

.Loyalty-m .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.Loyalty-m .TEXT-BLOCK {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: relative;
  top: 137px;
  left: 23px;
}

.Loyalty-m .line-2 {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.Loyalty-m .TEXT {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.Loyalty-m .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.Loyalty-m .component-4 {
  width: 243px;
  height: 96px;
}

.Loyalty-m .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.Loyalty-m .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.Loyalty-m .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-m .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  position: relative;
}

.Loyalty-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.Loyalty-m .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.Loyalty-m .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.Loyalty-m .div-8 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-m .text-wrapper-119 {
  color: #14a5ed;
  font-weight: 700;
}

.Loyalty-m .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.Loyalty-m .group-9 {
  width: 239px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 445px;
  overflow: hidden;
}

.Loyalty-m .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.Loyalty-m .overlap-wrapper {
  width: 267px;
  height: 164px;
  position: absolute;
  top: 713px;
  left: 438px;
  overflow: hidden;
}

.Loyalty-m .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: -2px;
  left: 1px;
}

.Loyalty-m .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.Loyalty-m .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.Loyalty-m .design-component-instance-node {
  mix-blend-mode: overlay !important;
  border-radius: 100px !important;
  width: 118px !important;
  height: 118px !important;
  position: absolute !important;
  top: 24px !important;
  left: 143px !important;
  transform: rotate(-129.01deg) !important;
}

.Loyalty-m .frame-lock {
  width: 243px;
  height: 89px;
  position: absolute;
  top: 1287px;
  left: 443px;
}

.Loyalty-m .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.Loyalty-m .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.Loyalty-m .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.Loyalty-m .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.Loyalty-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: 600px;
}

.Loyalty-m .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.Loyalty-m .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.Loyalty-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.Loyalty-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.Loyalty-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.Loyalty-m .text-wrapper-19 {
  color: #272936;
}

.Loyalty-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.Loyalty-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.Loyalty-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.Loyalty-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.Loyalty-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.Loyalty-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.Loyalty-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.Loyalty-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.Loyalty-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.Loyalty-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.Loyalty-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.Loyalty-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.Loyalty-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.Loyalty-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.Loyalty-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.Loyalty-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.Loyalty-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.Loyalty-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.Loyalty-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.Loyalty-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.Loyalty-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.Loyalty-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.Loyalty-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.Loyalty-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.CB-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3570px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CB-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.CB-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.CB-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.CB-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.CB-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.CB-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.CB-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.CB-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.CB-d .overlap-5 {
  height: 598px;
  position: relative;
}

.CB-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.CB-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.CB-d .main {
  width: 1280px;
  height: 574px;
  margin-top: -43px;
  position: relative;
}

.CB-d .overlap-6 {
  width: 1143px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.CB-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 129px;
  left: 0;
}

.CB-d .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 200px;
  display: flex;
  position: relative;
}

.CB-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.CB-d .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 606.85px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 57px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.CB-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -20px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.CB-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  margin-top: 25px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.CB-d .element-of {
  object-fit: cover;
  width: 600px;
  height: 654px;
  position: absolute;
  top: 0;
  left: 543px;
}

.CB-d .section {
  align-self: center;
  width: 1280px;
  height: 1785px;
  position: relative;
}

.CB-d .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 1525px;
  display: inline-flex;
  position: absolute;
  top: 207px;
  left: 163px;
}

.CB-d .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.CB-d .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.CB-d .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1005px;
  left: 750px;
}

.CB-d .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CB-d .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CB-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CB-d .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.CB-d .frame-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 200px;
  left: 750px;
}

.CB-d .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.CB-d .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-d .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.CB-d .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.CB-d .group-9 {
  position: absolute !important;
  top: 463px !important;
  left: 776px !important;
}

.CB-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
  left: -20px;
}

.CB-d .overlap-wrapper {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 726px;
  left: 760px;
}

.CB-d .overlap-7 {
  height: 176px;
  position: relative;
  top: -2px;
  left: 0;
}

.CB-d .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.CB-d .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.CB-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 752px;
}

.CB-d .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.CB-d .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.CB-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1600px !important;
  left: 682px !important;
}

.CB-d .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1700px !important;
  left: 865px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.CB-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CB-d .component-instance {
  position: absolute;
  top: 1039px;
  left: 846px;
}

.CB-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
}

.CB-d .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.CB-d .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.CB-d .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.CB-d .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.CB-d .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CB-d .text-wrapper-19 {
  color: #272936;
}

.CB-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.CB-d .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.CB-d .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.CB-d .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.CB-d .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.CB-d .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-d .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.CB-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.CB-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.CB-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.CB-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.CB-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CB-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.CB-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CB-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.CB-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.CB-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.CB-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.CB-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3390px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CB-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.CB-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.CB-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.CB-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.CB-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.CB-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.CB-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-i .if-container {
  width: 768px;
  position: relative;
  top: -350px;
}

.CB-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.CB-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.CB-i .overlap-5 {
  height: 598px;
  position: relative;
}

.CB-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.CB-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.CB-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.CB-i .main {
  width: 768px;
  height: 574px;
  position: relative;
}

.CB-i .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.CB-i .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 55px;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: -65px;
}

.CB-i .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 100px;
  display: flex;
  position: relative;
}

.CB-i .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 10px;
}

.CB-i .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 383.28px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.CB-i .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 386px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.CB-i .p {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 74px;
  margin-bottom: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CB-i .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 225px;
}

.CB-i .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.CB-i .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
}

.CB-i .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CB-i .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.CB-i .component-4 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 980px;
  left: 430px;
  overflow: hidden;
}

.CB-i .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CB-i .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CB-i .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CB-i .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.CB-i .frame-4 {
  z-index: 1;
  width: 346px;
  height: 176px;
  position: absolute;
  top: 80px;
  left: 444px;
}

.CB-i .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.CB-i .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-i .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.CB-i .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1750px;
  left: 490px;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.CB-i .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CB-i .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.CB-i .group-9 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 325px;
  left: 445px;
  overflow: hidden;
}

.CB-i .group-2 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 20px;
}

.CB-i .overlap-wrapper {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 613px;
  left: 400px;
  overflow: hidden;
}

.CB-i .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: 20px;
  left: 1px;
}

.CB-i .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.CB-i .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.CB-i .frame-lock {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 420px;
}

.CB-i .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.CB-i .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.CB-i .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.CB-i .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.CB-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -40px;
}

.CB-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.CB-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CB-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.CB-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.CB-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CB-i .text-wrapper-19 {
  color: #272936;
}

.CB-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.CB-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.CB-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.CB-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.CB-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.CB-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.CB-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.CB-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.CB-i .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.CB-i .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.CB-i .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.CB-i .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.CB-i .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.CB-i .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.CB-i .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.CB-i .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.CB-i .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.CB-i .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.CB-i .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.CB-i .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.CB-i .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.CB-i .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.CB-i .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.CB-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.CB-i .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-i .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.CB-i .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.CB-i .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.CB-i .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.CB-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.CB-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CB-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.CB-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CB-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.CB-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.CB-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.CB-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-i .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.CB-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 2946px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CB-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.CB-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.CB-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.CB-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.CB-m .mf-container {
  width: 360px;
  position: relative;
  top: -40px;
}

.CB-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.CB-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.CB-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.CB-m .overlap-5 {
  height: 435px;
  position: relative;
}

.CB-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.CB-m .main {
  width: 360px;
  height: 384px;
  position: relative;
}

.CB-m .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.CB-m .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: 58px;
  left: 42px;
}

.CB-m .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 289px;
  height: 106px;
  display: flex;
  position: relative;
}

.CB-m .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.CB-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 283.53px;
  margin-top: -16px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.CB-m .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 289px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.CB-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 15px;
}

.CB-m .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1630px;
  left: 66px;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.CB-m .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CB-m .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 255px;
}

.CB-m .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.CB-m .TEXT-BLOCK {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: relative;
  top: 137px;
  left: 23px;
}

.CB-m .line-2 {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.CB-m .TEXT {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CB-m .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.CB-m .component-4 {
  width: 243px;
  height: 96px;
}

.CB-m .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CB-m .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CB-m .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CB-m .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  position: relative;
}

.CB-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.CB-m .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.CB-m .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.CB-m .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.CB-m .group-9 {
  width: 239px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 445px;
  overflow: hidden;
}

.CB-m .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.CB-m .overlap-wrapper {
  width: 267px;
  height: 164px;
  position: absolute;
  top: 713px;
  left: 438px;
  overflow: hidden;
}

.CB-m .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: -2px;
  left: 1px;
}

.CB-m .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.CB-m .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.CB-m .frame-lock {
  width: 243px;
  height: 89px;
  position: absolute;
  top: 1287px;
  left: 443px;
}

.CB-m .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.CB-m .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.CB-m .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.CB-m .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.CB-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: -50px;
}

.CB-m .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.CB-m .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CB-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.CB-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.CB-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CB-m .text-wrapper-19 {
  color: #272936;
}

.CB-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.CB-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.CB-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CB-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.CB-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.CB-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.CB-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.CB-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.CB-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.CB-m .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.CB-m .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.CB-m .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.CB-m .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.CB-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.CB-m .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.CB-m .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.CB-m .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.CB-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.CB-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.CB-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.CB-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.CB-m .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.CB-m .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.CB-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.CB-m .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.CB-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.CB-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.CB-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.CB-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.CB-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CB-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.CB-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CB-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.CB-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.CB-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.CB-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CB-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.about-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3600px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.about-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.about-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.about-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.about-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.about-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.about-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.about-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.about-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.about-d .overlap-5 {
  height: 598px;
  position: relative;
}

.about-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.about-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.about-d .main-3 {
  width: 1280px;
  height: 611px;
  margin-top: -43px;
  position: relative;
}

.about-d .overlap-21 {
  width: 915px;
  height: 312px;
  position: relative;
  top: 200px;
  left: 133px;
}

.about-d .ABOUT-US {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -10.05px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 915px;
  font-family: Oswald, Helvetica;
  font-size: 201px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-clip: text !important;
}

.about-d .xion-global-was-wrapper {
  align-self: center;
  width: 1280px;
  height: 642px;
  margin-top: -43px;
  position: relative;
}

.about-d .xion-global-was-2 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1054px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  top: 173px;
  left: 113px;
}

.about-d .text-wrapper-102 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
}

.about-d .text-wrapper-103 {
  font-weight: 100;
}

.about-d .section-8 {
  width: 1280px;
  height: 684px;
  position: relative;
}

.about-d .our-e-commerce-2 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1054px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 100;
  line-height: 22.7px;
  position: absolute;
  top: 194px;
  left: 113px;
}

.about-d .FREE-SIMPLE-7 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 352px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 464px;
  -webkit-background-clip: text !important;
}

.about-d .section-9 {
  width: 1280px;
  height: 498px;
  margin-top: 86px;
  position: relative;
}

.about-d .frame-7-df {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 720px;
  left: 144px;
}

.about-d .FREE-SIMPLE-8 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 249px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 516px;
  -webkit-background-clip: text !important;
}

.about-d .frame-23 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 1054px;
  display: flex;
  position: absolute;
  top: 205px;
  left: 112px;
}

.about-d .group-13 {
  background-color: #d9d9d903;
  width: 159px;
  height: 58px;
  position: relative;
}

.about-d .vector-20, .about-d .vector-21, .about-d .vector-22, .about-d .vector-23, .about-d .vector-24, .about-d .vector-25, .about-d .vector-26, .about-d .vector-27, .about-d .vector-28, .about-d .vector-29, .about-d .vector-30, .about-d .vector-31, .about-d .vector-32, .about-d .vector-33, .about-d .vector-34, .about-d .vector-35, .about-d .vector-36, .about-d .vector-37 {
  width: 159px;
  position: absolute;
}

.about-d .section-10 {
  width: 1280px;
  height: 389px;
  margin-top: -105px;
  position: relative;
}

.about-d .FREE-SIMPLE-9 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 145px;
  font-family: Oswald, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 568px;
  -webkit-background-clip: text !important;
}

.about-d .df-container {
  width: 1280px;
  position: relative;
  top: -250px;
}

.about-d .frame-24 {
  width: 305px;
  height: 52px;
  position: absolute;
  top: 160px;
  left: 484px;
}

.about-d .aaa-ba-e-2 {
  object-fit: cover;
  width: 145px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-d .img-7 {
  object-fit: cover;
  width: 104px;
  height: 44px;
  position: absolute;
  top: 3px;
  left: 185px;
}

.about-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.about-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.about-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.about-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.about-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.about-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.about-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.about-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.about-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.about-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.about-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.about-ipad {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3000px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.about-ipad .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.about-ipad .overlap-4 {
  height: 3524px;
  position: relative;
}

.about-ipad .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.about-ipad .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.about-ipad .overlap-group-5 {
  height: 638px;
  position: relative;
}

.about-ipad .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.about-ipad .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .if-container {
  width: 768px;
  position: relative;
  top: -300px;
}

.about-ipad .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.about-ipad .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.legal-i .section-8 {
  width: 764px;
  height: 3786px;
  margin-top: -43px;
  position: relative;
}

.about-ipad .overlap-5 {
  height: 598px;
  position: relative;
}

.about-ipad .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.about-ipad .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.about-ipad .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.about-ipad .main-8 {
  width: 760px;
  height: 427px;
  margin-top: -43px;
  position: relative;
}

.about-ipad .overlap-43 {
  width: 623px;
  height: 312px;
  position: relative;
  top: 160px;
  left: 66px;
}

.about-ipad .ABOUT-US-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -6.85px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 623px;
  font-family: Oswald, Helvetica;
  font-size: 137px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-clip: text !important;
}

.about-ipad .linked-in-6 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 186px;
  left: 7px;
}

.about-ipad .overlap-group-37 {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.about-ipad .shadows-m-3 {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.about-ipad .ellipse-14 {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .path-3 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.about-ipad .linked-in-7 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 186px;
  left: 47px;
}

.about-ipad .linked-in-8 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 186px;
  left: 87px;
}

.about-ipad .section-19 {
  align-self: center;
  width: 768px;
  height: 590px;
  position: relative;
}

.about-ipad .xion-global-was-3 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 618px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.2px;
  position: absolute;
  top: 103px;
  left: 75px;
}

.about-ipad .text-wrapper-182 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.2px;
}

.about-ipad .text-wrapper-183 {
  font-weight: 100;
}

.about-ipad .section-20 {
  width: 764px;
  height: 658px;
  margin-top: -43px;
  position: relative;
}

.about-ipad .our-e-commerce-3 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 618px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 100;
  line-height: 18.2px;
  position: absolute;
  top: 164px;
  left: 73px;
}

.about-ipad .FREE-SIMPLE-14 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 352px;
  font-family: Oswald, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 206px;
  -webkit-background-clip: text !important;
}

.about-ipad .section-21 {
  width: 764px;
  height: 366px;
  margin-top: 83px;
  position: relative;
}

.about-ipad .FREE-SIMPLE-15 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 249px;
  font-family: Oswald, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 34px;
  left: 258px;
  -webkit-background-clip: text !important;
}

.about-ipad .frame-68 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 618px;
  display: flex;
  position: absolute;
  top: 122px;
  left: 73px;
}

.about-ipad .group-24 {
  background-color: #d9d9d903;
  width: 107.54px;
  height: 39.23px;
  position: relative;
}

.about-ipad .vector-38, .about-ipad .vector-39, .about-ipad .vector-40, .about-ipad .vector-41, .about-ipad .vector-42, .about-ipad .vector-43, .about-ipad .vector-44, .about-ipad .vector-45, .about-ipad .vector-46, .about-ipad .vector-47, .about-ipad .vector-48, .about-ipad .vector-49, .about-ipad .vector-50, .about-ipad .vector-51, .about-ipad .vector-52, .about-ipad .vector-53, .about-ipad .vector-54, .about-ipad .vector-55 {
  width: 107px;
  height: 39px;
  position: absolute;
}

.about-ipad .section-22 {
  width: 764px;
  height: 274px;
  margin-top: -43px;
  position: relative;
}

.about-ipad .FREE-SIMPLE-16 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 145px;
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 310px;
  -webkit-background-clip: text !important;
}

.about-ipad .frame-69 {
  width: 305px;
  height: 52px;
  position: absolute;
  top: 143px;
  left: 226px;
}

.about-ipad .audited-by {
  object-fit: cover;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .img-8 {
  object-fit: cover;
  width: 104px;
  height: 44px;
  position: absolute;
  top: 3px;
  left: 185px;
}

.about-ipad .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.about-ipad .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.about-ipad .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.about-ipad .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.about-ipad .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.about-ipad .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.about-ipad .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.about-ipad .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.about-ipad .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.about-ipad .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.about-ipad .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.about-ipad .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.about-ipad .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.about-ipad .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.about-ipad .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.about-ipad .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.about-ipad .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.about-ipad .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.about-ipad .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.about-ipad .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.about-ipad .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.about-ipad .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.about-ipad .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.about-ipad .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.about-ipad .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.about-ipad .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.about-ipad .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.about-ipad .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.about-ipad .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.about-ipad .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.about-ipad .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.about-ipad .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.about-ipad .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-ipad .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.about-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3000px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.about-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.about-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.about-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.about-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.about-m .mf-container {
  width: 360px;
  position: relative;
  top: -25px;
}

.about-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.about-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.about-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.about-m .overlap-5 {
  height: 435px;
  position: relative;
}

.about-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.about-m .main {
  width: 360px;
  height: 220px;
  margin-top: -43px;
  position: relative;
}

.about-m .FREE-SIMPLE {
  width: 283px;
  height: 48px;
  position: absolute;
  top: 120px;
  left: 39px;
}

.about-m .linked-in {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 148px;
  left: 39px;
}

.about-m .overlap-group-8 {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.about-m .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.about-m .ellipse-12 {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .path {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.about-m .div-wrapper {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 148px;
  left: 79px;
}

.about-m .linked-in-2 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 148px;
  left: 119px;
}

.about-m .section {
  align-self: center;
  width: 360px;
  height: 792px;
  position: relative;
}

.about-m .xion-global-was {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 320px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.9px;
  position: absolute;
  top: 103px;
  left: 19px;
}

.about-m .text-wrapper-66 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.9px;
}

.about-m .text-wrapper-67 {
  font-weight: 100;
}

.about-m .section-2 {
  width: 359px;
  height: 742px;
  position: relative;
}

.about-m .our-e-commerce {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 323px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 15.9px;
  position: absolute;
  top: 70px;
  left: 12px;
}

.about-m .FREE-SIMPLE-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 352px;
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-clip: text !important;
}

.about-m .section-3 {
  width: 360px;
  height: 391px;
  margin-top: 83px;
  position: relative;
}

.about-m .overlap-9 {
  width: 320px;
  height: 338px;
  position: relative;
}

.about-m .FREE-SIMPLE-3 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 249px;
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 41px;
  -webkit-background-clip: text !important;
}

.about-m .frame-4 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px 20px;
  width: 330px;
  display: flex;
  position: absolute;
  top: 68px;
  left: 0;
}

.about-m .group-11 {
  background-color: #d9d9d903;
  width: 107.54px;
  height: 39.23px;
  position: relative;
}

.about-m .vector-2, .about-m .vector-3, .about-m .vector-4, .about-m .vector-5, .about-m .vector-6, .about-m .vector-7, .about-m .vector-8, .about-m .vector-9, .about-m .vector-10, .about-m .vector-11, .about-m .vector-12, .about-m .vector-13, .about-m .vector-14, .about-m .vector-15, .about-m .vector-16, .about-m .vector-17, .about-m .vector-18, .about-m .vector-19 {
  width: 107.54px;
  height: 39.23px;
  position: absolute;
}

.about-m .section-4 {
  width: 360px;
  height: 274px;
  position: relative;
}

.about-m .FREE-SIMPLE-4 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 145px;
  font-family: Oswald, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 105px;
  -webkit-background-clip: text !important;
}

.about-m .frame-5 {
  width: 305px;
  height: 52px;
  position: absolute;
  top: 143px;
  left: 21px;
}

.about-m .audited-by {
  object-fit: cover;
  width: 305px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .img-6 {
  object-fit: cover;
  width: 104px;
  height: 44px;
  position: absolute;
  top: 3px;
  left: 185px;
}

.about-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.about-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
}

.about-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.about-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.about-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.about-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.about-m .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.about-m .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.about-m .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.about-m .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.about-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.about-m .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.about-m .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.about-m .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.about-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.about-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.about-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.about-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.about-m .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.about-m .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.about-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.about-m .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.about-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.about-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.about-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.about-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.about-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.about-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.about-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.about-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.about-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.about-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.about-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.legal-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 8500px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.legal-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 8282px;
  position: absolute;
  top: 0;
}

.legal-d .overlap-4 {
  height: 8282px;
  position: relative;
}

.legal-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.legal-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.legal-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.legal-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.legal-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.legal-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.legal-d .overlap-5 {
  height: 598px;
  position: relative;
}

.legal-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.legal-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.legal-d .main-3 {
  width: 1280px;
  height: 611px;
  margin-top: -43px;
  position: relative;
}

.legal-d .ABOUT-US-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -10.05px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 793px;
  font-family: Oswald, Helvetica;
  font-size: 201px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 182px;
  left: 133px;
  -webkit-background-clip: text !important;
}

.legal-d .section-5 {
  width: 1280px;
  height: 2640px;
  margin-top: -43px;
  position: relative;
}

.legal-d .frame-18 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 166px;
  left: 113px;
}

.legal-d .text-wrapper-23 {
  color: #000;
  letter-spacing: 0;
  width: 443px;
  height: 62px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.legal-d .text-wrapper-24 {
  color: #000;
  letter-spacing: 0;
  width: 1054px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 100;
  line-height: 11.3px;
  position: relative;
}

.legal-d .section-6 {
  width: 1280px;
  height: 2587px;
  margin-top: -43px;
  position: relative;
}

.legal-d .last-updated {
  color: #000;
  letter-spacing: 0;
  width: 1054px;
  font-family: undefined-Regular, Helvetica;
  font-size: 10px;
  line-height: 11.3px;
  position: relative;
}

.legal-d .section-7 {
  width: 1280px;
  height: 1766px;
  margin-top: 180px;
  position: relative;
}

.legal-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.legal-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.legal-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.legal-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.legal-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.legal-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.legal-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.legal-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.legal-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.legal-d .shadows {
  background: linear-gradient(90deg, #0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.legal-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.legal-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 11000px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.legal-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 10877px;
  position: absolute;
  top: 0;
}

.legal-i .overlap-4 {
  height: 10877px;
  position: relative;
}

.legal-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.legal-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.legal-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.legal-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.legal-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-i .if-container {
  width: 768px;
  position: relative;
  top: 100px;
}

.legal-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.legal-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.legal-i .overlap-5 {
  height: 598px;
  position: relative;
}

.legal-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.legal-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.legal-i .noize {
  width: 768px;
  height: 10877px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-i .main-4 {
  width: 760px;
  height: 427px;
  margin-top: -43px;
  position: relative;
}

.legal-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.legal-i .frame-22 {
  flex-direction: column;
  align-items: flex-start;
  width: 618px;
  display: flex;
  position: relative;
  top: 134px;
  left: 73px;
}

.legal-i .text-wrapper-33 {
  color: #000;
  letter-spacing: 0;
  width: 443px;
  height: 62px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.legal-i .last-updated-2 {
  color: #000;
  letter-spacing: 0;
  width: 618px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 100;
  line-height: 11.3px;
  position: relative;
}

.legal-i .section-9 {
  width: 768px;
  height: 3551px;
  margin-top: -43px;
  position: relative;
}

.legal-i .frame-23 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 96px;
  left: 76px;
}

.legal-i .last-updated-3 {
  color: #000;
  letter-spacing: 0;
  width: 617px;
  font-family: undefined-Regular, Helvetica;
  font-size: 10px;
  line-height: 11.3px;
  position: relative;
}

.legal-i .section-10 {
  width: 765px;
  height: 2262px;
  margin-top: -43px;
  position: relative;
}

.legal-i .frame-24 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 450px;
  left: 73px;
}

.legal-i .PLEASE-READ-THE {
  color: #000;
  letter-spacing: 0;
  width: 617px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 100;
  line-height: 11.3px;
  position: relative;
}

.legal-i .section-8 {
  width: 764px;
  height: 3786px;
  margin-top: -43px;
  position: relative;
}

.legal-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.legal-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.legal-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.legal-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.legal-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.legal-i .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.legal-i .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.legal-i .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.legal-i .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.legal-i .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.legal-i .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.legal-i .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.legal-i .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.legal-i .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.legal-i .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.legal-i .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.legal-i .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.legal-i .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.legal-i .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.legal-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.legal-i .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-i .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.legal-i .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.legal-i .ABOUT-US-4 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -6.85px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 623px;
  font-family: Oswald, Helvetica;
  font-size: 137px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 123px;
  left: 66px;
  -webkit-background-clip: text !important;
}

.legal-i .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.legal-i .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.legal-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.legal-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.legal-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.legal-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.legal-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.legal-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.legal-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.legal-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-i.group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.legal-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -200px;
}

.legal-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.legal-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.legal-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.legal-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.legal-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.legal-i .text-wrapper-19 {
  color: #272936;
}

.legal-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.legal-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.legal-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.legal-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 1460px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.legal-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.legal-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.legal-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.legal-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.legal-m .mf-container {
  width: 360px;
  position: relative;
  top: -25px;
}

.legal-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.legal-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.legal-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.legal-m .overlap-5 {
  height: 435px;
  position: relative;
}

.legal-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.legal-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.legal-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: -50px;
}

.legal-m .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.legal-m .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.legal-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.legal-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.legal-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.legal-m .text-wrapper-19 {
  color: #272936;
}

.legal-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.legal-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.legal-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.legal-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.legal-m .FREE-SIMPLE-wrapper {
  width: 360px;
  height: 220px;
  margin-top: -43px;
  position: relative;
}

.legal-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 245px;
  margin-top: -16px;
  font-family: Oswald, Helvetica;
  font-size: 48px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 110px;
  left: 58px;
  -webkit-background-clip: text !important;
}

.legal-m .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  width: 318px;
  display: flex;
  position: absolute;
  top: 194px;
  left: 19px;
}

.legal-m .frame-12 {
  flex-direction: column;
  align-items: flex-start;
  width: 318px;
  display: flex;
  position: absolute;
  top: 294px;
  left: 19px;
}

.legal-m .section-3 {
  width: 359px;
  height: 399px;
  position: relative;
}

.legal-m .frame-10 {
  flex-direction: column;
  align-items: flex-start;
  width: 318px;
  display: flex;
  position: absolute;
  top: 94px;
  left: 19px;
}

.legal-m .text-wrapper-14 {
  color: #000;
  letter-spacing: 0;
  width: 318px;
  height: 62px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.legal-m .text-wrapper-15 {
  color: #000;
  letter-spacing: 0;
  width: 318px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 100;
  line-height: 11.3px;
  position: relative;
}

.legal-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.legal-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.legal-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.legal-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.error-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 1200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.error-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.error-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.error-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.error-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.error-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.error-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.error-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.error-d .overlap-5 {
  height: 598px;
  position: relative;
}

.error-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.error-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.error-d .main-3 {
  width: 1280px;
  height: 1200px;
  margin-top: -49px;
  position: relative;
}

.error-d .overlap-13 {
  width: 1147px;
  height: 624px;
  position: relative;
  top: 74px;
  left: 133px;
}

.error-d .frame-23 {
  width: 513px;
  height: 414px;
  position: absolute;
  top: 16px;
  left: 0;
}

.error-d .go-back-2 {
  cursor: pointer;
  background-color: #0000;
  border: .5px solid #7f64fb;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 415px !important;
  left: 9px !important;
}

.error-d .go-back-2:hover {
  background-color: #7f64fb;
}

.error-d .go-back-2-text {
  color: #fff;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
}

.error-d .overlap-group-11 {
  width: 513px;
  height: 312px;
  position: absolute;
  top: 98px;
  left: 0;
}

.error-d .text-wrapper-32 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 504px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: absolute;
  top: 246px;
  left: 9px;
}

.error-d .text-wrapper-33 {
  color: #fff;
  letter-spacing: 0;
  width: 501px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 9px;
}

.error-d .ABOUT-US-3 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -10.05px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 513px;
  font-family: Oswald, Helvetica;
  font-size: 201px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-clip: text !important;
}

.error-d .gummy-rayman-2 {
  object-fit: cover;
  width: 688px;
  height: 624px;
  position: absolute;
  top: 0;
  left: 459px;
}

.error-d .gummy-rayman-3 {
  object-fit: cover;
  width: 321px;
  height: 355px;
  position: absolute;
  top: 269px;
  left: 466px;
}

.error-d .NAV-5 {
  background-color: #f3f3f3;
  width: 1280px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .frame-24 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.error-d .xion-logo-5 {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.error-d .frame-25 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.error-d .df-container {
  width: 1280px;
  position: relative;
  top: -216px;
}

.error-d .GS-button-5 {
  all: unset;
  box-sizing: border-box;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-d .frame-26 {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.error-d .overlap-group-12 {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-d .get-started-9 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.error-d .div-8 {
  width: 45px;
  height: 39px;
  position: relative;
}

.error-d .shadows-5 {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.error-d .shadows-6 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .rectangle-14 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.error-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.error-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.error-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.error-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.error-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.error-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.error-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.error-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.error-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 1200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 2013px;
  position: absolute;
  top: 0;
}

.error-i .overlap-4 {
  height: 1078px;
  position: relative;
}

.error-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.error-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.error-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.error-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.error-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .if-container {
  width: 768px;
  position: relative;
  top: 350px;
}

.error-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.error-i .hero {
  width: 709px;
  height: 957px;
  position: absolute;
  top: 121px;
  left: 205px;
}

.error-i .section-8 {
  width: 764px;
  height: 3786px;
  margin-top: -43px;
  position: relative;
}

.error-i .overlap-5 {
  height: 598px;
  position: relative;
}

.error-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: -175px;
}

.error-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.error-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.error-i .overlap-11 {
  height: 1078px;
  position: relative;
}

.error-i .overlap-group-11 {
  height: 868px;
  position: relative;
}

.error-i .rectangle-14 {
  object-fit: cover;
  width: 709px;
  height: 866px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .gragient-2 {
  width: 709px;
  height: 868px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .noize-2 {
  width: 768px;
  height: 1014px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .nav-spacer-4 {
  width: 768px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.error-i .main-3 {
  width: 760px;
  height: 427px;
  margin-top: -43px;
  position: relative;
}

.error-i .overlap-12 {
  width: 731px;
  height: 752px;
  position: relative;
  top: 122px;
  left: 66px;
}

.error-i .gummy-rayman-wrapper {
  background-image: url("gummy-rayman-adventures-sebastien-camrrubi-removebg-preview-1-2.1856da72.webp");
  background-position: 50%;
  background-size: cover;
  width: 678px;
  height: 676px;
  position: absolute;
  top: 80px;
  left: 5px;
}

.error-i .gummy-rayman {
  object-fit: cover;
  width: 349px;
  height: 372px;
  position: absolute;
  top: 304px;
  left: 8px;
}

.error-i .ABOUT-US-3 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -6.85px;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 355px;
  font-family: Oswald, Helvetica;
  font-size: 137px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-clip: text !important;
}

.error-i .go-back-instance {
  cursor: pointer;
  background-color: #0000;
  border: .5px solid #7f64fb;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 213px !important;
  left: 5px !important;
}

.error-i .go-back-instance:hover {
  background-color: #7f64fb;
}

.error-i .go-back-2-text {
  color: #fff;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
}

.error-i .text-wrapper-31 {
  color: #fff;
  letter-spacing: 0;
  width: 504px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 168px;
  left: 5px;
}

.error-i .text-wrapper-32 {
  color: #fff;
  letter-spacing: 0;
  width: 501px;
  font-family: Titillium Web, Helvetica;
  font-size: 34px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 5px;
}

.error-i .NAV-4 {
  background-color: #f3f3f3;
  width: 768px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .frame-20 {
  align-items: center;
  gap: 262px;
  width: 709px;
  display: flex;
  position: relative;
  top: 47px;
  left: 29px;
}

.error-i .xion-logo-4 {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.error-i .frame-21 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -1px;
  display: inline-flex;
  position: relative;
}

.error-i .GS-button-3 {
  all: unset;
  box-sizing: border-box;
  width: 228.02px;
  height: 39px;
  position: relative;
}

.error-i .overlap-group-12 {
  background-size: 100% 100%;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-i .rectangle-15 {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .get-started-7 {
  color: #439be4;
  letter-spacing: 3.24px;
  text-align: center;
  width: 226px;
  height: 27px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 0;
}

.error-i .shadows-5 {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.error-i .shadows-6 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .rectangle-16 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .group-5 {
  width: 21px;
  height: 15px;
  position: absolute;
  top: 13px;
  left: 14px;
}

.error-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.error-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.error-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.error-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.error-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.error-i .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.error-i .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.error-i .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.error-i .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.error-i .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.error-i .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.error-i .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.error-i .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.error-i .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.error-i .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.error-i .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.error-i .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.error-i .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.error-i .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.error-i .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.error-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.error-i .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.error-i .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.error-i .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.error-i .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.error-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.error-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.error-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.error-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.error-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.error-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-i .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.error-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 1200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-m .BG-elements {
  align-self: center;
  width: 360px;
  height: 761px;
  position: absolute;
  top: 65px;
}

.error-m .overlap-4 {
  width: 360px;
  height: 760px;
  position: relative;
  top: 1px;
}

.error-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.error-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.error-m .mf-container {
  width: 360px;
  position: relative;
  top: -25px;
}

.error-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.error-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.error-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .hero {
  width: 327px;
  height: 424px;
  position: absolute;
  top: 64px;
  left: 15px;
}

.error-m .overlap-5 {
  height: 435px;
  position: relative;
}

.error-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  position: absolute;
  top: 4px;
  left: 0;
}

.error-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .nav-spacer {
  width: 360px;
  height: 175px;
  position: relative;
}

.error-m .main {
  width: 360px;
  height: 220px;
  position: relative;
  bottom: 40px;
}

.error-m .FREE-SIMPLE {
  width: 144px;
  height: 48px;
  position: absolute;
  top: 79px;
  left: 109px;
}

.error-m .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 324px;
  font-family: Titillium Web, Helvetica;
  font-size: 23px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 34px;
  left: 19px;
}

.error-m .div {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 136px;
  left: 36px;
}

.error-m .go-back-instance {
  cursor: pointer;
  background-color: #0000;
  border: .5px solid #7f64fb;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 189px !important;
  left: 108px !important;
}

.error-m .go-back-instance:hover {
  background-color: #7f64fb;
}

.error-m .go-back-2-text {
  color: #fff;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
}

.error-m .design-component-instance-node {
  letter-spacing: -.84px !important;
  width: 144px !important;
  font-size: 12px !important;
  top: 7px !important;
}

.error-m .group {
  width: 374px;
  height: 374px;
  position: absolute;
  top: 256px;
  left: 17px;
}

.error-m .gummy-rayman-wrapper {
  background-image: url("gummy-rayman-adventures-sebastien-camrrubi-removebg-preview-1.ed5804ed.webp");
  background-position: 50%;
  background-size: cover;
  width: 360px;
  height: 383px;
  position: relative;
  top: -11px;
  left: -17px;
}

.error-m .gummy-rayman {
  object-fit: cover;
  width: 213px;
  height: 210px;
  position: absolute;
  top: 173px;
  left: 0;
}

.error-m .linked-in {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 148px;
  left: 39px;
}

.error-m .overlap-group-8 {
  width: 29px;
  height: 29px;
  position: relative;
  top: -1px;
  left: -1px;
}

.error-m .shadows-m {
  mix-blend-mode: color-burn;
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 26.16px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-180deg);
  box-shadow: inset 3.27px 3.27px 2.18px #aeaec033, inset -2.18px -2.18px 2.18px #ffffffb2;
}

.error-m .ellipse-12 {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .path {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.error-m .div-wrapper {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 148px;
  left: 79px;
}

.error-m .linked-in-2 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 148px;
  left: 119px;
}

.error-m .section {
  align-self: center;
  width: 360px;
  height: 792px;
  position: relative;
}

.error-m .xion-global-was {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 320px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.9px;
  position: absolute;
  top: 103px;
  left: 19px;
}

.error-m .text-wrapper-66 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.9px;
}

.error-m .text-wrapper-67 {
  font-weight: 100;
}

.error-m .section-2 {
  width: 359px;
  height: 742px;
  margin-top: -43px;
  position: relative;
}

.error-m .our-e-commerce {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 323px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 15.9px;
  position: absolute;
  top: 70px;
  left: 12px;
}

.error-m .FREE-SIMPLE-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 352px;
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-clip: text !important;
}

.error-m .section-3 {
  width: 360px;
  height: 391px;
  margin-top: -43px;
  position: relative;
}

.error-m .overlap-9 {
  width: 320px;
  height: 338px;
  position: relative;
}

.error-m .FREE-SIMPLE-3 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 249px;
  font-family: Oswald, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 41px;
  -webkit-background-clip: text !important;
}

.error-m .frame-4 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1px 7px;
  width: 330px;
  display: flex;
  position: absolute;
  top: 68px;
  left: 0;
}

.error-m .group-11 {
  background-color: #d9d9d903;
  width: 107.54px;
  height: 39.23px;
  position: relative;
}

.error-m .vector-2 {
  width: 49px;
  height: 29px;
  position: absolute;
  top: 5px;
  left: 30px;
}

.error-m .vector-3 {
  width: 78px;
  height: 21px;
  position: absolute;
  top: 9px;
  left: 16px;
}

.error-m .vector-4 {
  width: 69px;
  height: 25px;
  position: absolute;
  top: 7px;
  left: 20px;
}

.error-m .vector-5 {
  width: 70px;
  height: 17px;
  position: absolute;
  top: 11px;
  left: 19px;
}

.error-m .vector-6 {
  width: 51px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 28px;
}

.error-m .vector-7 {
  width: 80px;
  height: 17px;
  position: absolute;
  top: 11px;
  left: 14px;
}

.error-m .vector-8 {
  width: 74px;
  height: 15px;
  position: absolute;
  top: 12px;
  left: 17px;
}

.error-m .vector-9 {
  width: 80px;
  height: 16px;
  position: absolute;
  top: 13px;
  left: 14px;
}

.error-m .vector-10 {
  width: 80px;
  height: 11px;
  position: absolute;
  top: 16px;
  left: 12px;
}

.error-m .vector-11 {
  width: 76px;
  height: 18px;
  position: absolute;
  top: 13px;
  left: 16px;
}

.error-m .vector-12 {
  width: 46px;
  height: 32px;
  position: absolute;
  top: 4px;
  left: 31px;
}

.error-m .vector-13 {
  width: 72px;
  height: 15px;
  position: absolute;
  top: 14px;
  left: 18px;
}

.error-m .vector-14 {
  width: 77px;
  height: 14px;
  position: absolute;
  top: 14px;
  left: 16px;
}

.error-m .vector-15 {
  width: 77px;
  height: 16px;
  position: absolute;
  top: 11px;
  left: 16px;
}

.error-m .vector-16 {
  width: 29px;
  height: 31px;
  position: absolute;
  top: 5px;
  left: 38px;
}

.error-m .vector-17 {
  width: 85px;
  height: 16px;
  position: absolute;
  top: 12px;
  left: 13px;
}

.error-m .vector-18 {
  width: 47px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 30px;
}

.error-m .vector-19 {
  width: 68px;
  height: 14px;
  position: absolute;
  top: 13px;
  left: 20px;
}

.error-m .section-4 {
  width: 360px;
  height: 274px;
  position: relative;
}

.error-m .FREE-SIMPLE-4 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 145px;
  font-family: Oswald, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 105px;
  -webkit-background-clip: text !important;
}

.error-m .frame-5 {
  width: 305px;
  height: 52px;
  position: absolute;
  top: 143px;
  left: 21px;
}

.error-m .aaa-ba-e {
  object-fit: cover;
  width: 145px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .img-6 {
  object-fit: cover;
  width: 104px;
  height: 44px;
  position: absolute;
  top: 3px;
  left: 185px;
}

.error-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.error-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
}

.error-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.error-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.error-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.error-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.error-m .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.error-m .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.error-m .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.error-m .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.error-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.error-m .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.error-m .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.error-m .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.error-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.error-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.error-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.error-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.error-m .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.error-m .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.error-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.error-m .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.error-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.error-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.error-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.error-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.error-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.error-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.error-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.error-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.error-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.error-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.error-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.contact-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 2500px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contact-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.contact-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.contact-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.contact-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.contact-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.contact-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 15px;
  left: 50px;
}

.contact-d .overlap-5 {
  height: 598px;
  position: relative;
}

.contact-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.contact-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.contact-d .main {
  width: 1280px;
  height: 611px;
  margin-top: -43px;
  position: relative;
}

.contact-d .ABOUT-US {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -10.05px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 817px;
  font-family: Oswald, Helvetica;
  font-size: 201px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 182px;
  left: 133px;
  -webkit-background-clip: text !important;
}

.contact-d .section {
  align-self: center;
  width: 1280px;
  height: 670px;
  position: relative;
}

.contact-d .overlap-2 {
  width: 564px;
  height: 489px;
  position: absolute;
  top: 140px;
  left: 627px;
}

.contact-d .rectangle-2 {
  filter: blur(43px);
  opacity: .5;
  background-color: #170d5333;
  border-radius: 23px;
  width: 543px;
  height: 471px;
  position: absolute;
  top: 18px;
  left: 21px;
}

.contact-d .rectangle-3 {
  background-color: #ededed;
  border-radius: 24px;
  width: 543px;
  height: 468px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-d .contact-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 479px;
  height: 396px;
  display: flex;
  position: absolute;
  top: 28px;
  left: 31px;
}

.contact-d .frame {
  flex: none;
  align-items: flex-start;
  gap: 25px;
  margin-right: -2px;
  display: inline-flex;
  position: relative;
}

.contact-d .div-2 {
  color: #000;
  letter-spacing: -.4px;
  width: 125px;
  height: 33px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.contact-d .rectangle-4 {
  background-color: #e5e4e4;
  border-radius: 6px;
  width: 331px;
  height: 33px;
  position: relative;
}

.contact-d .frame-2 {
  align-items: flex-start;
  gap: 25px;
  height: 84px;
  margin-right: -2px;
  display: inline-flex;
  position: relative;
}

.contact-d .text-wrapper {
  color: #000;
  letter-spacing: -.4px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.contact-d .span {
  letter-spacing: -.25px;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
}

.contact-d .rectangle-5 {
  background-color: #e5e3e3;
  border-radius: 6px;
  width: 331px;
  height: 84px;
  position: relative;
}

.contact-d .read-button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  width: 161px;
  height: 28px;
  position: absolute;
  top: 369px;
  left: 322px;
}

.contact-d .read-the-docs-wrapper {
  background-color: #7f64fb;
  border-radius: 6px;
  width: 159px;
  height: 28px;
  position: relative;
}

.contact-d .read-the-docs {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 151px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 4px;
}

.contact-d .TEXT {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 443px;
  padding: 34px 0;
  display: flex;
  position: relative;
}

.contact-d .text-wrapper-2 {
  color: #000;
  letter-spacing: 0;
  width: 443px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.contact-d .our-team-can-help {
  color: #272936;
  letter-spacing: -.35px;
  width: 402px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-d .our-team-can-help ul {
  padding-left: 20px;
  list-style-type: disc;
}

.contact-d .our-team-can-help li {
  margin-bottom: 10px;
}

.contact-d .our-team-can-help .custom-margin {
  margin-top: 25px;
}

.contact-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
  top: -43px;
}

.contact-d .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.contact-d .TEXTcomplaints {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 443px;
  padding: 34px 0;
  display: flex;
  position: relative;
}

.contact-d .complaints-section {
  position: absolute;
  top: 400px;
  left: 163px;
}

.contact-d .complaints-text {
  color: #272936;
  letter-spacing: -.35px;
  width: 372px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.8px;
  position: relative;
}

.contact-d .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.contact-d .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.contact-d .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.contact-d .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-d .text-wrapper-19 {
  color: #272936;
}

.contact-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.contact-d .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.contact-d .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.contact-d .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.contact-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 2050px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contact-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.contact-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.contact-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.contact-i .if-container {
  width: 768px;
  position: relative;
  top: -330px;
}

.contact-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.contact-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.contact-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.contact-i .overlap-5 {
  height: 598px;
  position: relative;
}

.contact-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.contact-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.contact-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.contact-i .main {
  width: 760px;
  height: 427px;
  margin-top: -43px;
  position: relative;
}

.contact-i .section {
  align-self: center;
  width: 760px;
  height: 627px;
  margin-top: 13px;
  position: relative;
}

.contact-i .overlap-2 {
  width: 670px;
  height: 472px;
  position: relative;
  top: 136px;
  left: 350px;
}

.contact-i .read-button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  width: 161px;
  height: 28px;
  position: absolute;
  top: 359px;
  left: 144px;
}

.contact-i .read-the-docs-wrapper {
  background-color: #7f64fb;
  border-radius: 6px;
  width: 159px;
  height: 28px;
  position: relative;
}

.contact-i .read-the-docs {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 151px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 4px;
}

.contact-i .main {
  width: 760px;
  height: 427px;
  position: relative;
}

.contact-i .ABOUT-US {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -6.85px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 623px;
  font-family: Oswald, Helvetica;
  font-size: 137px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 122px;
  left: 66px;
  -webkit-background-clip: text !important;
}

.contact-i .group {
  width: 390px;
  height: 471px;
  position: absolute;
  top: 1px;
  left: 280px;
}

.contact-i .our-team-can-help ul {
  padding-left: 20px;
  list-style-type: disc;
}

.contact-i .our-team-can-help li {
  margin-bottom: 10px;
}

.contact-i .our-team-can-help .custom-margin {
  margin-top: 25px;
}

.contact-i .overlap-3 {
  height: 471px;
  position: relative;
}

.contact-i .rectangle-2 {
  filter: blur(43px);
  opacity: .5;
  background-color: #170d5333;
  border-radius: 23px;
  width: 375px;
  height: 454px;
  position: absolute;
  top: 17px;
  left: 15px;
}

.contact-i .rectangle-3 {
  background-color: #ededed;
  border-radius: 24px;
  width: 375px;
  height: 451px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-i .contact-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 303px;
  height: 396px;
  display: flex;
  position: absolute;
  top: 28px;
  left: 35px;
}

.contact-i .frame {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.contact-i .div-2 {
  color: #000;
  letter-spacing: -.4px;
  width: 103px;
  height: 33px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.contact-i .rectangle-4 {
  background-color: #e5e4e4;
  border-radius: 6px;
  width: 200px;
  height: 33px;
  position: relative;
}

.contact-i .frame-2 {
  align-items: flex-start;
  height: 84px;
  display: inline-flex;
  position: relative;
}

.contact-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.contact-i .text-wrapper-2 {
  color: #000;
  letter-spacing: -.25px;
  font-family: Titillium Web, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 12px;
}

.contact-i .rectangle-5 {
  background-color: #e5e3e3;
  border-radius: 6px;
  width: 200px;
  height: 84px;
  position: relative;
}

.contact-i .TEXT {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 259px;
  padding: 34px 0;
  display: flex;
  position: relative;
}

.contact-i .text-wrapper-3 {
  color: #000;
  letter-spacing: 0;
  width: 259px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.contact-i .our-team-can-help {
  color: #272936;
  letter-spacing: -.35px;
  width: 259px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -50px;
}

.contact-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.contact-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.contact-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.contact-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-i .text-wrapper-19 {
  color: #272936;
}

.contact-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.contact-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.contact-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.contact-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.contact-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 2110px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contact-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.contact-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.contact-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.contact-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.contact-m .mf-container {
  width: 360px;
  position: relative;
  top: 120px;
}

.contact-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.contact-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.contact-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.contact-m .overlap-5 {
  height: 435px;
  position: relative;
}

.contact-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .ABOUT-US {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -6.85px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 289px;
  font-family: Oswald, Helvetica;
  font-size: 75px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 45px;
  -webkit-background-clip: text !important;
}

.contact-m .div {
  height: 232px;
  position: relative;
}

.contact-m .rectangle {
  object-fit: cover;
  width: 327px;
  height: 230px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .img {
  width: 327px;
  height: 232px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .noize {
  width: 358px;
  height: 1911px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.contact-m .main {
  width: 360px;
  height: 220px;
  margin-top: -43px;
  position: relative;
}

.contact-m .FREE-SIMPLE {
  width: 250px;
  height: 48px;
  position: absolute;
  top: 79px;
  left: 57px;
}

.contact-m .section {
  align-self: center;
  width: 360px;
  height: 884px;
  position: relative;
}

.contact-m .group {
  width: 350px;
  height: 485px;
  position: absolute;
  top: 425px;
  left: 12px;
}

.contact-m .overlap-2 {
  height: 485px;
  position: relative;
}

.contact-m .rectangle-2 {
  filter: blur(43px);
  opacity: .5;
  background-color: #170d5333;
  border-radius: 23px;
  width: 337px;
  height: 468px;
  position: absolute;
  top: 17px;
  left: 13px;
}

.contact-m .rectangle-3 {
  background-color: #ededed;
  border-radius: 24px;
  width: 337px;
  height: 464px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .contact-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 303px;
  height: 396px;
  display: flex;
  position: absolute;
  top: 18px;
  left: 18px;
}

.contact-m .frame {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.contact-m .div-2 {
  color: #000;
  letter-spacing: -.4px;
  width: 103px;
  height: 33px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.contact-m .rectangle-4 {
  background-color: #e5e4e4;
  border-radius: 6px;
  width: 200px;
  height: 33px;
  position: relative;
}

.contact-m .frame-2 {
  justify-content: center;
  align-items: flex-start;
  height: 84px;
  display: inline-flex;
  position: relative;
}

.contact-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.contact-m .text-wrapper {
  color: #000;
  letter-spacing: -.4px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.contact-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.contact-m .rectangle-5 {
  background-color: #e5e3e3;
  border-radius: 6px;
  width: 200px;
  height: 84px;
  position: relative;
}

.contact-m .read-button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  width: 161px;
  height: 28px;
  position: absolute;
  top: 359px;
  left: 144px;
}

.contact-m .read-the-docs-wrapper {
  background-color: #7f64fb;
  border-radius: 6px;
  width: 159px;
  height: 28px;
  position: relative;
}

.contact-m .read-the-docs {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 151px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 4px;
}

.contact-m .TEXT-wrapper {
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 66px;
  left: 23px;
}

.contact-m .TEXT {
  flex-direction: column;
  align-items: center;
  gap: 17px;
  width: 259px;
  padding: 34px 0;
  display: flex;
  position: relative;
}

.contact-m .text-wrapper-2 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 259px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.contact-m .our-team-can-help {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 259px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-m .our-team-can-help li {
  margin-bottom: 10px;
}

.contact-m .our-team-can-help .custom-margin {
  margin-top: 25px;
}

.contact-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: 100px;
}

.contact-m .div-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.contact-m .TEXT-2 {
  flex-direction: column;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.contact-m .xion-docs {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 314px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.contact-m .text-wrapper-3 {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.contact-m .explore-guides-and {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  width: 314px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-m .text-wrapper-4 {
  color: #272936;
}

.contact-m .text-wrapper-5 {
  color: #14a5ed;
}

.contact-m .frame-3 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 38px;
}

.contact-m .technical-and {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 284px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.contact-m .we-re-here-to-help {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  width: 284px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-m .line {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.contact-m .start-section {
  width: 356px;
  height: 252px;
  margin-top: -43px;
  position: relative;
}

.contact-m .overlap-3 {
  width: 328px;
  height: 238px;
  position: relative;
  top: 25px;
  left: 30px;
}

.contact-m .phone-image {
  object-fit: cover;
  width: 243px;
  height: 238px;
  position: absolute;
  top: 0;
  left: 85px;
}

.contact-m .text-wrapper-6 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 87px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 122px;
  left: 0;
}

.contact-m .text-wrapper-7 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 140px;
  height: 35px;
  font-family: Oswald, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 153px;
  left: 0;
  -webkit-background-clip: text !important;
}

.contact-m .footer-section {
  width: 298px;
  height: 196px;
  margin-top: -43px;
  position: relative;
}

.contact-m .frame-4 {
  align-items: flex-start;
  gap: 56px;
  width: 293px;
  display: flex;
  position: relative;
  top: 95px;
  left: 3px;
}

.contact-m .XG-logo {
  width: 107px;
  height: 21.89px;
  position: relative;
}

.contact-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.contact-m .follow-us-2 {
  width: 136px;
  height: 92.8px;
  margin-right: -6px;
  position: relative;
}

.contact-m .overlap-group-2 {
  width: 61px;
  height: 17px;
  position: absolute;
  top: 75px;
  left: 69px;
}

.contact-m .text-wrapper-8 {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 13px;
}

.contact-m .text-wrapper-9 {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: right;
  width: 130px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 30px;
  left: 0;
}

.contact-m .social {
  align-items: flex-start;
  gap: 16.5px;
  width: 130px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.contact-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.contact-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.contact-m .medium-footer, .contact-m .icon-instance-node {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.contact-m .NAV {
  width: 360px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .frame-wrapper {
  background-color: #f3f3f3;
  width: 359px;
  height: 120px;
}

.contact-m .frame-5 {
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
  top: 53px;
  left: 19px;
}

.contact-m .xion-logo {
  width: 25.4px;
  height: 30.71px;
  position: relative;
}

.contact-m .frame-6 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.contact-m .GS-button {
  all: unset;
  box-sizing: border-box;
  width: 176px;
  height: 30px;
  position: relative;
}

.contact-m .overlap-group-3 {
  background-size: 100% 100%;
  width: 174px;
  height: 30px;
  position: relative;
}

.contact-m .rectangle-6 {
  border: 1px solid #439be4;
  border-radius: 6px;
  width: 174px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .get-started {
  color: #439be4;
  letter-spacing: 2.34px;
  text-align: center;
  width: 174px;
  height: 30px;
  font-family: Oswald, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .burger {
  width: 52px;
  height: 48.53px;
  margin-top: -8.72px;
  margin-bottom: -9.81px;
  margin-right: -9px;
  position: relative;
}

.contact-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.contact-m .text-wrapper-19 {
  color: #272936;
}

.contact-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.contact-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.contact-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.contact-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.contact-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.contact-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.CheckoutB-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3850px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CheckoutB-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.CheckoutB-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.CheckoutB-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.CheckoutB-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.CheckoutB-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.CheckoutB-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.CheckoutB-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.CheckoutB-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.CheckoutB-d .overlap-5 {
  height: 598px;
  position: relative;
}

.CheckoutB-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.CheckoutB-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.CheckoutB-d .main {
  width: 1280px;
  height: 574px;
  margin-top: -43px;
  position: relative;
}

.CheckoutB-d .overlap-6 {
  width: 1143px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.CheckoutB-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 129px;
  left: 0;
}

.CheckoutB-d .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 200px;
  display: flex;
  position: relative;
}

.CheckoutB-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.CheckoutB-d .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 606.85px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 57px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.CheckoutB-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -20px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.CheckoutB-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  margin-top: 25px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.CheckoutB-d .element-of {
  object-fit: cover;
  width: 600px;
  height: 654px;
  position: absolute;
  top: 0;
  left: 543px;
}

.CheckoutB-d .section {
  align-self: center;
  width: 1280px;
  height: 1785px;
  position: relative;
}

.CheckoutB-d .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 1525px;
  display: inline-flex;
  position: absolute;
  top: 207px;
  left: 163px;
}

.CheckoutB-d .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.CheckoutB-d .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-d .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1005px;
  left: 750px;
}

.CheckoutB-d .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CheckoutB-d .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CheckoutB-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-d .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.CheckoutB-d .frame-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: -40px;
  left: 600px;
}

.CheckoutB-d .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.CheckoutB-d .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-d .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.CheckoutB-d .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.CheckoutB-d .group-9 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 250px;
  left: 600px;
}

.CheckoutB-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
  left: -20px;
}

.CheckoutB-d .overlap-wrapper {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 500px;
  left: 600px;
}

.CheckoutB-d .overlap-7 {
  height: 176px;
  position: relative;
  top: -2px;
  left: 0;
}

.CheckoutB-d .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.CheckoutB-d .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.CheckoutB-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 752px;
}

.CheckoutB-d .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.CheckoutB-d .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.CheckoutB-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1600px !important;
  left: 682px !important;
}

.CheckoutB-d .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1700px !important;
  left: 865px !important;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.CheckoutB-d .component-instance {
  position: absolute;
  top: 1039px;
  left: 846px;
}

.CheckoutB-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
  top: 250px;
}

.CheckoutB-d .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.CheckoutB-d .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.CheckoutB-d .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.CheckoutB-d .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.CheckoutB-d .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-d .text-wrapper-19 {
  color: #272936;
}

.CheckoutB-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.CheckoutB-d .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.CheckoutB-d .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.CheckoutB-d .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.CheckoutB-d .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.CheckoutB-d .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-d .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.CheckoutB-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.CheckoutB-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.CheckoutB-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.CheckoutB-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CheckoutB-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.CheckoutB-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CheckoutB-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.CheckoutB-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.CheckoutB-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.CheckoutB-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.CheckoutB-d .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 500px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.CheckoutB-d .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-d .PL {
  z-index: 1;
  width: 492px;
  height: 731px;
  position: absolute;
  top: 1280px;
  left: 620px;
}

.CheckoutB-d .HCC {
  z-index: 1;
  width: 492px;
  height: 731px;
  position: absolute;
  top: 1280px;
  left: 145px;
}

.CheckoutB-d .TEXTa {
  z-index: 2;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1650px;
  left: 190px;
}

.CheckoutB-d .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-d .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CheckoutB-d .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CheckoutB-d .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-d .bullet-list {
  list-style-type: none;
}

.CheckoutB-d .bullet-list li {
  padding-left: 20px;
  font-size: 14px;
  position: relative;
}

.CheckoutB-d .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.CheckoutB-d .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1650px;
  left: 665px;
}

.CheckoutB-d .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 491px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-d .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CheckoutB-d .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CheckoutB-d .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-d .text-containera, .CheckoutB-d .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.CheckoutB-d .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 830px;
}

.CheckoutB-d .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.CheckoutB-d .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1850px !important;
  left: 190px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.CheckoutB-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-d .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1850px !important;
  left: 665px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.CheckoutB-d .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-d .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-d .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
}

.CheckoutB-d .text-wrapper-44-2 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
  left: 160px;
}

.CheckoutB-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3390px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CheckoutB-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.CheckoutB-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.CheckoutB-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.CheckoutB-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.CheckoutB-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.CheckoutB-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.CheckoutB-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-i .if-container {
  width: 768px;
  position: relative;
  top: -350px;
}

.CheckoutB-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.CheckoutB-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.CheckoutB-i .overlap-5 {
  height: 598px;
  position: relative;
}

.CheckoutB-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.CheckoutB-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.CheckoutB-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.CheckoutB-i .main {
  width: 768px;
  height: 574px;
  position: relative;
}

.CheckoutB-i .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.CheckoutB-i .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 55px;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: -65px;
}

.CheckoutB-i .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 100px;
  display: flex;
  position: relative;
}

.CheckoutB-i .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 10px;
}

.CheckoutB-i .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 383.28px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.CheckoutB-i .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 386px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.CheckoutB-i .p {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 74px;
  margin-bottom: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-i .element-of {
  object-fit: cover;
  width: 408px;
  height: 455px;
  position: absolute;
  top: 0;
  left: 255px;
}

.CheckoutB-i .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.CheckoutB-i .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
}

.CheckoutB-i .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CheckoutB-i .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.CheckoutB-i .component-4 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 980px;
  left: 430px;
  overflow: hidden;
}

.CheckoutB-i .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CheckoutB-i .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CheckoutB-i .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-i .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.CheckoutB-i .frame-4 {
  z-index: 1;
  width: 306px;
  height: 126px;
  position: absolute;
  top: 80px;
  left: 444px;
}

.CheckoutB-i .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.CheckoutB-i .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-i .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.CheckoutB-i .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1720px !important;
  left: 80px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.CheckoutB-i .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-i .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.CheckoutB-i .group-9 {
  width: 306px;
  height: 126px;
  position: absolute;
  top: 325px;
  left: 445px;
  overflow: hidden;
}

.CheckoutB-i .group-2 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 20px;
}

.CheckoutB-i .overlap-wrapper {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 613px;
  left: 400px;
  overflow: hidden;
}

.CheckoutB-i .overlap-7 {
  width: 353px;
  height: 126px;
  position: relative;
  top: 20px;
  left: 1px;
}

.CheckoutB-i .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.CheckoutB-i .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.CheckoutB-i .frame-lock {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 420px;
}

.CheckoutB-i .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.CheckoutB-i .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.CheckoutB-i .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.CheckoutB-i .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.CheckoutB-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -40px;
}

.CheckoutB-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.CheckoutB-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CheckoutB-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.CheckoutB-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.CheckoutB-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-i .text-wrapper-19 {
  color: #272936;
}

.CheckoutB-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.CheckoutB-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.CheckoutB-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.CheckoutB-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.CheckoutB-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.CheckoutB-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.CheckoutB-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.CheckoutB-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.CheckoutB-i .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.CheckoutB-i .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-i .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-i .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.CheckoutB-i .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.CheckoutB-i .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-i .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.CheckoutB-i .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.CheckoutB-i .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.CheckoutB-i .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.CheckoutB-i .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.CheckoutB-i .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.CheckoutB-i .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.CheckoutB-i .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.CheckoutB-i .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.CheckoutB-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.CheckoutB-i .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-i .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.CheckoutB-i .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.CheckoutB-i .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.CheckoutB-i .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.CheckoutB-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CheckoutB-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.CheckoutB-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CheckoutB-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.CheckoutB-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.CheckoutB-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.CheckoutB-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-i .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.CheckoutB-i .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -70px;
  position: absolute !important;
  top: 350px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.CheckoutB-i .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-i .PL {
  z-index: 1;
  width: 400px;
  height: 639px;
  position: absolute;
  top: 1200px;
  left: 400px;
}

.CheckoutB-i .HCC {
  z-index: 1;
  width: 400px;
  height: 639px;
  position: absolute;
  top: 1200px;
  left: 40px;
}

.CheckoutB-i .TEXTa {
  z-index: 2;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 1510px;
  left: 80px;
}

.CheckoutB-i .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-i .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CheckoutB-i .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CheckoutB-i .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
}

.CheckoutB-i .bullet-list {
  list-style-type: none;
}

.CheckoutB-i .bullet-list li {
  padding-left: 20px;
  font-size: 13px;
  position: relative;
}

.CheckoutB-i .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.CheckoutB-i .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1510px;
  left: 430px;
}

.CheckoutB-i .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 491px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-i .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CheckoutB-i .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CheckoutB-i .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
}

.CheckoutB-i .text-containera, .CheckoutB-i .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.CheckoutB-i .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 830px;
}

.CheckoutB-i .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.CheckoutB-i .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1720px !important;
  left: 430px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.CheckoutB-i .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-i .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-i .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
}

.CheckoutB-i .text-wrapper-44-2 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
  left: 160px;
}

.CheckoutB-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3450px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CheckoutB-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.CheckoutB-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.CheckoutB-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.CheckoutB-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.CheckoutB-m .mf-container {
  width: 360px;
  position: relative;
  top: 85px;
}

.CheckoutB-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.CheckoutB-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.CheckoutB-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.CheckoutB-m .overlap-5 {
  height: 435px;
  position: relative;
}

.CheckoutB-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.CheckoutB-m .main {
  width: 360px;
  height: 384px;
  position: relative;
}

.CheckoutB-m .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.CheckoutB-m .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: 58px;
  left: 42px;
}

.CheckoutB-m .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 289px;
  height: 106px;
  display: flex;
  position: relative;
}

.CheckoutB-m .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.CheckoutB-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 283.53px;
  margin-top: -16px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  left: 25px;
  -webkit-background-clip: text !important;
}

.CheckoutB-m .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 289px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
  left: -12px;
}

.CheckoutB-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 15px;
}

.CheckoutB-m .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -35px;
  position: relative !important;
  top: -450px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.CheckoutB-m .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-m .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 255px;
}

.CheckoutB-m .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.CheckoutB-m .TEXT-BLOCK {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: relative;
  top: 137px;
  left: 23px;
}

.CheckoutB-m .line-2 {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.CheckoutB-m .TEXT {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CheckoutB-m .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.CheckoutB-m .component-4 {
  width: 243px;
  height: 96px;
}

.CheckoutB-m .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.CheckoutB-m .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.CheckoutB-m .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-m .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  position: relative;
}

.CheckoutB-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-m .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.CheckoutB-m .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.CheckoutB-m .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.CheckoutB-m .group-9 {
  width: 239px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 445px;
  overflow: hidden;
}

.CheckoutB-m .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.CheckoutB-m .overlap-wrapper {
  width: 267px;
  height: 164px;
  position: absolute;
  top: 713px;
  left: 438px;
  overflow: hidden;
}

.CheckoutB-m .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: -2px;
  left: 1px;
}

.CheckoutB-m .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.CheckoutB-m .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.CheckoutB-m .frame-lock {
  width: 243px;
  height: 89px;
  position: absolute;
  top: 1287px;
  left: 443px;
}

.CheckoutB-m .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.CheckoutB-m .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.CheckoutB-m .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.CheckoutB-m .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.CheckoutB-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: 65px;
}

.CheckoutB-m .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.CheckoutB-m .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CheckoutB-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.CheckoutB-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.CheckoutB-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-m .text-wrapper-19 {
  color: #272936;
}

.CheckoutB-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.CheckoutB-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.CheckoutB-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.CheckoutB-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.CheckoutB-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.CheckoutB-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.CheckoutB-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.CheckoutB-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.CheckoutB-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.CheckoutB-m .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-m .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-m .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.CheckoutB-m .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.CheckoutB-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-m .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.CheckoutB-m .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.CheckoutB-m .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.CheckoutB-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.CheckoutB-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.CheckoutB-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.CheckoutB-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.CheckoutB-m .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.CheckoutB-m .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.CheckoutB-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.CheckoutB-m .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.CheckoutB-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.CheckoutB-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.CheckoutB-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.CheckoutB-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CheckoutB-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.CheckoutB-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.CheckoutB-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.CheckoutB-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.CheckoutB-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.CheckoutB-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.CheckoutB-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.CheckoutB-m .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.CheckoutB-m .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 150px;
}

.CheckoutB-m .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -70px;
  position: absolute !important;
  top: 350px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.CheckoutB-m .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-m .PL {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 2180px;
}

.CheckoutB-m .HCC {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 1680px;
}

.CheckoutB-m .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-m .TEXTa {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: -290px;
}

.CheckoutB-m .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  left: 35px;
}

.CheckoutB-m .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  top: -8px;
  left: 35px;
}

.CheckoutB-m .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}

.CheckoutB-m .bullet-list {
  list-style-type: none;
}

.CheckoutB-m .bullet-list li {
  padding-left: 20px;
  font-size: 10px;
  position: relative;
}

.CheckoutB-m .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.CheckoutB-m .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 35px;
}

.CheckoutB-m .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 315px;
  display: inline-flex;
  position: relative;
}

.CheckoutB-m .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  top: 40px;
  left: 35px;
}

.CheckoutB-m .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  top: 35px;
  left: 35px;
}

.CheckoutB-m .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.CheckoutB-m .text-containera, .CheckoutB-m .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.CheckoutB-m .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.CheckoutB-m .text-wrapper-444 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.CheckoutB-m .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -35px;
  position: relative !important;
  top: 90px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.CheckoutB-m .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.CheckoutB-m .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}

.PaymentL-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3850px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PaymentL-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.PaymentL-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.PaymentL-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.PaymentL-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.PaymentL-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PaymentL-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.PaymentL-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.PaymentL-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.PaymentL-d .overlap-5 {
  height: 598px;
  position: relative;
}

.PaymentL-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.PaymentL-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.PaymentL-d .main {
  width: 1280px;
  height: 574px;
  margin-top: -43px;
  position: relative;
}

.PaymentL-d .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 500px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.PaymentL-d .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-d .overlap-6 {
  width: 1143px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.PaymentL-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 129px;
  left: 0;
}

.PaymentL-d .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 200px;
  display: flex;
  position: relative;
}

.PaymentL-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.PaymentL-d .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 606.85px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 57px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.PaymentL-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -20px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.PaymentL-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  margin-top: 25px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.PaymentL-d .element-of {
  object-fit: cover;
  width: 600px;
  height: 654px;
  position: absolute;
  top: 0;
  left: 543px;
}

.PaymentL-d .section {
  align-self: center;
  width: 1280px;
  height: 1785px;
  position: relative;
}

.PaymentL-d .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 1525px;
  display: inline-flex;
  position: absolute;
  top: 207px;
  left: 163px;
}

.PaymentL-d .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.PaymentL-d .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.PaymentL-d .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1005px;
  left: 750px;
}

.PaymentL-d .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PaymentL-d .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PaymentL-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-d .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.PaymentL-d .frame-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: -40px;
  left: 600px;
}

.PaymentL-d .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.PaymentL-d .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-d .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.PaymentL-d .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.PaymentL-d .group-9 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 250px;
  left: 600px;
}

.PaymentL-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
  left: -20px;
}

.PaymentL-d .overlap-wrapper {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 500px;
  left: 600px;
}

.PaymentL-d .overlap-7 {
  height: 176px;
  position: relative;
  top: -2px;
  left: 0;
}

.PaymentL-d .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.PaymentL-d .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.PaymentL-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 752px;
}

.PaymentL-d .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.PaymentL-d .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.PaymentL-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1600px !important;
  left: 682px !important;
}

.PaymentL-d .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1700px !important;
  left: 865px !important;
}

.PaymentL-d .component-instance {
  position: absolute;
  top: 1039px;
  left: 846px;
}

.PaymentL-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
  top: 250px;
}

.PaymentL-d .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.PaymentL-d .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.PaymentL-d .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.PaymentL-d .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.PaymentL-d .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-d .text-wrapper-19 {
  color: #272936;
}

.PaymentL-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PaymentL-d .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.PaymentL-d .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.PaymentL-d .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.PaymentL-d .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.PaymentL-d .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-d .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.PaymentL-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.PaymentL-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.PaymentL-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.PaymentL-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.PaymentL-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PaymentL-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PaymentL-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PaymentL-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.PaymentL-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.PaymentL-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.PaymentL-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.PaymentL-d .PL {
  z-index: 1;
  width: 492px;
  height: 731px;
  position: absolute;
  top: 1280px;
  left: 620px;
}

.PaymentL-d .HCC {
  z-index: 1;
  width: 492px;
  height: 731px;
  position: absolute;
  top: 1280px;
  left: 145px;
}

.PaymentL-d .TEXTa {
  z-index: 2;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1650px;
  left: 190px;
}

.PaymentL-d .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.PaymentL-d .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PaymentL-d .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PaymentL-d .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-d .bullet-list {
  list-style-type: none;
}

.bullet-list li {
  padding-left: 20px;
  font-size: 14px;
  position: relative;
}

.bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.PaymentL-d .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1650px;
  left: 665px;
}

.PaymentL-d .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 491px;
  display: inline-flex;
  position: relative;
}

.PaymentL-d .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PaymentL-d .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PaymentL-d .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-d .text-containera, .PaymentL-d .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.PaymentL-d .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 830px;
}

.PaymentL-d .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.PaymentL-d .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1850px !important;
  left: 190px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-d .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1850px !important;
  left: 665px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-d .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-d .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-d .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
}

.PaymentL-d .text-wrapper-44-2 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
  left: 160px;
}

.PaymentL-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3390px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PaymentL-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.PaymentL-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.PaymentL-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.PaymentL-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.PaymentL-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PaymentL-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.PaymentL-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-i .if-container {
  width: 768px;
  position: relative;
  top: -350px;
}

.PaymentL-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.PaymentL-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.PaymentL-i .overlap-5 {
  height: 598px;
  position: relative;
}

.PaymentL-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.PaymentL-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.PaymentL-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.PaymentL-i .main {
  width: 768px;
  height: 574px;
  position: relative;
}

.PaymentL-i .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.PaymentL-i .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 55px;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: -65px;
}

.PaymentL-i .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 100px;
  display: flex;
  position: relative;
}

.PaymentL-i .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 10px;
}

.PaymentL-i .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 383.28px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.PaymentL-i .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 386px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.PaymentL-i .p {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 74px;
  margin-bottom: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-i .element-of {
  object-fit: cover;
  width: 408px;
  height: 455px;
  position: absolute;
  top: 0;
  left: 255px;
}

.PaymentL-i .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.PaymentL-i .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
}

.PaymentL-i .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PaymentL-i .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.PaymentL-i .component-4 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 980px;
  left: 430px;
  overflow: hidden;
}

.PaymentL-i .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PaymentL-i .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 400px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PaymentL-i .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-i .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.PaymentL-i .frame-4 {
  z-index: 1;
  width: 306px;
  height: 126px;
  position: absolute;
  top: 80px;
  left: 444px;
}

.PaymentL-i .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.PaymentL-i .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-i .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.PaymentL-i .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1750px;
  left: 490px;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.PaymentL-i .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PaymentL-i .group-9 {
  width: 306px;
  height: 136px;
  position: absolute;
  top: 325px;
  left: 445px;
  overflow: hidden;
}

.PaymentL-i .group-2 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 20px;
}

.PaymentL-i .overlap-wrapper {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 613px;
  left: 400px;
  overflow: hidden;
}

.PaymentL-i .overlap-7 {
  width: 353px;
  height: 126px;
  position: relative;
  top: 20px;
  left: 1px;
}

.PaymentL-i .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.PaymentL-i .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.PaymentL-i .frame-lock {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 420px;
}

.PaymentL-i .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.PaymentL-i .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.PaymentL-i .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.PaymentL-i .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.PaymentL-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: -40px;
}

.PaymentL-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.PaymentL-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PaymentL-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.PaymentL-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.PaymentL-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-i .text-wrapper-19 {
  color: #272936;
}

.PaymentL-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PaymentL-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.PaymentL-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.PaymentL-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.PaymentL-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.PaymentL-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.PaymentL-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.PaymentL-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.PaymentL-i .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.PaymentL-i .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.PaymentL-i .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.PaymentL-i .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.PaymentL-i .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.PaymentL-i .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.PaymentL-i .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.PaymentL-i .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.PaymentL-i .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.PaymentL-i .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.PaymentL-i .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.PaymentL-i .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.PaymentL-i .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.PaymentL-i .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.PaymentL-i .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.PaymentL-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.PaymentL-i .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-i .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.PaymentL-i .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.PaymentL-i .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.PaymentL-i .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.PaymentL-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.PaymentL-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PaymentL-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PaymentL-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PaymentL-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.PaymentL-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.PaymentL-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.PaymentL-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-i .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.PaymentL-i .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -70px;
  position: absolute !important;
  top: 350px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-i .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-i .PL {
  z-index: 1;
  width: 400px;
  height: 639px;
  position: absolute;
  top: 1200px;
  left: 400px;
}

.PaymentL-i .HCC {
  z-index: 1;
  width: 400px;
  height: 639px;
  position: absolute;
  top: 1200px;
  left: 40px;
}

.PaymentL-i .TEXTa {
  z-index: 2;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 1510px;
  left: 80px;
}

.PaymentL-i .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.PaymentL-i .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PaymentL-i .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PaymentL-i .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
}

.PaymentL-i .bullet-list {
  list-style-type: none;
}

.PaymentL-i .bullet-list li {
  padding-left: 20px;
  font-size: 12px;
  position: relative;
}

.PaymentL-i .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.PaymentL-i .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 1510px;
  left: 430px;
}

.PaymentL-i .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 491px;
  display: inline-flex;
  position: relative;
}

.PaymentL-i .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PaymentL-i .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 25px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PaymentL-i .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
}

.PaymentL-i .text-containera, .PaymentL-i .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.PaymentL-i .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  width: 945px;
  display: flex;
  position: absolute;
  top: 830px;
}

.PaymentL-i .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.PaymentL-i .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1720px !important;
  left: 80px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-i .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-i .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1720px !important;
  left: 430px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-i .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-i .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-i .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
}

.PaymentL-i .text-wrapper-44-2 {
  color: #272936;
  letter-spacing: -.35px;
  width: 650px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 5px;
  left: 160px;
}

.PaymentL-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3450px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PaymentL-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.PaymentL-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.PaymentL-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.PaymentL-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.PaymentL-m .mf-container {
  width: 360px;
  position: relative;
}

.PaymentL-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PaymentL-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.PaymentL-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.PaymentL-m .overlap-5 {
  height: 435px;
  position: relative;
}

.PaymentL-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.PaymentL-m .main {
  width: 360px;
  height: 384px;
  position: relative;
}

.PaymentL-m .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.PaymentL-m .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: 58px;
  left: 42px;
}

.PaymentL-m .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 289px;
  height: 106px;
  display: flex;
  position: relative;
}

.PaymentL-m .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.PaymentL-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 283.53px;
  margin-top: -16px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  left: 19px;
  -webkit-background-clip: text !important;
}

.PaymentL-m .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 289px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.PaymentL-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 15px;
}

.PaymentL-m .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1630px;
  left: 66px;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.PaymentL-m .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 255px;
}

.PaymentL-m .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.PaymentL-m .TEXT-BLOCK {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: relative;
  top: 137px;
  left: 23px;
}

.PaymentL-m .line-2 {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.PaymentL-m .TEXT {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PaymentL-m .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.PaymentL-m .component-4 {
  width: 243px;
  height: 96px;
}

.PaymentL-m .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PaymentL-m .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PaymentL-m .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-m .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  position: relative;
}

.PaymentL-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.PaymentL-m .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.PaymentL-m .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.PaymentL-m .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PaymentL-m .group-9 {
  width: 239px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 445px;
  overflow: hidden;
}

.PaymentL-m .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PaymentL-m .overlap-wrapper {
  width: 267px;
  height: 164px;
  position: absolute;
  top: 713px;
  left: 438px;
  overflow: hidden;
}

.PaymentL-m .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: -2px;
  left: 1px;
}

.PaymentL-m .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.PaymentL-m .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.PaymentL-m .frame-lock {
  width: 243px;
  height: 89px;
  position: absolute;
  top: 1287px;
  left: 443px;
}

.PaymentL-m .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.PaymentL-m .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.PaymentL-m .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.PaymentL-m .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.PaymentL-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
}

.PaymentL-m .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.PaymentL-m .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PaymentL-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.PaymentL-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.PaymentL-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-m .text-wrapper-19 {
  color: #272936;
}

.PaymentL-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PaymentL-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.PaymentL-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PaymentL-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.PaymentL-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.PaymentL-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.PaymentL-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.PaymentL-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.PaymentL-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.PaymentL-m .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.PaymentL-m .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.PaymentL-m .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.PaymentL-m .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.PaymentL-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.PaymentL-m .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.PaymentL-m .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.PaymentL-m .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.PaymentL-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.PaymentL-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.PaymentL-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.PaymentL-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.PaymentL-m .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.PaymentL-m .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.PaymentL-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.PaymentL-m .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.PaymentL-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.PaymentL-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.PaymentL-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.PaymentL-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.PaymentL-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PaymentL-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PaymentL-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PaymentL-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.PaymentL-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.PaymentL-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.PaymentL-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PaymentL-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.PaymentL-m .text-wrapper-17-n {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PaymentL-m .TEXT-a {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 150px;
}

.PaymentL-m .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -70px;
  position: absolute !important;
  top: 350px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-m .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-m .PL {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 2200px;
}

.PaymentL-m .HCC {
  z-index: 1;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 1700px;
}

.PaymentL-m .frame-3a {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.PaymentL-m .TEXTa {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: -270px;
}

.PaymentL-m .text-wrapper-15a {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  left: 35px;
}

.PaymentL-m .text-wrapper-16a {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  top: -8px;
  left: 35px;
}

.PaymentL-m .text-wrapper-17a {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}

.PaymentL-m .bullet-list {
  list-style-type: none;
}

.PaymentL-m .bullet-list li {
  padding-left: 20px;
  font-size: 10px;
  position: relative;
}

.PaymentL-m .bullet-list li:before {
  content: "•";
  color: #439be4;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.PaymentL-m .TEXT-b {
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
  top: 45px;
}

.PaymentL-m .frame-3b {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 2px;
  width: 315px;
  display: inline-flex;
  position: relative;
}

.PaymentL-m .text-wrapper-15b {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
  top: 40px;
  left: 35px;
}

.PaymentL-m .text-wrapper-16b {
  color: #000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
  top: 35px;
  left: 35px;
}

.PaymentL-m .text-wrapper-17b {
  color: #272936;
  letter-spacing: -.35px;
  width: 295px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.PaymentL-m .text-containera, .PaymentL-m .text-container-b {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.PaymentL-m .df-container {
  width: 1280px;
  position: relative;
  top: 0;
}

.PaymentL-m .text-wrapper-444 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
  left: 35px;
}

.PaymentL-m .GS-button {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: 35px;
  position: relative !important;
  top: -500px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-m .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-m .GS-button-2 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: 35px;
  position: relative !important;
  top: 70px !important;
}

.GS-button-2:hover {
  background-color: #7f64fb;
}

.GS-button-2-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PaymentL-m .GS-button-2-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PaymentL-m .text-wrapper-44 {
  color: #272936;
  letter-spacing: -.35px;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: -5px;
  left: 35px;
}

.loading-screen-container {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.loading-screen {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

.loading-screen img {
  max-width: 15%;
  max-height: 15%;
}

.PWC-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 4555px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PWC-d .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.PWC-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.PWC-d .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.PWC-d .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.PWC-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PWC-d .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.PWC-d .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-d .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.PWC-d .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.PWC-d .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 480px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.PWC-d .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-d .overlap-5 {
  height: 598px;
  position: relative;
}

.PWC-d .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-d .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.PWC-d .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-d .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.PWC-d .main {
  width: 1280px;
  height: 574px;
  margin-top: -43px;
  position: relative;
}

.PWC-d .overlap-6 {
  width: 1143px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.PWC-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 130px;
  left: 0;
}

.PWC-d .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 200px;
  display: flex;
  position: relative;
}

.PWC-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.PWC-d .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 650px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 57px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.PWC-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -20px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.PWC-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  margin-top: -40px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.PWC-d .element-of {
  object-fit: cover;
  width: 600px;
  height: 654px;
  position: absolute;
  top: 0;
  left: 543px;
}

.PWC-d .section {
  align-self: center;
  width: 1280px;
  height: 2500px;
  position: relative;
}

.PWC-d .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 1525px;
  display: inline-flex;
  position: absolute;
  top: 207px;
  left: 163px;
}

.PWC-d .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.PWC-d .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.PWC-d .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1005px;
  left: 750px;
}

.PWC-d .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PWC-d .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PWC-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 504px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-d .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.PWC-d .frame-4 {
  width: 448px;
  height: 177px;
  position: absolute;
  top: 265px;
  left: 605px;
}

.PWC-d .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.PWC-d .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-d .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.PWC-d .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.PWC-d .group-9 {
  width: 448px;
  left: 608px;
  position: absolute !important;
  top: 212px !important;
}

.PWC-d .group-9a {
  z-index: 99;
  width: 200px;
  transition: transform .3s ease-in-out;
  left: 842px;
  position: absolute !important;
  top: 369px !important;
}

.PWC-d .group-9a:hover {
  transform: scale(1.1);
}

.PWC-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
  left: -20px;
}

.PWC-d .group-9b {
  width: 180px;
  transition: transform .3s ease-in-out;
  left: 630px;
  position: absolute !important;
  top: 369px !important;
}

.PWC-d .group-9b:hover {
  transform: scale(1.1);
}

.PWC-d .referral-code {
  cursor: pointer;
  -webkit-user-select: all;
  user-select: all;
  font-weight: bold;
}

.PWC-d .xvoucher-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.PWC-d .xvoucher-link:hover {
  text-decoration: underline;
}

.PWC-d .appstore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.PWC-d .appstore-link:hover {
  text-decoration: underline;
}

.PWC-d .googlestore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.PWC-d .googlestore-link:hover {
  text-decoration: underline;
}

.PWC-d .overlap-wrapper {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 726px;
  left: 760px;
}

.PWC-d .overlap-7 {
  width: 338px;
  position: relative;
  top: 310px;
  left: -84px;
}

.PWC-d .overlap-77 {
  width: 264px;
  height: 90px;
  position: absolute;
  top: 918px;
  left: 738px;
}

.PWC-d .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.PWC-d .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.PWC-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 752px;
}

.PWC-d .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.PWC-d .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.PWC-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1600px !important;
  left: 682px !important;
}

.PWC-d .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1700px !important;
  left: 865px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PWC-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-d .component-instance {
  position: absolute;
  top: 1039px;
  left: 846px;
}

.PWC-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  margin-top: -219px;
  position: relative;
  top: 500px;
}

.PWC-d .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.PWC-d .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.PWC-d .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.PWC-d .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.PWC-d .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-d .text-wrapper-19 {
  color: #272936;
}

.PWC-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PWC-d .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.PWC-d .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.PWC-d .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.PWC-d .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.PWC-d .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-d .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.PWC-d .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.PWC-d .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.PWC-d .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.PWC-d .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.PWC-d .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PWC-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PWC-d .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PWC-d .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.PWC-d .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.PWC-d .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.PWC-d .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-d .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.PWC-d .section-3s {
  width: 1280px;
  height: 876px;
  margin-top: -21px;
  position: relative;
}

.PWC-d .what-is-scan-to-pay {
  color: #0000;
  letter-spacing: 0;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  left: 165px;
}

.PWC-d .text-wrapper-9s {
  color: #14a5ed;
  font-weight: 700;
}

.PWC-d .text-wrapper-10s {
  color: #000;
  font-weight: 100;
}

.PWC-d .FREE-SIMPLE-3s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 137px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -100px;
  left: 165px;
  -webkit-background-clip: text !important;
}

.PWC-d .section-2s {
  width: 1280px;
  height: 592px;
  margin-top: 1415px;
  position: relative;
}

.PWC-d .overlap-2s {
  width: 1023px;
  height: 285px;
  position: absolute;
  top: 180px;
  left: 145px;
}

.PWC-d .text-wrapper-8s {
  color: #000;
  letter-spacing: 0;
  cursor: pointer;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 100;
  line-height: 22.7px;
  position: absolute;
  top: 254px;
  left: 20px;
}

.PWC-d .slides-navigation {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 245px;
  left: 480px;
}

.PWC-d .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.PWC-d .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.PWC-d .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.PWC-d .dot-indictaor {
  position: relative !important;
}

.PWC-d .imagetakealot {
  object-fit: cover;
  width: 359px;
  height: 251px;
  position: absolute;
  top: 3px;
  left: 0;
}

.PWC-d .imageshell {
  object-fit: cover;
  width: 359px;
  height: 253px;
  position: absolute;
  top: 0;
  left: 336px;
}

.PWC-d .imagesnapscan {
  object-fit: cover;
  width: 359px;
  height: 253px;
  position: absolute;
  top: 0;
  left: 664px;
}

.PWC-d .FREE-SIMPLE-2s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 917px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 160px;
  -webkit-background-clip: text !important;
}

.PWC-d .df-container {
  width: 1280px;
  position: relative;
  top: 250px;
}

.PWC-d .roadshow {
  object-fit: cover;
  width: 963px;
  height: 1297px;
  position: absolute;
  top: 550px;
  left: 165px;
}

.PWC-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 4650px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PWC-i .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.PWC-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.PWC-i .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.PWC-i .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.PWC-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PWC-i .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.PWC-i .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-i .if-container {
  width: 768px;
  position: relative;
  top: 950px;
}

.PWC-i .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.PWC-i .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.PWC-i .overlap-5 {
  height: 598px;
  position: relative;
}

.PWC-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.PWC-i .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.PWC-i .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-i .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.PWC-i .main {
  width: 768px;
  height: 574px;
  position: relative;
}

.PWC-i .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.PWC-i .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -70px;
  position: absolute !important;
  top: 360px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.PWC-i .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-i .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 80px;
  left: -65px;
}

.PWC-i .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 100px;
  display: flex;
  position: relative;
}

.PWC-i .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 10px;
}

.PWC-i .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 383.28px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 33px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.PWC-i .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 386px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.PWC-i .p {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  height: 74px;
  margin-bottom: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-i .element-of {
  object-fit: cover;
  width: 408px;
  height: 455px;
  position: absolute;
  top: 0;
  left: 250px;
}

.PWC-i .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.PWC-i .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
}

.PWC-i .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PWC-i .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.PWC-i .component-4 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 980px;
  left: 430px;
  overflow: hidden;
}

.PWC-i .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PWC-i .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 330px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PWC-i .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 450px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-i .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.PWC-i .frame-4 {
  z-index: 1;
  width: 400px;
  height: 167px;
  position: absolute;
  top: 210px;
  left: 320px;
}

.PWC-i .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.PWC-i .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-i .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.PWC-i .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1750px;
  left: 490px;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PWC-i .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-i .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PWC-i .group-9 {
  width: 400px;
  height: 167px;
  position: absolute;
  top: 572px;
  left: 320px;
  overflow: hidden;
}

.PWC-i .group-2 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 20px;
}

.PWC-i .overlap-wrapper {
  width: 400px;
  height: 167px;
  position: absolute;
  top: 750px;
  left: 350px;
}

.PWC-i .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: 547px;
  left: 30px;
}

.PWC-i .overlap-77 {
  width: 264px;
  height: 90px;
  position: absolute;
  top: 969px;
  left: 445px;
}

.PWC-i .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.PWC-i .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.PWC-i .frame-lock {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 420px;
}

.PWC-i .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.PWC-i .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.PWC-i .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.PWC-i .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.PWC-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: 1284px;
}

.PWC-i .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.PWC-i .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PWC-i .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.PWC-i .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.PWC-i .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-i .text-wrapper-19 {
  color: #272936;
}

.PWC-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PWC-i .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.PWC-i .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.PWC-i .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.PWC-i .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.PWC-i .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-i .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.PWC-i .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.PWC-i .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.PWC-i .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.PWC-i .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.PWC-i .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.PWC-i .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.PWC-i .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.PWC-i .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.PWC-i .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.PWC-i .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.PWC-i .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.PWC-i .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.PWC-i .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.PWC-i .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.PWC-i .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.PWC-i .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.PWC-i .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.PWC-i .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.PWC-i .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-i .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.PWC-i .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.PWC-i .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.PWC-i .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.PWC-i .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.PWC-i .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PWC-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PWC-i .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PWC-i .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.PWC-i .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.PWC-i .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.PWC-i .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-i .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.PWC-i .section-3s {
  width: 768px;
  height: 876px;
  margin-top: 9px;
  position: relative;
}

.PWC-i .what-is-scan-to-pay {
  color: #0000;
  letter-spacing: 0;
  width: 600px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  top: -75px;
  left: 75px;
}

.PWC-i .text-wrapper-9s {
  color: #14a5ed;
  font-weight: 700;
}

.PWC-i .text-wrapper-10s {
  color: #000;
  font-weight: 100;
}

.PWC-i .FREE-SIMPLE-3s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 137px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -200px;
  left: 70px;
  -webkit-background-clip: text !important;
}

.PWC-i .section-2s {
  width: 768px;
  height: 592px;
  margin-top: 1619px;
  position: relative;
}

.PWC-i .overlap-2s {
  width: 768px;
  height: 285px;
  position: absolute;
  top: 180px;
  left: 65px;
}

.PWC-i .text-wrapper-8s {
  color: #000;
  letter-spacing: 0;
  cursor: pointer;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 100;
  line-height: 22.7px;
  position: absolute;
  top: 180px;
  left: 0;
}

.PWC-i .slides-navigation {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 245px;
  left: 480px;
}

.PWC-i .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.PWC-i .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.PWC-i .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.PWC-i .dot-indictaor {
  position: relative !important;
}

.PWC-i .imagetakealot {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 0;
  left: -25px;
}

.PWC-i .imageshell {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 0;
  left: 200px;
}

.PWC-i .imagesnapscan {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 0;
  left: 430px;
}

.PWC-i .FREE-SIMPLE-2s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 768px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 80px;
  left: 90px;
  -webkit-background-clip: text !important;
}

.PWC-i .df-container {
  width: 768px;
  position: relative;
  top: -250px;
}

.PWC-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 5080px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PWC-m .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.PWC-m .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.PWC-m .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.PWC-m .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.PWC-m .mf-container {
  width: 360px;
  position: relative;
  top: 828px;
}

.PWC-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PWC-m .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.PWC-m .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.PWC-m .overlap-5 {
  height: 435px;
  position: relative;
}

.PWC-m .rectangle-9 {
  object-fit: cover;
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.PWC-m .main {
  width: 360px;
  height: 384px;
  position: relative;
}

.PWC-m .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.PWC-m .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: 70px;
  position: absolute !important;
  top: 350px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.PWC-m .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-m .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: -10px;
  left: 42px;
}

.PWC-m .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 289px;
  height: 106px;
  display: flex;
  position: relative;
  top: 60px;
}

.PWC-m .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.PWC-m .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 315px;
  margin-top: -1px;
  font-family: Oswald, Helvetica;
  font-size: 23px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  left: 15px;
  -webkit-background-clip: text !important;
}

.PWC-m .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 289px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.PWC-m .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
}

.PWC-m .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1630px;
  left: 66px;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.PWC-m .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-m .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 255px;
}

.PWC-m .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.PWC-m .TEXT-BLOCK {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: relative;
  top: 137px;
  left: 23px;
}

.PWC-m .line-2 {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.PWC-m .TEXT {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PWC-m .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.PWC-m .component-4 {
  width: 243px;
  height: 96px;
}

.PWC-m .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.PWC-m .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.PWC-m .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-m .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  position: relative;
}

.PWC-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.PWC-m .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.PWC-m .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.PWC-m .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PWC-m .group-9 {
  width: 239px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 445px;
  overflow: hidden;
}

.PWC-m .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.PWC-m .overlap-wrapper {
  width: 267px;
  height: 164px;
  position: absolute;
  top: 713px;
  left: 438px;
  overflow: hidden;
}

.PWC-m .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: -2px;
  left: 1px;
}

.PWC-m .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.PWC-m .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.PWC-m .frame-lock {
  width: 243px;
  height: 89px;
  position: absolute;
  top: 1287px;
  left: 443px;
}

.PWC-m .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.PWC-m .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.PWC-m .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.PWC-m .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.PWC-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: 827px;
}

.PWC-m .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.PWC-m .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PWC-m .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.PWC-m .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.PWC-m .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-m .text-wrapper-19 {
  color: #272936;
}

.PWC-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PWC-m .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.PWC-m .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.PWC-m .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.PWC-m .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.PWC-m .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.PWC-m .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.PWC-m .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.PWC-m .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.PWC-m .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.PWC-m .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.PWC-m .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.PWC-m .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.PWC-m .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.PWC-m .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.PWC-m .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.PWC-m .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.PWC-m .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.PWC-m .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.PWC-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.PWC-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.PWC-m .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.PWC-m .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.PWC-m .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.PWC-m .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.PWC-m .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.PWC-m .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.PWC-m .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.PWC-m .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.PWC-m .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PWC-m .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PWC-m .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.PWC-m .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.PWC-m .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.PWC-m .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.PWC-m .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.PWC-m .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.PWC-m .section-3s {
  width: 360px;
  height: 876px;
  margin-top: 95px;
  position: relative;
  left: -50px;
}

.PWC-m .what-is-scan-to-pay {
  color: #0000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  top: 70px;
  left: 80px;
}

.PWC-m .text-wrapper-9s {
  color: #14a5ed;
  font-weight: 700;
}

.PWC-m .text-wrapper-10s {
  color: #000;
  font-weight: 100;
}

.PWC-m .FREE-SIMPLE-3s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 137px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -50px;
  left: 160px;
  -webkit-background-clip: text !important;
}

.PWC-m .section-2s {
  width: 360px;
  height: 592px;
  margin-top: -290px;
  position: relative;
}

.PWC-m .overlap-2s {
  width: 360px;
  height: 285px;
  position: absolute;
  top: 90px;
  left: -100px;
}

.PWC-m .text-wrapper-8s {
  color: #000;
  letter-spacing: 0;
  cursor: pointer;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 100;
  line-height: 22.7px;
  position: absolute;
  top: 530px;
  left: 160px;
}

.PWC-m .slides-navigation {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 245px;
  left: 480px;
}

.PWC-m .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.PWC-m .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.PWC-m .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.PWC-m .dot-indictaor {
  position: relative !important;
}

.PWC-m .imagetakealot {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 20px;
  left: 160px;
}

.PWC-m .imageshell {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 170px;
  left: 160px;
}

.PWC-m .imagesnapscan {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 320px;
  left: 160px;
}

.PWC-m .FREE-SIMPLE-2s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 315px;
  font-family: Oswald, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 30px;
  left: 25px;
  -webkit-background-clip: text !important;
}

.PWC-m .referral-code {
  cursor: pointer;
  -webkit-user-select: all;
  user-select: all;
  font-weight: bold;
}

.PWC-m .xvoucher-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.PWC-m .xvoucher-link:hover {
  text-decoration: underline;
}

.PWC-m .appstore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.PWC-m .appstore-link:hover {
  text-decoration: underline;
}

.PWC-m .googlestore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.PWC-m .googlestore-link:hover {
  text-decoration: underline;
}

.NewsD {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3050px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.NewsD .BG-elements {
  align-self: center;
  width: 1280px;
  height: 3374px;
  position: absolute;
  top: 0;
}

.NewsD .overlap-4 {
  height: 3524px;
  position: relative;
}

.NewsD .footer {
  width: 1179px;
  height: 315px;
  position: absolute;
  top: 3163px;
  left: 50px;
}

.NewsD .start {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 2525px;
  left: 50px;
}

.NewsD .overlap-group-5 {
  height: 638px;
  position: relative;
}

.NewsD .rectangle-8 {
  object-fit: cover;
  width: 1179px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.NewsD .gradient {
  width: 1179px;
  height: 638px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsD .mouse {
  width: 1179px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.NewsD .hero {
  width: 1180px;
  height: 607px;
  position: absolute;
  top: 120px;
  left: 50px;
}

.NewsD .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 480px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.NewsD .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.NewsD .overlap-5 {
  height: 598px;
  position: relative;
}

.NewsD .rectangle-9 {
  object-fit: cover;
  width: 1179px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsD .gradient-2 {
  width: 1179px;
  height: 500px;
  position: absolute;
  top: 3px;
  left: 0;
}

.NewsD .noize {
  width: 1280px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsD .nav-spacer {
  width: 1280px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.NewsD .main {
  width: 1280px;
  height: 574px;
  margin-top: -43px;
  position: relative;
}

.NewsD .overlap-6 {
  width: 1143px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.NewsD .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 130px;
  left: 0;
}

.NewsD .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 200px;
  display: flex;
  position: relative;
}

.NewsD .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.NewsD .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 917px;
  margin-top: -23px;
  font-family: Oswald, Helvetica;
  font-size: 200px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.NewsD .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -20px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.NewsD .p {
  color: #fff;
  letter-spacing: 0;
  width: 869px;
  height: 104px;
  margin-top: 32px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

.NewsD .element-of {
  object-fit: cover;
  width: 600px;
  height: 654px;
  position: absolute;
  top: 0;
  left: 543px;
}

.NewsD .section {
  align-self: center;
  width: 1280px;
  height: 2500px;
  position: relative;
}

.NewsD .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 1525px;
  display: inline-flex;
  position: absolute;
  top: 207px;
  left: 163px;
}

.NewsD .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.NewsD .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.NewsD .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1005px;
  left: 750px;
}

.NewsD .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 491px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.NewsD .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.NewsD .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 504px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.NewsD .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.NewsD .frame-4 {
  width: 448px;
  height: 177px;
  position: absolute;
  top: 265px;
  left: 605px;
}

.NewsD .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.NewsD .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsD .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.NewsD .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.NewsD .group-9 {
  width: 448px;
  left: 608px;
  position: absolute !important;
  top: 588px !important;
}

.NewsD .group-9a {
  z-index: 99;
  width: 200px;
  transition: transform .3s ease-in-out;
  left: 842px;
  position: absolute !important;
  top: 734px !important;
}

.NewsD .group-9a:hover {
  transform: scale(1.1);
}

.NewsD .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
  left: -20px;
}

.NewsD .group-9b {
  width: 180px;
  transition: transform .3s ease-in-out;
  left: 630px;
  position: absolute !important;
  top: 734px !important;
}

.NewsD .group-9b:hover {
  transform: scale(1.1);
}

.NewsD .referral-code {
  cursor: pointer;
  -webkit-user-select: all;
  user-select: all;
  font-weight: bold;
}

.NewsD .xvoucher-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.NewsD .xvoucher-link:hover {
  text-decoration: underline;
}

.NewsD .appstore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.NewsD .appstore-link:hover {
  text-decoration: underline;
}

.NewsD .googlestore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.NewsD .googlestore-link:hover {
  text-decoration: underline;
}

.NewsD .overlap-wrapper {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 726px;
  left: 760px;
}

.NewsD .overlap-7 {
  width: 338px;
  position: relative;
  top: 672px;
  left: -84px;
}

.NewsD .overlap-77 {
  width: 264px;
  height: 90px;
  position: absolute;
  top: 918px;
  left: 738px;
}

.NewsD .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.NewsD .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.NewsD .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 752px;
}

.NewsD .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.NewsD .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.NewsD .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1600px !important;
  left: 682px !important;
}

.NewsD .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 1700px !important;
  left: 865px !important;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.NewsD .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.NewsD .component-instance {
  position: absolute;
  top: 1039px;
  left: 846px;
}

.NewsD .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  margin-top: 155px;
  position: relative;
  top: 432px;
}

.NewsD .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 163px;
}

.NewsD .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  display: flex;
  position: relative;
}

.NewsD .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.NewsD .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.NewsD .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 491px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.NewsD .text-wrapper-19 {
  color: #272936;
}

.NewsD .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.NewsD .frame-7 {
  align-items: center;
  gap: 70px;
  width: 460px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 709px;
}

.NewsD .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -33px;
  display: flex;
  position: relative;
}

.NewsD .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.NewsD .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.NewsD .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsD .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.NewsD .footer-section {
  width: 1278px;
  height: 344px;
  margin-top: -43px;
  position: relative;
}

.NewsD .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.NewsD .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.NewsD .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.NewsD .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.NewsD .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.NewsD .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.NewsD .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.NewsD .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.NewsD .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.NewsD .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsD .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsD .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.NewsD .section-3s {
  width: 1280px;
  height: 876px;
  margin-top: -21px;
  position: relative;
}

.NewsD .what-is-scan-to-pay {
  color: #0000;
  letter-spacing: 0;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  left: 165px;
}

.NewsD .text-wrapper-9s {
  color: #14a5ed;
  font-weight: 700;
}

.NewsD .text-wrapper-10s {
  color: #000;
  font-weight: 100;
}

.NewsD .FREE-SIMPLE-3s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 137px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -100px;
  left: 165px;
  -webkit-background-clip: text !important;
}

.NewsD .section-2s {
  width: 1280px;
  height: 592px;
  margin-top: 1770px;
  position: relative;
}

.NewsD .overlap-2s {
  width: 1023px;
  height: 285px;
  position: absolute;
  top: 180px;
  left: 145px;
}

.NewsD .text-wrapper-8s {
  color: #000;
  letter-spacing: 0;
  cursor: pointer;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 100;
  line-height: 22.7px;
  position: absolute;
  top: 254px;
  left: 20px;
}

.NewsD .slides-navigation {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 245px;
  left: 480px;
}

.NewsD .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.NewsD .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.NewsD .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.NewsD .dot-indictaor {
  position: relative !important;
}

.NewsD .imagetakealot {
  object-fit: cover;
  width: 359px;
  height: 251px;
  position: absolute;
  top: 3px;
  left: 0;
}

.NewsD .imageshell {
  object-fit: cover;
  width: 359px;
  height: 253px;
  position: absolute;
  top: 0;
  left: 336px;
}

.NewsD .imagesnapscan {
  object-fit: cover;
  width: 359px;
  height: 253px;
  position: absolute;
  top: 0;
  left: 664px;
}

.NewsD .FREE-SIMPLE-2s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 917px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 160px;
  -webkit-background-clip: text !important;
}

.NewsD .df-container {
  width: 1280px;
  position: relative;
  top: 200px;
}

.NewsD .roadshow {
  object-fit: cover;
  width: 963px;
  height: 1297px;
  position: absolute;
  top: 550px;
  left: 165px;
}

.NewsD .featured-articles {
  justify-content: center;
  gap: 437px;
  width: 1055px;
  margin: 20px 0;
  display: flex;
  position: relative;
  top: 209px;
  left: 0;
}

.NewsD .article-large-1 {
  width: 48%;
  position: relative;
}

.NewsD .article-large img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.NewsD .article-large-1 .article-info {
  color: #fff;
  text-align: -webkit-center;
  background-color: #00000080;
  border-radius: 5px;
  width: 343px;
  height: 100px;
  padding: 10px;
  position: absolute;
  bottom: -50px;
  left: -10px;
}

.NewsD .article-large-1 .article-info h2 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 18px;
}

.NewsD .article-large-1 .article-info p {
  margin: 5px 0 0;
  font-size: 14px;
}

.NewsD .article-large-2 .article-info {
  color: #fff;
  text-align: -webkit-center;
  background-color: #00000080;
  border-radius: 5px;
  width: 343px;
  height: 100px;
  padding: 10px;
  position: absolute;
  bottom: -50px;
  left: -4px;
}

.NewsD .article-large-2 .article-info p {
  margin: 5px 0 0;
  font-size: 14px;
}

.NewsD .article-large-2 .article-info h2 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 18px;
}

.NewsD .articles-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 417px;
  width: 768px;
  margin: 20px 0;
  display: grid;
  position: relative;
  top: 541px;
  right: 131px;
}

.NewsD .article-small {
  width: calc(33.333% - 20px);
  position: relative;
}

.NewsD .article-small img {
  border-radius: 10px;
  width: 208px;
  height: auto;
  position: absolute;
  top: -224px;
}

.NewsD .article-small .article-info {
  color: #fff;
  background-color: #00000080;
  border-radius: 5px;
  width: 263px;
  height: 124px;
  padding: 10px;
  position: absolute;
  bottom: -29px;
  left: -21px;
}

.NewsD .article-small h3 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 10px;
  font-size: 16px;
}

.NewsD .article-small p {
  text-align: center;
  margin: 5px 0 0;
  font-size: 12px;
}

.NewsD .load-more {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  display: block;
}

.NewsD .load-more:hover {
  background-color: #0056b3;
}

.NewsD .article-large-2 {
  width: 48%;
  position: relative;
}

.NewsD .img-article-1 {
  border-radius: 10px;
  width: 100%;
  height: auto;
  position: relative;
  top: -68px;
  left: 6px;
}

.NewsD .img-article-2 {
  border-radius: 10px;
  width: 100%;
  height: auto;
  position: relative;
  top: -69px;
  left: 11px;
}

.NewsI {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 2332px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.NewsI .BG-elements {
  align-self: center;
  width: 768px;
  height: 3200px;
  position: absolute;
  top: 0;
}

.NewsI .overlap-4 {
  height: 3524px;
  position: relative;
}

.NewsI .footer {
  width: 709px;
  height: 275px;
  position: absolute;
  top: 3163px;
  left: 29px;
}

.NewsI .start {
  width: 709px;
  height: 289px;
  position: absolute;
  top: 2525px;
  left: 29px;
}

.NewsI .overlap-group-5 {
  height: 638px;
  position: relative;
}

.NewsI .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.NewsI .gradient {
  width: 768px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsI .if-container {
  width: 768px;
  position: relative;
  top: 72px;
}

.NewsI .mouse {
  width: 768px;
  height: 428px;
  position: absolute;
  top: 210px;
  left: 0;
}

.NewsI .hero {
  width: 768px;
  height: 607px;
  position: absolute;
  top: 120px;
}

.NewsI .overlap-5 {
  height: 598px;
  position: relative;
}

.NewsI .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.NewsI .gradient-2 {
  width: 709px;
  height: 300px;
  position: absolute;
  top: 3px;
  left: 0;
}

.NewsI .noize {
  width: 768px;
  height: 3524px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsI .nav-spacer {
  width: 768px;
  height: 151px;
  margin-top: -43px;
  position: relative;
}

.NewsI .main {
  width: 768px;
  height: 574px;
  position: relative;
}

.NewsI .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.NewsI .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: -70px;
  position: absolute !important;
  top: 360px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.NewsI .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.NewsI .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 80px;
  left: -65px;
}

.NewsI .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 611px;
  height: 100px;
  display: flex;
  position: relative;
}

.NewsI .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 10px;
}

.NewsI .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 664.28px;
  margin-top: -2px;
  font-family: Oswald, Helvetica;
  font-size: 144px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  top: -39px;
  -webkit-background-clip: text !important;
}

.NewsI .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 386px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.NewsI .p {
  color: #fff;
  letter-spacing: 0;
  width: 629px;
  height: 74px;
  margin-bottom: -10px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 44px;
}

.NewsI .element-of {
  object-fit: cover;
  width: 408px;
  height: 455px;
  position: absolute;
  top: 0;
  left: 250px;
}

.NewsI .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.NewsI .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
}

.NewsI .TEXT {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.NewsI .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.NewsI .component-4 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 980px;
  left: 430px;
  overflow: hidden;
}

.NewsI .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.NewsI .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  width: 330px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.NewsI .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  width: 450px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.NewsI .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  margin-left: -2.67px;
  position: relative;
}

.NewsI .frame-4 {
  z-index: 1;
  width: 400px;
  height: 167px;
  position: absolute;
  top: 210px;
  left: 320px;
}

.NewsI .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.NewsI .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsI .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.NewsI .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1750px;
  left: 490px;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.NewsI .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.NewsI .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.NewsI .group-9 {
  width: 400px;
  height: 167px;
  position: absolute;
  top: 572px;
  left: 320px;
  overflow: hidden;
}

.NewsI .group-2 {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 20px;
}

.NewsI .overlap-wrapper {
  width: 400px;
  height: 167px;
  position: absolute;
  top: 750px;
  left: 350px;
}

.NewsI .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: 547px;
  left: 30px;
}

.NewsI .overlap-77 {
  width: 264px;
  height: 90px;
  position: absolute;
  top: 969px;
  left: 445px;
}

.NewsI .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.NewsI .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.NewsI .frame-lock {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 420px;
}

.NewsI .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.NewsI .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.NewsI .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.NewsI .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.NewsI .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: 391px;
}

.NewsI .TEXT-wrapper {
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 75px;
}

.NewsI .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.NewsI .div-4 {
  color: #000;
  letter-spacing: 0;
  width: 493px;
  margin-top: -1px;
  margin-right: -178px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.NewsI .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.NewsI .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.NewsI .text-wrapper-19 {
  color: #272936;
}

.NewsI .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.NewsI .frame-7 {
  align-items: center;
  gap: 70px;
  width: 284px;
  display: flex;
  position: absolute;
  top: 140px;
  left: 409px;
}

.NewsI .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 493px;
  margin-right: -209px;
  display: flex;
  position: relative;
}

.NewsI .line-4 {
  width: 618px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 75px;
}

.NewsI .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.NewsI .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsI .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.NewsI .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.NewsI .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.NewsI .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.NewsI .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.NewsI .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.NewsI .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.NewsI .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.NewsI .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.NewsI .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.NewsI .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.NewsI .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.NewsI .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.NewsI .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.NewsI .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.NewsI .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.NewsI .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.NewsI .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.NewsI .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.NewsI .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsI .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.NewsI .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.NewsI .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.NewsI .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.NewsI .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.NewsI .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.NewsI .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.NewsI .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.NewsI .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.NewsI .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.NewsI .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.NewsI .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsI .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsI .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.NewsI .section-3s {
  width: 768px;
  height: 876px;
  margin-top: 9px;
  position: relative;
}

.NewsI .what-is-scan-to-pay {
  color: #0000;
  letter-spacing: 0;
  width: 600px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  top: -75px;
  left: 75px;
}

.NewsI .text-wrapper-9s {
  color: #14a5ed;
  font-weight: 700;
}

.NewsI .text-wrapper-10s {
  color: #000;
  font-weight: 100;
}

.NewsI .FREE-SIMPLE-3s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 137px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -200px;
  left: 70px;
  -webkit-background-clip: text !important;
}

.NewsI .section-2s {
  width: 768px;
  height: 592px;
  margin-top: 1619px;
  position: relative;
}

.NewsI .overlap-2s {
  width: 768px;
  height: 285px;
  position: absolute;
  top: 180px;
  left: 65px;
}

.NewsI .text-wrapper-8s {
  color: #000;
  letter-spacing: 0;
  cursor: pointer;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 100;
  line-height: 22.7px;
  position: absolute;
  top: 180px;
  left: 0;
}

.NewsI .slides-navigation {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 245px;
  left: 480px;
}

.NewsI .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.NewsI .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.NewsI .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.NewsI .dot-indictaor {
  position: relative !important;
}

.NewsI .imagetakealot {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 0;
  left: -25px;
}

.NewsI .imageshell {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 0;
  left: 200px;
}

.NewsI .imagesnapscan {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 0;
  left: 430px;
}

.NewsI .FREE-SIMPLE-2s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 768px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 80px;
  left: 90px;
  -webkit-background-clip: text !important;
}

.NewsI .df-container {
  width: 768px;
  position: relative;
  top: -250px;
}

.NewsI .featured-articles {
  justify-content: center;
  gap: 437px;
  width: 454px;
  margin: 20px 0;
  display: flex;
  position: relative;
  top: 140px;
  left: -119px;
}

.NewsI .article-large-1 {
  width: 48%;
  position: relative;
}

.NewsI .article-large img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.NewsI .article-large-1 .article-info {
  color: #fff;
  background-color: #00000080;
  border-radius: 5px;
  width: 293px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: -14px;
}

.NewsI .article-large-1 .article-info h2 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 16px;
}

.NewsI .article-large-1 .article-info p {
  text-align: -webkit-center;
  margin: 5px 0 0;
  font-size: 14px;
}

.NewsI .article-large-2 .article-info {
  color: #fff;
  background-color: #00000080;
  border-radius: 5px;
  width: 293px;
  padding: 10px;
  position: absolute;
  bottom: -2px;
  left: -11px;
}

.NewsI .article-large-2 .article-info p {
  text-align: -webkit-center;
  margin: 5px 0 0;
  font-size: 14px;
}

.NewsI .article-large-2 .article-info h2 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 16px;
}

.NewsI .articles-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 258px;
  width: 485px;
  margin: 20px 0;
  display: grid;
  position: relative;
  top: 359px;
  right: 92px;
}

.NewsI .article-small {
  width: calc(33.333% - 20px);
  position: relative;
}

.NewsI .article-small img {
  border-radius: 10px;
  width: 150px;
  position: absolute;
  top: -175px;
  left: 9px;
}

.NewsI .article-small .article-info {
  color: #fff;
  background-color: #00000080;
  border-radius: 5px;
  width: 184px;
  height: 127px;
  padding: 10px;
  position: absolute;
  bottom: -50px;
  left: -9px;
}

.NewsI .article-small h3 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 16px;
}

.NewsI .article-small p {
  text-align: -webkit-center;
  margin: 5px 0 0;
  font-size: 12px;
}

.NewsI .load-more {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  display: block;
}

.NewsI .load-more:hover {
  background-color: #0056b3;
}

.NewsI .article-large-2 {
  width: 48%;
  position: relative;
}

.NewsI .img-article-1 {
  border-radius: 10px;
  width: 251px;
  height: auto;
  position: absolute;
  top: -239px;
  left: 7px;
}

.NewsI .img-article-2 {
  border-radius: 10px;
  width: 251px;
  height: auto;
  position: absolute;
  top: -237px;
  left: 11px;
}

.NewsM {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3876px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.NewsM .BG-elements {
  align-self: center;
  width: 379px;
  height: 2926px;
  position: absolute;
  top: 0;
}

.NewsM .overlap-4 {
  width: 360px;
  height: 2925px;
  position: relative;
  top: 1px;
  left: 14px;
}

.NewsM .footer {
  width: 327px;
  height: 195px;
  position: absolute;
  top: 2730px;
  left: 17px;
}

.NewsM .start {
  width: 327px;
  height: 200px;
  position: absolute;
  top: 2530px;
  left: 15px;
}

.NewsM .mf-container {
  width: 360px;
  position: relative;
  top: 581px;
}

.NewsM .overlap-group-5 {
  height: 638px;
  position: relative;
}

.NewsM .rectangle-8 {
  object-fit: cover;
  width: 709px;
  height: 426px;
  position: absolute;
  top: 212px;
  left: 0;
}

.NewsM .gradient {
  width: 327px;
  height: 198px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .hero {
  width: 327px;
  height: 795px;
  position: absolute;
  top: 120px;
  left: 17px;
}

.NewsM .overlap-5 {
  height: 435px;
  position: relative;
}

.NewsM .rectangle-9 {
  object-fit: cover;
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .gradient-2 {
  width: 327px;
  height: 435px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .noize {
  width: 358px;
  height: 2925px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .nav-spacer {
  width: 360px;
  height: 175px;
  margin-top: -43px;
  position: relative;
}

.NewsM .main {
  width: 360px;
  height: 384px;
  position: relative;
}

.NewsM .overlap-6 {
  width: 768px;
  height: 654px;
  position: relative;
  top: 14px;
  left: 137px;
}

.NewsM .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  left: 70px;
  position: absolute !important;
  top: 350px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb;
}

.GS-button-3-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.NewsM .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.NewsM .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 289px;
  height: 264px;
  display: flex;
  position: relative;
  top: -10px;
  left: 42px;
}

.NewsM .HT {
  flex-direction: column;
  align-items: flex-start;
  width: 289px;
  height: 106px;
  display: flex;
  position: relative;
  top: 60px;
}

.NewsM .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.NewsM .FREE-SIMPLE {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 315px;
  margin-top: -1px;
  font-family: Oswald, Helvetica;
  font-size: 64px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  left: 5px;
  -webkit-background-clip: text !important;
}

.NewsM .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 289px;
  height: 39px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: -10px;
}

.NewsM .p {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  height: 74px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
  top: 35px;
}

.NewsM .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: .5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 1630px;
  left: 66px;
}

.GS-button:hover {
  background-color: #7f64fb;
}

.GS-button-text:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.NewsM .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.NewsM .element-of {
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 255px;
}

.NewsM .section {
  align-self: center;
  width: 360px;
  height: 1734px;
  margin-top: -43px;
  position: relative;
  bottom: 25px;
}

.NewsM .TEXT-BLOCK {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: relative;
  top: 137px;
  left: 23px;
}

.NewsM .line-2 {
  width: 326px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 17px;
}

.NewsM .TEXT {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.NewsM .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  width: 315px;
  display: flex;
  position: relative;
}

.NewsM .component-4 {
  width: 243px;
  height: 96px;
}

.NewsM .text-wrapper-15 {
  color: #7f64fb;
  letter-spacing: -.42px;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.NewsM .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 31px;
  font-weight: 100;
  line-height: 35.2px;
  position: relative;
}

.NewsM .text-wrapper-17 {
  color: #272936;
  letter-spacing: -.35px;
  text-align: center;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.NewsM .line-3 {
  object-fit: cover;
  width: 5.33px;
  height: 53.17px;
  position: relative;
}

.NewsM .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.NewsM .frame-49 {
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.NewsM .frame-5 {
  background-size: 100% 100%;
  width: 250px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

.NewsM .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.NewsM .group-9 {
  width: 239px;
  height: 163px;
  position: absolute;
  top: 428px;
  left: 445px;
  overflow: hidden;
}

.NewsM .group-2 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.NewsM .overlap-wrapper {
  width: 267px;
  height: 164px;
  position: absolute;
  top: 713px;
  left: 438px;
  overflow: hidden;
}

.NewsM .overlap-7 {
  width: 403px;
  height: 166px;
  position: relative;
  top: -2px;
  left: 1px;
}

.NewsM .asset {
  width: 382px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 11px;
}

.NewsM .clip-path-group {
  width: 356px;
  height: 98px;
  position: absolute;
  top: 34px;
  left: 21px;
}

.NewsM .frame-lock {
  width: 243px;
  height: 89px;
  position: absolute;
  top: 1287px;
  left: 443px;
}

.NewsM .WEB {
  color: #1185c3;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000026;
  white-space: nowrap;
  width: 117px;
  height: 80px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 83px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.NewsM .frame-36 {
  position: absolute !important;
  top: 0 !important;
  left: 121px !important;
}

.NewsM .component-3 {
  width: 234px;
  height: 58px;
  position: absolute;
  top: 1593px;
  left: 445px;
}

.NewsM .component-instance {
  position: absolute !important;
  top: 1017px !important;
  left: 445px !important;
}

.NewsM .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: 553px;
}

.NewsM .TEXT-wrapper {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 23px;
}

.NewsM .TEXT-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.NewsM .div-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
  position: relative;
}

.NewsM .span {
  color: #000;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 27px;
  font-weight: 100;
  line-height: 30.6px;
}

.NewsM .div-5 {
  color: #0000;
  letter-spacing: -.35px;
  text-align: center;
  z-index: 9;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.NewsM .text-wrapper-19 {
  color: #272936;
}

.NewsM .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.NewsM .frame-7 {
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 315px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 23px;
}

.NewsM .TEXT-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 315px;
  display: flex;
  position: relative;
}

.NewsM .line-4 {
  width: 948px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 163px;
}

.NewsM .overlap-8 {
  width: 323px;
  height: 154px;
  position: absolute;
  top: 145px;
  left: 137px;
}

.NewsM .text-wrapper-21 {
  color: #e9edf3;
  letter-spacing: 0;
  width: 224px;
  font-family: Titillium Web, Helvetica;
  font-size: 66px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .text-wrapper-22 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 323px;
  height: 79px;
  font-family: Oswald, Helvetica;
  font-size: 66px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 0;
  -webkit-background-clip: text !important;
}

.NewsM .footer-section {
  width: 768px;
  height: 344px;
  position: relative;
}

.NewsM .frame-8 {
  align-items: flex-start;
  gap: 307px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 144px;
}

.NewsM .XG-logo {
  width: 155.66px;
  height: 31.84px;
  position: relative;
}

.NewsM .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.NewsM .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.NewsM .text-wrapper-23 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.NewsM .text-wrapper-24 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.NewsM .follow-us {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: relative;
}

.NewsM .follow-us-2 {
  width: 98.78px;
  height: 81.5px;
  position: relative;
}

.NewsM .text-wrapper-25 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.NewsM .social {
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 0;
}

.NewsM .telegram-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-left: -.5px !important;
  position: relative !important;
}

.NewsM .twitter-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.5px !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.NewsM .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  position: relative !important;
}

.NewsM .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -.51px !important;
  margin-bottom: -.49px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.NewsM .text-wrapper-26 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 1px;
}

.NewsM .t-cs {
  width: 61px;
  height: 17.49px;
  position: relative;
}

.NewsM .overlap-group-6 {
  height: 17px;
  position: relative;
  top: -1px;
}

.NewsM .text-wrapper-27 {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .all-rights-reserved {
  color: #e8e8e8;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

.NewsM .NAV {
  z-index: 1000;
  background-color: #f3f3f3;
  align-self: center;
  width: 768px;
  height: 120px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.NewsM .frame-9 {
  align-items: center;
  gap: 732px;
  width: 1179px;
  display: flex;
  position: relative;
  top: 47px;
  left: 50px;
}

.NewsM .xion-logo {
  width: 156.98px;
  height: 30.71px;
  position: relative;
}

.NewsM .frame-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-right: -.98px;
  display: inline-flex;
  position: relative;
}

.NewsM .frame-wrapper {
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.NewsM .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.NewsM .get-started-wrapper {
  border: 1px solid #439be4;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: relative;
}

.NewsM .get-started-3 {
  color: #439be4;
  letter-spacing: 3.24px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 55px;
}

.NewsM .div-7 {
  width: 45px;
  height: 39px;
  position: relative;
}

.NewsM .shadows {
  background: linear-gradient(#0009 0%, #fff0 100%);
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #fff;
}

.NewsM .shadows-2 {
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border: 2px solid #0000;
  border-image: linear-gradient(#ab2ffe, #40affd 96.88%) 1;
  border-radius: 10px;
  width: 45px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.NewsM .group-4 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.NewsM .section-3s {
  width: 360px;
  height: 876px;
  margin-top: 95px;
  position: relative;
  left: -50px;
}

.NewsM .what-is-scan-to-pay {
  color: #0000;
  letter-spacing: 0;
  width: 315px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  top: 70px;
  left: 80px;
}

.NewsM .text-wrapper-9s {
  color: #14a5ed;
  font-weight: 700;
}

.NewsM .text-wrapper-10s {
  color: #000;
  font-weight: 100;
}

.NewsM .FREE-SIMPLE-3s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 137px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -50px;
  left: 160px;
  -webkit-background-clip: text !important;
}

.NewsM .section-2s {
  width: 360px;
  height: 592px;
  margin-top: 60px;
  position: relative;
}

.NewsM .overlap-2s {
  width: 360px;
  height: 285px;
  position: absolute;
  top: 90px;
  left: -100px;
}

.NewsM .text-wrapper-8s {
  color: #000;
  letter-spacing: 0;
  cursor: pointer;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 100;
  line-height: 22.7px;
  position: absolute;
  top: 530px;
  left: 160px;
}

.NewsM .slides-navigation {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 245px;
  left: 480px;
}

.NewsM .click-area {
  flex: none;
  align-items: flex-start;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.NewsM .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.NewsM .slide-indicator {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.NewsM .dot-indictaor {
  position: relative !important;
}

.NewsM .imagetakealot {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 20px;
  left: 160px;
}

.NewsM .imageshell {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 170px;
  left: 160px;
}

.NewsM .imagesnapscan {
  object-fit: cover;
  width: 250px;
  position: absolute;
  top: 320px;
  left: 160px;
}

.NewsM .FREE-SIMPLE-2s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 315px;
  font-family: Oswald, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 30px;
  left: 25px;
  -webkit-background-clip: text !important;
}

.NewsM .referral-code {
  cursor: pointer;
  -webkit-user-select: all;
  user-select: all;
  font-weight: bold;
}

.NewsM .xvoucher-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.NewsM .xvoucher-link:hover {
  text-decoration: underline;
}

.NewsM .appstore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.NewsM .appstore-link:hover {
  text-decoration: underline;
}

.NewsM .googlestore-link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.NewsM .googlestore-link:hover {
  text-decoration: underline;
}

.NewsM .featured-articles {
  flex-direction: column;
  justify-content: center;
  gap: 319px;
  width: 368px;
  margin: 20px 0;
  display: flex;
  position: relative;
  top: 320px;
  left: 22px;
}

.NewsM .article-large-1 {
  width: 48%;
  position: relative;
}

.NewsM .article-large img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.NewsM .article-large-1 .article-info h2 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 16px;
}

.NewsM .article-large-1 .article-info p {
  text-align: -webkit-center;
  margin: 5px 0 0;
  padding: 5px;
  font-size: 10px;
}

.NewsM .article-large-1 .article-info {
  color: #fff;
  background-color: #00000080;
  border-radius: 5px;
  width: 282px;
  padding: 10px;
  position: absolute;
  bottom: 5px;
  left: 31px;
}

.NewsM .article-large-2 .article-info p {
  text-align: -webkit-center;
  margin: 5px 0 0;
  padding: 5px;
  font-size: 10px;
}

.NewsM .article-large-2 .article-info h2 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 16px;
}

.NewsM .article-large-2 .article-info {
  color: #fff;
  background-color: #00000080;
  border-radius: 5px;
  width: 282px;
  padding: 10px;
  position: absolute;
  bottom: 10px;
  left: 31px;
}

.NewsM .articles-grid {
  grid-template-columns: 1fr;
  gap: 258px;
  width: 330px;
  height: 1600px;
  margin: 20px 0;
  display: grid;
  position: relative;
  top: 568px;
  right: -66px;
}

.NewsM .article-small {
  width: calc(33.333% - 20px);
  position: relative;
}

.NewsM .article-small img {
  border-radius: 10px;
  width: 194px;
  height: auto;
  position: absolute;
  top: -211px;
  left: 8px;
}

.NewsM .article-small .article-info {
  color: #fff;
  background-color: #00000080;
  border-radius: 5px;
  width: 239px;
  height: 101px;
  padding: 10px;
  position: absolute;
  bottom: 42px;
  left: -13px;
}

.NewsM .article-small h3 {
  cursor: pointer;
  text-align: -webkit-center;
  margin: 0;
  font-size: 16px;
}

.NewsM .article-small p {
  text-align: -webkit-center;
  margin: 5px 0 0;
  padding: 5px;
  font-size: 10px;
}

.NewsM .load-more {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  display: block;
}

.NewsM .load-more:hover {
  background-color: #0056b3;
}

.NewsM .article-large-2 {
  width: 48%;
  position: relative;
}

.NewsM .img-article-1 {
  border-radius: 10px;
  width: 234px;
  height: auto;
  position: absolute;
  top: -238px;
  left: 56px;
}

.NewsM .img-article-2 {
  border-radius: 10px;
  width: 234px;
  height: auto;
  position: absolute;
  top: -236px;
  left: 56px;
}

.Refer-d {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 73px;
  display: flex;
  position: relative;
}

.Refer-d .header {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 199px;
  display: flex;
  position: relative;
}

.Refer-d .banner {
  background: linear-gradient(#1ddeb8 0%, #009bff 100%);
  align-self: stretch;
  width: 100%;
  height: 32px;
  position: relative;
}

.Refer-d .refer-earn-for-your {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 5px;
}

.Refer-d .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.Refer-d .span {
  font-weight: 700;
}

.Refer-d .nav {
  background-color: #fff;
  align-self: stretch;
  width: 100%;
  height: 96px;
  position: relative;
}

.Refer-d .container {
  width: 100%;
  height: 48px;
  position: relative;
  top: 24px;
}

.Refer-d .div {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 100%;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all .3s;
  position: absolute;
  top: 11px;
  right: 300px;
}

.div:hover, .div.clicked {
  font-weight: bold;
}

@keyframes flow {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.Refer-d .button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff) 0 0 / 200% 200%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 10%;
  height: 48px;
  padding: 16px 0;
  animation: 4s linear infinite flow;
  display: flex;
  position: absolute;
  top: 0;
  right: 50px;
}

.Refer-d .button:hover, .Refer-d .button.clicked {
  transform: scale(1.2);
}

.Refer-d .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -5px;
  margin-bottom: -3px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.Refer-d .logo {
  height: 48px;
  position: absolute;
  top: 0;
  left: 64px;
}

.Refer-d .overlap-group {
  background-image: url("vectorreferral.dd0d44e4.svg");
  background-size: 100% 100%;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-d .vector {
  width: 26px;
  height: 33px;
  position: absolute;
  top: 7px;
  left: 4px;
}

.Refer-d .img {
  width: 7px;
  height: 29px;
  position: absolute;
  top: 7px;
  left: 9px;
}

.Refer-d .vector-2 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 14px;
  left: 28px;
}

.Refer-d .REFER-EARN {
  color: #1ddeb8;
  letter-spacing: 0;
  white-space: nowrap;
  width: 181px;
  height: 35px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 6px;
  left: 58px;
}

.Refer-d .text-wrapper-3 {
  font-style: italic;
  font-weight: 600;
}

.Refer-d .text-wrapper-4 {
  font-style: italic;
  font-weight: 700;
}

.Refer-d .main {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 50px;
  width: 1116.22px;
  display: flex;
  position: relative;
}

.Refer-d .image {
  border-radius: 40px;
  width: 922px;
  height: 356px;
  position: relative;
  overflow: hidden;
}

.Refer-d .overlap-group-wrapper {
  width: 927px;
  height: 342px;
  position: relative;
  left: -1px;
}

.Refer-d .overlap-group-2 {
  height: 342px;
  position: relative;
}

.Refer-d .bright-cartoon {
  width: 922px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 1px;
}

.Refer-d .refer-earn {
  width: 927px;
  height: 125px;
  position: absolute;
  top: 218px;
  left: 0;
}

.Refer-d .invite-a-friend-to {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 151px;
  font-family: Titillium Web, Helvetica;
  font-size: 60px;
  font-weight: 900;
  line-height: 60px;
  position: relative;
}

.Refer-d .text-wrapper-5 {
  color: #0b2738;
}

.Refer-d .text-wrapper-6 {
  color: #009bff;
}

.Refer-d .text-wrapper-7 {
  color: #1ddeb8;
}

.Refer-d .p {
  color: #737373;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  height: 35px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  position: relative;
}

.Refer-d .how-to {
  width: 668px;
  height: 402px;
  position: relative;
}

.Refer-d .element {
  width: 668px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-d .div-wrapper {
  background-color: #8870d0;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-d .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 9px;
  height: 16px;
  font-family: Nimbus Sans-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 9px;
  left: 11px;
}

.Refer-d .text-wrapper-9 {
  color: #000;
  letter-spacing: 0;
  width: 610px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 56px;
}

.Refer-d .element-2 {
  width: 670px;
  height: 32px;
  position: absolute;
  top: 50px;
  left: 0;
}

.Refer-d .text-wrapper-10 {
  color: #000;
  letter-spacing: 0;
  width: 612px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 56px;
}

.Refer-d .element-3 {
  width: 668px;
  height: 32px;
  position: absolute;
  top: 100px;
  left: 0;
}

.Refer-d .element-4 {
  width: 670px;
  height: 32px;
  position: absolute;
  top: 150px;
  left: 0;
}

.Refer-d .element-5 {
  width: 670px;
  height: 34px;
  position: absolute;
  top: 210px;
  left: 0;
}

.Refer-d .element-6 {
  background-color: #8870d0;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 1px;
  left: 0;
}

.Refer-d .your-friend-has-to {
  color: #000;
  letter-spacing: 0;
  width: 612px;
  height: 34px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 56px;
}

.Refer-d .element-7 {
  width: 668px;
  height: 36px;
  position: absolute;
  top: 276px;
  left: 0;
}

.Refer-d .text-wrapper-11 {
  color: #000;
  letter-spacing: 0;
  width: 610px;
  height: 36px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 56px;
}

.Refer-d .element-8 {
  width: 668px;
  height: 32px;
  position: absolute;
  top: 333px;
  left: 0;
}

.Refer-d .button-2 {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff) 0 0 / 200% 200%;
  border-radius: 8px;
  width: 251.36px;
  height: 56px;
  animation: 4s linear infinite flow;
  position: relative;
}

.Refer-d .button-2:hover, .Refer-d .button-2.clicked {
  transform: scale(1.2);
}

.Refer-d .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 156px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 15px;
  left: 48px;
}

.Refer-d .rules {
  width: 768px;
  height: 168px;
  margin-bottom: 46px;
  position: relative;
}

.Refer-d .text-wrapper-13 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 61px;
  height: 32px;
  font-family: Titillium Web, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: -1px;
  left: 353px;
}

.Refer-d .frame {
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 0;
}

.Refer-d .background {
  background-color: #f2f2f2;
  border-radius: 12px;
  width: 245.33px;
  height: 140px;
  position: relative;
}

.Refer-d .the-person-you-refer {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 192px;
  height: 80px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 12px;
  left: 27px;
}

.Refer-d .your-referred-friend {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  height: 80px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 12px;
  left: 18px;
}

.Refer-d .the-you-and-your {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 192px;
  height: 60px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 14px;
  left: 27px;
}

.Refer-d .read-the-full-t-s-c {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.Refer-d .text-wrapper-14 {
  color: #000;
}

.Refer-d .text-wrapper-15 {
  color: #2482c5;
  text-decoration: underline;
}

.Refer-d .footer {
  background-color: #0b2738;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
}

.Refer-d .group {
  width: 100%;
  height: 64px;
  margin-right: -.38px;
  position: relative;
}

.Refer-d .frame-2 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.Refer-d .frame-3 {
  flex: none;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: relative;
}

.Refer-d .logo-2 {
  width: 236.88px;
  height: 25.31px;
  position: relative;
}

.Refer-d .overlap-group-3 {
  background-image: url("vector-4r.950e116b.svg");
  background-size: 100% 100%;
  width: 56px;
  height: 55px;
  position: absolute;
  top: -4px;
  left: -31px;
}

.Refer-d .vector-3 {
  width: 14px;
  height: 17px;
  position: absolute;
  top: 4px;
  left: 2px;
}

.Refer-d .vector-4 {
  width: 4px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.Refer-d .vector-5 {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 7px;
  left: 15px;
}

.Refer-d .REFER-EARN-2 {
  color: #1ddeb8;
  letter-spacing: 0;
  white-space: nowrap;
  width: 205px;
  height: 18px;
  font-family: Titillium Web, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 3px;
  left: 30px;
}

.Refer-d .frame-4 {
  flex: none;
  align-items: center;
  gap: 96px;
  display: inline-flex;
  position: relative;
}

.Refer-d .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 157.01px;
  display: flex;
  position: relative;
}

.Refer-d .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.Refer-d .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.Refer-d .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 99.05px;
  display: flex;
  position: relative;
}

.Refer-d .frame-7 {
  flex: none;
  position: relative;
}

.Refer-d .frame-8 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 135px;
  display: flex;
  position: relative;
}

.Refer-d .fantasy-go-pty-ltd {
  color: #999;
  letter-spacing: 0;
  width: 209.01px;
  height: 135px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.Refer-d .frame-9 {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 1444px;
  display: flex;
  position: relative;
}

.Refer-d .DISCLAIMER {
  color: #999;
  letter-spacing: 0;
  width: 1444px;
  height: 45px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.Refer-d .link {
  width: 94.95px;
  height: 15px;
  position: relative;
}

.Refer-d .national-responsible {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 0;
}

.Refer-d .national-gambling-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 102px;
  height: 15px;
  position: relative;
}

.Refer-d .national-gambling {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-d .financial-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.13px;
  height: 15px;
  position: relative;
}

.Refer-d .financial {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-d .fantasygo-self-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
}

.Refer-d .fantasygo-self {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-d .highlight {
  animation: 2s infinite flash;
}

@keyframes flash {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px #0080ff;
  }

  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px #00ffae;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 5px #00ff2f;
  }
}

.Refer-i {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 73px;
  display: flex;
  position: relative;
}

.Refer-i .header {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.Refer-i .banner {
  background: linear-gradient(#1ddeb8 0%, #009bff 100%);
  align-self: stretch;
  width: 100%;
  height: 32px;
  position: relative;
}

.Refer-i .refer-earn-for-your {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 5px;
}

.Refer-i .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.Refer-i .span {
  font-weight: 700;
}

.Refer-i .nav {
  background-color: #fff;
  align-self: stretch;
  width: 100%;
  height: 96px;
  position: relative;
}

.Refer-i .container {
  width: 100%;
  height: 48px;
  position: relative;
  top: 24px;
}

.Refer-i .div {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 100%;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all .3s;
  position: absolute;
  top: 11px;
  right: 242px;
}

.div:hover, .div.clicked {
  font-weight: bold;
}

@keyframes flow {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.Refer-i .button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff) 0 0 / 200% 200%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 15%;
  height: 48px;
  padding: 16px 0;
  animation: 4s linear infinite flow;
  display: flex;
  position: absolute;
  top: 0;
  right: 50px;
}

.Refer-i .button:hover, .Refer-i .button.clicked {
  transform: scale(1.2);
}

.Refer-i .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -5px;
  margin-bottom: -3px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.Refer-i .logo {
  height: 48px;
  position: absolute;
  top: 0;
  left: 64px;
}

.Refer-i .overlap-group {
  background-image: url("vectorreferral.dd0d44e4.svg");
  background-size: 100% 100%;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-i .vector {
  width: 26px;
  height: 33px;
  position: absolute;
  top: 7px;
  left: 4px;
}

.Refer-i .img {
  width: 7px;
  height: 29px;
  position: absolute;
  top: 7px;
  left: 9px;
}

.Refer-i .vector-2 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 14px;
  left: 28px;
}

.Refer-i .REFER-EARN {
  color: #1ddeb8;
  letter-spacing: 0;
  white-space: nowrap;
  width: 181px;
  height: 35px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 6px;
  left: 58px;
}

.Refer-i .text-wrapper-3 {
  font-style: italic;
  font-weight: 600;
}

.Refer-i .text-wrapper-4 {
  font-style: italic;
  font-weight: 700;
}

.Refer-i .main {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 50px;
  width: 768px;
  display: flex;
  position: relative;
}

.Refer-i .image {
  border-radius: 40px;
  width: 768px;
  height: 356px;
  position: relative;
  overflow: hidden;
}

.Refer-i .overlap-group-wrapper {
  width: 768px;
  height: 342px;
  position: relative;
  left: -1px;
}

.Refer-i .overlap-group-2 {
  height: 342px;
  position: relative;
}

.Refer-i .bright-cartoon {
  width: 768px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 1px;
}

.Refer-i .refer-earn {
  width: 768px;
  height: 125px;
  position: absolute;
  top: 218px;
  left: 0;
}

.Refer-i .invite-a-friend-to {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 151px;
  font-family: Titillium Web, Helvetica;
  font-size: 60px;
  font-weight: 900;
  line-height: 60px;
  position: relative;
}

.Refer-i .text-wrapper-5 {
  color: #0b2738;
}

.Refer-i .text-wrapper-6 {
  color: #009bff;
}

.Refer-i .text-wrapper-7 {
  color: #1ddeb8;
}

.Refer-i .p {
  color: #737373;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  height: 35px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  position: relative;
}

.Refer-i .how-to {
  width: 668px;
  height: 402px;
  position: relative;
}

.Refer-i .element {
  width: 668px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-i .div-wrapper {
  background-color: #8870d0;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-i .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 9px;
  height: 16px;
  font-family: Nimbus Sans-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 9px;
  left: 11px;
}

.Refer-i .text-wrapper-9 {
  color: #000;
  letter-spacing: 0;
  width: 610px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 56px;
}

.Refer-i .element-2 {
  width: 670px;
  height: 32px;
  position: absolute;
  top: 50px;
  left: 0;
}

.Refer-i .text-wrapper-10 {
  color: #000;
  letter-spacing: 0;
  width: 612px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 56px;
}

.Refer-i .element-3 {
  width: 668px;
  height: 32px;
  position: absolute;
  top: 100px;
  left: 0;
}

.Refer-i .element-4 {
  width: 670px;
  height: 32px;
  position: absolute;
  top: 150px;
  left: 0;
}

.Refer-i .element-5 {
  width: 670px;
  height: 34px;
  position: absolute;
  top: 210px;
  left: 0;
}

.Refer-i .element-6 {
  background-color: #8870d0;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 1px;
  left: 0;
}

.Refer-i .your-friend-has-to {
  color: #000;
  letter-spacing: 0;
  width: 612px;
  height: 34px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 56px;
}

.Refer-i .element-7 {
  width: 668px;
  height: 36px;
  position: absolute;
  top: 276px;
  left: 0;
}

.Refer-i .text-wrapper-11 {
  color: #000;
  letter-spacing: 0;
  width: 610px;
  height: 36px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 56px;
}

.Refer-i .element-8 {
  width: 668px;
  height: 32px;
  position: absolute;
  top: 333px;
  left: 0;
}

.Refer-i .button-2 {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff) 0 0 / 200% 200%;
  border-radius: 8px;
  width: 251.36px;
  height: 56px;
  animation: 4s linear infinite flow;
  position: relative;
}

.Refer-i .button-2:hover, .Refer-i .button-2.clicked {
  transform: scale(1.2);
}

.Refer-i .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 156px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 15px;
  left: 48px;
}

.Refer-i .rules {
  width: 768px;
  height: 168px;
  margin-bottom: 46px;
  position: relative;
}

.Refer-i .text-wrapper-13 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 61px;
  height: 32px;
  font-family: Titillium Web, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: -1px;
  left: 353px;
}

.Refer-i .frame {
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 0;
}

.Refer-i .background {
  background-color: #f2f2f2;
  border-radius: 12px;
  width: 245.33px;
  height: 140px;
  position: relative;
}

.Refer-i .the-person-you-refer {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 192px;
  height: 80px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 12px;
  left: 27px;
}

.Refer-i .your-referred-friend {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  height: 80px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 12px;
  left: 18px;
}

.Refer-i .the-you-and-your {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 192px;
  height: 60px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 14px;
  left: 27px;
}

.Refer-i .read-the-full-t-s-c {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.Refer-i .text-wrapper-14 {
  color: #000;
}

.Refer-i .text-wrapper-15 {
  color: #2482c5;
  text-decoration: underline;
}

.Refer-i .footer {
  background-color: #0b2738;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
}

.Refer-i .group {
  width: 100%;
  height: 64px;
  margin-right: -.38px;
  position: relative;
}

.Refer-i .frame-2 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.Refer-i .frame-3 {
  flex: none;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: relative;
}

.Refer-i .logo-2 {
  width: 236.88px;
  height: 25.31px;
  position: relative;
}

.Refer-i .overlap-group-3 {
  background-image: url("vector-4r.950e116b.svg");
  background-size: 100% 100%;
  width: 56px;
  height: 55px;
  position: absolute;
  top: -4px;
  left: -31px;
}

.Refer-i .vector-3 {
  width: 14px;
  height: 17px;
  position: absolute;
  top: 4px;
  left: 2px;
}

.Refer-i .vector-4 {
  width: 4px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.Refer-i .vector-5 {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 7px;
  left: 15px;
}

.Refer-i .REFER-EARN-2 {
  color: #1ddeb8;
  letter-spacing: 0;
  white-space: nowrap;
  width: 205px;
  height: 18px;
  font-family: Titillium Web, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 3px;
  left: 30px;
}

.Refer-i .frame-4 {
  flex: none;
  align-items: center;
  gap: 96px;
  display: inline-flex;
  position: relative;
}

.Refer-i .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 157.01px;
  display: flex;
  position: relative;
}

.Refer-i .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.Refer-i .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.Refer-i .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 99.05px;
  display: flex;
  position: relative;
}

.Refer-i .frame-7 {
  flex: none;
  height: 24px;
  position: absolute;
  top: 218px;
  right: 6px;
}

.Refer-i .frame-8 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 135px;
  display: flex;
  position: relative;
}

.Refer-i .fantasy-go-pty-ltd {
  color: #999;
  letter-spacing: 0;
  width: 209.01px;
  height: 135px;
  margin-top: -1px;
  padding-right: 5px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.Refer-i .frame-9 {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 992px;
  display: flex;
  position: relative;
}

.Refer-i .DISCLAIMER {
  color: #999;
  letter-spacing: 0;
  width: 768px;
  height: 45px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.Refer-i .link {
  width: 94.95px;
  height: 15px;
  position: relative;
}

.Refer-i .national-responsible {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 0;
}

.Refer-i .national-gambling-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 102px;
  height: 15px;
  position: relative;
}

.Refer-i .national-gambling {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-i .financial-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.13px;
  height: 15px;
  position: relative;
}

.Refer-i .financial {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-i .fantasygo-self-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
}

.Refer-i .fantasygo-self {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-i .highlight {
  animation: 2s infinite flash;
}

@keyframes flash {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px #0080ff;
  }

  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px #00ffae;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 5px #00ff2f;
  }
}

.Refer-m {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 73px;
  display: flex;
  position: relative;
}

.Refer-m .header {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.Refer-m .banner {
  background: linear-gradient(#1ddeb8 0%, #009bff 100%);
  align-self: stretch;
  width: 100%;
  height: 32px;
  position: relative;
}

.Refer-m .refer-earn-for-your {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 5px;
}

.Refer-m .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.Refer-m .span {
  font-weight: 700;
}

.Refer-m .nav {
  background-color: #fff;
  align-self: stretch;
  width: 100%;
  height: 96px;
  position: relative;
}

.Refer-m .container {
  width: 100%;
  height: 48px;
  position: relative;
  top: 24px;
}

.Refer-m .div {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all .3s;
  position: absolute;
  top: 11px;
  right: 213px;
}

.div:hover, .div.clicked {
  font-weight: bold;
}

@keyframes flow {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.Refer-m .button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff) 0 0 / 200% 200%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 27%;
  height: 48px;
  padding: 16px 0;
  animation: 4s linear infinite flow;
  display: flex;
  position: absolute;
  top: 0;
  right: 50px;
}

.Refer-m .button:hover, .Refer-m .button.clicked {
  transform: scale(1.2);
}

.Refer-m .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -5px;
  margin-bottom: -3px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.Refer-m .logo {
  height: 48px;
  position: absolute;
  top: 0;
  left: 64px;
}

.Refer-m .overlap-group {
  background-image: url("vectorreferral.dd0d44e4.svg");
  background-size: 100% 100%;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: -27px;
}

.Refer-m .vector {
  width: 26px;
  height: 33px;
  position: absolute;
  top: 7px;
  left: 4px;
}

.Refer-m .img {
  width: 7px;
  height: 29px;
  position: absolute;
  top: 7px;
  left: 9px;
}

.Refer-m .vector-2 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 14px;
  left: 28px;
}

.Refer-m .REFER-EARN {
  color: #1ddeb8;
  letter-spacing: 0;
  white-space: nowrap;
  height: 35px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 6px;
  left: 23px;
}

.Refer-m .text-wrapper-3 {
  font-style: italic;
  font-weight: 600;
}

.Refer-m .text-wrapper-4 {
  font-style: italic;
  font-weight: 700;
}

.Refer-m .main {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 50px;
  width: 325px;
  display: flex;
  position: relative;
}

.Refer-m .image {
  border-radius: 40px;
  width: 325px;
  height: 165px;
  position: relative;
  overflow: hidden;
}

.Refer-m .overlap-group-wrapper {
  width: 325px;
  height: 342px;
  position: relative;
  left: -1px;
}

.Refer-m .overlap-group-2 {
  height: 342px;
  position: relative;
}

.Refer-m .bright-cartoon {
  width: 325px;
  height: 159px;
  position: absolute;
  top: 0;
  left: 1px;
}

.Refer-m .refer-earn {
  width: 320px;
  height: 125px;
  position: absolute;
  top: 73px;
  left: 3px;
}

.Refer-m .invite-a-friend-to {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 151px;
  font-family: Titillium Web, Helvetica;
  font-size: 40px;
  font-weight: 900;
  line-height: 60px;
  position: relative;
}

.Refer-m .text-wrapper-5 {
  color: #0b2738;
}

.Refer-m .text-wrapper-6 {
  color: #009bff;
}

.Refer-m .text-wrapper-7 {
  color: #1ddeb8;
}

.Refer-m .p {
  color: #737373;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  height: 35px;
  font-family: Titillium Web, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  position: relative;
  top: 107px;
}

.Refer-m .how-to {
  width: 325px;
  height: 402px;
  position: relative;
  top: 113px;
}

.Refer-m .element {
  width: 325px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-m .div-wrapper {
  background-color: #8870d0;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.Refer-m .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 9px;
  height: 16px;
  font-family: Nimbus Sans-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 9px;
  left: 11px;
}

.Refer-m .text-wrapper-9 {
  color: #000;
  letter-spacing: 0;
  width: 280px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 56px;
}

.Refer-m .element-2 {
  width: 325px;
  height: 32px;
  position: absolute;
  top: 50px;
  left: 0;
}

.Refer-m .text-wrapper-10 {
  color: #000;
  letter-spacing: 0;
  width: 280px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 56px;
}

.Refer-m .element-3 {
  width: 325px;
  height: 32px;
  position: absolute;
  top: 100px;
  left: 0;
}

.Refer-m .element-4 {
  width: 325px;
  height: 32px;
  position: absolute;
  top: 150px;
  left: 0;
}

.Refer-m .element-5 {
  width: 325px;
  height: 34px;
  position: absolute;
  top: 210px;
  left: 0;
}

.Refer-m .element-6 {
  background-color: #8870d0;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 1px;
  left: 0;
}

.Refer-m .your-friend-has-to {
  color: #000;
  letter-spacing: 0;
  width: 280px;
  height: 34px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 56px;
}

.Refer-m .element-7 {
  width: 325px;
  height: 36px;
  position: absolute;
  top: 276px;
  left: 0;
}

.Refer-m .text-wrapper-11 {
  color: #000;
  letter-spacing: 0;
  width: 280px;
  height: 36px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 56px;
}

.Refer-m .element-8 {
  width: 280px;
  height: 32px;
  position: absolute;
  top: 333px;
  left: 0;
}

.Refer-m .button-2 {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(90deg, #009bff, #1ddeb8, #00ffae, #009bff) 0 0 / 200% 200%;
  border-radius: 8px;
  width: 251.36px;
  height: 56px;
  animation: 4s linear infinite flow;
  position: relative;
  top: 204px;
}

.Refer-m .button-2:hover, .Refer-m .button-2.clicked {
  transform: scale(1.2);
}

.Refer-m .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 156px;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 15px;
  left: 48px;
}

.Refer-m .rules {
  width: 325px;
  height: 468px;
  margin-bottom: 240px;
  position: relative;
  top: 200px;
}

.Refer-m .text-wrapper-13 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 61px;
  height: 32px;
  font-family: Titillium Web, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.Refer-m .frame {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 44px;
}

.Refer-m .background {
  background-color: #f2f2f2;
  border-radius: 12px;
  width: 245.33px;
  height: 140px;
  position: relative;
}

.Refer-m .the-person-you-refer {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 192px;
  height: 80px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 12px;
  left: 27px;
}

.Refer-m .your-referred-friend {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  height: 80px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 12px;
  left: 18px;
}

.Refer-m .the-you-and-your {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 192px;
  height: 60px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 14px;
  left: 27px;
}

.Refer-m .read-the-full-t-s-c {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  height: 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.Refer-m .text-wrapper-14 {
  color: #000;
}

.Refer-m .text-wrapper-15 {
  color: #2482c5;
  text-decoration: underline;
}

.Refer-m .footer {
  background-color: #0b2738;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
}

.Refer-m .group {
  width: 100%;
  height: 64px;
  margin-right: -.38px;
  position: relative;
}

.Refer-m .frame-2 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.Refer-m .frame-3 {
  flex-direction: column;
  flex: none;
  gap: 14px;
  display: inline-flex;
  position: relative;
}

.Refer-m .logo-2 {
  width: 236.88px;
  height: 25.31px;
  position: relative;
}

.Refer-m .overlap-group-3 {
  background-image: url("vector-4r.950e116b.svg");
  background-size: 100% 100%;
  width: 56px;
  height: 55px;
  position: absolute;
  top: -4px;
  left: -31px;
}

.Refer-m .vector-3 {
  width: 14px;
  height: 17px;
  position: absolute;
  top: 4px;
  left: 2px;
}

.Refer-m .vector-4 {
  width: 4px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.Refer-m .vector-5 {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 7px;
  left: 15px;
}

.Refer-m .REFER-EARN-2 {
  color: #1ddeb8;
  letter-spacing: 0;
  white-space: nowrap;
  width: 205px;
  height: 18px;
  font-family: Titillium Web, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 3px;
  left: 30px;
}

.Refer-m .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
  top: 10px;
  right: -71px;
}

.Refer-m .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 157.01px;
  display: flex;
  position: relative;
  right: -43px;
}

.Refer-m .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.Refer-m .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.Refer-m .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 99.05px;
  display: flex;
  position: relative;
  right: -15px;
}

.Refer-m .frame-7 {
  flex: none;
  height: 24px;
  position: absolute;
  top: 212px;
  right: 44px;
}

.Refer-m .frame-8 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 135px;
  display: flex;
  position: relative;
}

.Refer-m .fantasy-go-pty-ltd {
  color: #999;
  letter-spacing: 0;
  width: 213.01px;
  height: 135px;
  margin-top: -1px;
  padding-right: 5px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  top: -50px;
  left: -24px;
}

.Refer-m .frame-9 {
  flex-flow: row-reverse;
  align-items: flex-end;
  gap: 31px 8px;
  display: flex;
  position: relative;
  top: 131px;
  left: -122px;
}

.Refer-m .DISCLAIMER {
  color: #999;
  letter-spacing: 0;
  width: 325px;
  height: 45px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.Refer-m .link {
  width: 94.95px;
  height: 15px;
  position: relative;
}

.Refer-m .national-responsible {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 0;
}

.Refer-m .national-gambling-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 102px;
  height: 15px;
  position: relative;
}

.Refer-m .national-gambling {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-m .financial-wrapper {
  border-color: #e5e7eb;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.13px;
  height: 15px;
  position: relative;
}

.Refer-m .financial {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-m .fantasygo-self-wrapper {
  border-color: #e5e7eb;
  border-left-width: 1px;
  width: 100.45px;
  height: 15px;
  position: relative;
}

.Refer-m .fantasygo-self {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 9px;
}

.Refer-m .highlight {
  animation: 2s infinite flash;
}

@keyframes flash {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px #0080ff;
  }

  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px #00ffae;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 5px #00ff2f;
  }
}

.Article1-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article1-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article1-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article1-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article1-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article1-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article1-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article1-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article2-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article2-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article2-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article2-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article2-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article2-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article2-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article2-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article3-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article3-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article3-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article3-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article3-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article3-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article3-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article3-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article4-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article4-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article4-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article4-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article4-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article4-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article4-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article4-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article5-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article5-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article5-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article5-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article5-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article5-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article5-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article5-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article6-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article6-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article6-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article6-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article6-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article6-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article6-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article6-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article7-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article7-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article7-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article7-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article7-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article7-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article7-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article7-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article8-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article8-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article8-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article8-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article8-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article8-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article8-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article8-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article9-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article9-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article9-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article9-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article9-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article9-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article9-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article9-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

.Article10-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article10-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article10-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article10-d .article-image {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.Article10-d .article-title {
  color: #333;
  margin: 20px 0 10px;
  font-size: 2.5em;
}

.Article10-d .article-meta {
  color: #666;
  font-size: .9em;
}

.article-date, .article-author {
  margin-right: 10px;
}

.Article10-d .article-content {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

.Article10-d .highlighted-text {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

/*# sourceMappingURL=index.5d936501.css.map */
