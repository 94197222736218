.menu-d {
  align-items: center;
  background-color: #ffffff00;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 9999; /* Adjust the value as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.BG-elements-d {
  height: 800px;
  left: calc(50% - 640px);
  position: absolute;
  top: 0;
  width: 1280px;
}

.NAV-d {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-around;
  padding: 31px 50px 15px;
  position: relative;
  width: 100%;
}

.nav-bar-d {
  align-items: flex-start;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 17.5px 0px 17.5px;
  position: relative;
  width: 1179px;
}

.xion-logo-d {
  height: 30.71px;
  position: relative;
  cursor: pointer;
}

.menu-buttons-d {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.GS-BUTTON-d {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 226.5px;
  cursor: pointer;
}

.GS-button-d { 
  height: 39px;
  margin-right: -2px;
  position: relative;
  width: 228.5px;
}

.div-d {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.shadows-d {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 226px;
}

.rectangle-2-d {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 226px;
}

.get-started-d {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 38px;
  left: 0;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 226px;
}

.get-started-d:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.div-2-d {
  height: 39px;
  position: relative;
  width: 45px;
}

.shadows-2-d {
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.img-d {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.rectangle-3-d {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  border: 1px solid;
  border-color: #14a5ed;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.group-d {
  height: 19px;
  left: 13px;
  position: absolute;
  top: 9px;
  width: 19px;
  cursor: pointer;
}

.menu-section-d {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 27px;
  margin-top: -49px;
  padding: 119px 130px 0px;
  position: relative;
  width: 100%;
}

.head-line-d {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 9px;
  position: relative;
  width: 1020px;
}

.text-wrapper-d {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 40px;
  font-weight: 100;
  height: 58px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 107px;
}

.text-wrapper-2-d {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 340px;
  height: 45px;
  margin-top: -1px;
  font-family: Oswald, Helvetica;
  font-size: 35px;
  font-weight: 500;
  line-height: 59.2px;
  position: relative;
  -webkit-background-clip: text !important;
}

.blue-line-d {
  height: 4px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
  width: 1024px;
}

.frame-d {
  align-items: flex-start;
  align-self: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 268px;
  position: relative;
}

.ONE-CLICK-PAYMENT-d {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 21px;
  justify-content: flex-end;
  position: relative;
  width: 389.6px;
}

.text-wrapper-3-d {
  align-self: stretch;
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.frame-2-d {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 23px 23px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.b-d {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 147.31px;
  padding: 0px 4.87e-9px 0px 0.91px;
  position: relative;
  width: auto;
}

.img-2-d {
  display: block;
  height: 111.59px;
  object-fit: cover;
  position: relative;
  transition: all 0.2s ease;
  width: 181.04px;
}

.text-wrapper-4-d {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 13px;
  font-weight: 100;
  height: 19.89px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: auto;
}

.b-2-d {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 149.85px;
  justify-content: flex-end;
  position: relative;
  width: auto;
}

.text-wrapper-5-d {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 13px;
  font-weight: 100;
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 181px;
}

.b-3-d {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 146.44px;
  justify-content: flex-end;
  position: relative;
  width: auto;
}

.frame-3-d {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.div-3-d {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  padding: 0px 20px;
  position: relative;
}

.text-wrapper-6-d {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.text-wrapper-7-d {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.text-wrapper-7-d:hover {
  color: #14a5ed;; /* Change to the color of your choosing on hover */
}

.follow-us-d {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 6px 20px;
  padding: 0px 20px;
  position: relative;
  width: 106px;
}

.text-wrapper-8-d {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -13px;
  margin-top: -1px;
  position: relative;
  width: 79px;
}

.frame-4-d {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 12px;
  margin-right: -13px;
  position: relative;
  width: 79px;
}

.telegram-instance-d {
  height: 29.03px !important;
  left: unset !important;
  margin-left: -0.74px !important;
  margin-top: -0.74px !important;
  position: relative !important;
  top: unset !important;
  width: 29.03px !important;
  cursor: pointer;
}

.twitter-instance-d {
  height: 27.55px !important;
  left: unset !important;
  top: unset !important;
  cursor: pointer;
}

.design-component-instance-node-d {
  width: 28px !important;
}

.medium-instance-d {
  height: 29.46px !important;
  left: unset !important;
  margin-bottom: -0.73px !important;
  margin-left: -0.74px !important;
  position: relative !important;
  top: unset !important;
  width: 29.47px !important;
  cursor: pointer;
}

.linkedin-instance-d {
  height: 29.46px !important;
  left: unset !important;
  margin-bottom: -0.73px !important;
  position: relative !important;
  top: unset !important;
  width: 29.47px !important;
  cursor: pointer;
}
