.GS-button-d {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
  cursor: pointer;
}

.GS-button-d .get-started {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 0;
}

.GS-button-d.property-1-0-default {
  background-color: #14a5ed;
}

.GS-button-d.property-1-0-variant-2 {
  background-color: #7f64fb;
}
