.Loyalty-i {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 3850px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Loyalty-i .BG-elements {
  height: 3200px;
  align-self: center;
  position: absolute;
  top: 0;
  width: 768px;
}

.Loyalty-i .overlap-4 {
  height: 3524px;
  position: relative;
}

.Loyalty-i .footer {
  height: 275px;
  left: 29px;
  position: absolute;
  top: 3163px;
  width: 709px;
}

.Loyalty-i .start {
  height: 289px;
  left: 29px;
  position: absolute;
  top: 2525px;
  width: 709px;
}

.Loyalty-i .overlap-group-5 {
  height: 638px;
  position: relative;
}

.Loyalty-i .rectangle-8 {
  height: 426px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 709px;
}

.Loyalty-i .gradient {
  height: 200px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.Loyalty-i .if-container {
  width: 768px;
  position: relative;
  top: 125px;
}

.Loyalty-i .mouse {
  height: 428px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 768px;
}

.Loyalty-i .hero {
  height: 607px;
  position: absolute;
  top: 120px;
  width: 768px;
}

.Loyalty-i .overlap-5 {
  height: 598px;
  position: relative;
}

.Loyalty-i .rectangle-9 {
  object-fit: cover;
  width: 709px;
  position: absolute;
  top: 0;
  left: 35px;
}

.Loyalty-i .gradient-2 {
  height: 300px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 709px;
}

.Loyalty-i .noize {
  height: 3524px;
  left: 0;
  position: absolute;
  top: 0;
  width: 768px;
}

.Loyalty-i .nav-spacer {
  height: 151px;
  margin-top: -43px;
  position: relative;
  width: 768px;
}

.Loyalty-i .main {
  height: 574px;
  position: relative;
  width: 768px;
}

.Loyalty-i .overlap-6 {
  height: 654px;
  left: 137px;
  position: relative;
  top: 14px;
  width: 768px;
}

.Loyalty-i .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 82px;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: -65px;
}

.Loyalty-i .HT {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100px;
  position: relative;
  width: 611px;
}

.Loyalty-i .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 386px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 33px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 10px;
}

.Loyalty-i .FREE-SIMPLE {
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent;
background: linear-gradient(
  90deg,
  rgb(26, 219, 187) 0%,
  rgb(20, 165, 237) 21.88%,
  rgb(127, 100, 251) 41.67%,
  rgb(243, 50, 101) 61.98%,
  rgb(255, 175, 132) 81.25%,
  rgb(255, 118, 108) 100%
);
background-clip: text;
color: transparent;
font-family: "Oswald", Helvetica;
font-size: 33px;
font-weight: 500;
letter-spacing: 0;
line-height: normal;
margin-top: -2px;
position: relative;
text-fill-color: transparent;
width: 383.28px;
}

.Loyalty-i .payment-solution-you {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 33px;
  font-weight: 100;
  height: 97px;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: -36px;
  position: relative;
  width: 386px;
}

.Loyalty-i .payment-solution-you-2 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 33px;
  font-weight: 100;
  height: 97px;
  letter-spacing: 0;
  line-height: 33px;
  position: relative;
  width: 386px;
  top: 2px;
}

.Loyalty-i .p {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 74px;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-bottom: -10px;
  position: relative;
  width: 386px;
}

.Loyalty-i .element-of {  
  object-fit: cover;
  width: 438px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 245px;
} 

.Loyalty-i .section {
  align-self: center;
  width: 768px;
  height: 1785px;
  position: relative;
  top: -150px;
}

.Loyalty-i .TEXT-BLOCK {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 315px;
  height: 1582px;
  display: flex;
  position: absolute;
  top: 100px;
  left: 75px;
  z-index: 5;
}

.Loyalty-i .TEXT {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.Loyalty-i .text-wrapper-119 {
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  color: #14a5ed;
  font-weight: 700;
}

.Loyalty-i .frame-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 315px;
}

.Loyalty-i .component-4 {
  width: 346px;
  height: 176px;
}

.Loyalty-i .text-wrapper-15 {
  color: #7f64fb;
  font-family: "Titillium Web", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 20.4px;
  margin-top: -1px;
  position: relative;
  width: 315px;
}

.Loyalty-i .text-wrapper-16 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  position: relative;
  width: 315px;
}

.Loyalty-i .text-wrapper-17 {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 315px;
}

.Loyalty-i .line-3 {
  height: 53.17px;
  margin-left: -2.67px;
  object-fit: cover;
  position: relative;
  width: 5.33px;
}

.Loyalty-i .frame-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 90px;
  left: 344px;
  z-index: 5;
}

.Loyalty-i .frame-49 {
  left: 0 !important;
  position: absolute !important;
  top: 66px !important;
}

.Loyalty-i .frame-5 {
  background-size: 100% 100%;
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 250px;
}

.Loyalty-i .text-wrapper-18 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  left: 15px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 11px;
}

.Loyalty-i .group-3 {
  width: 250px;
  height: 117px;
  position: absolute;
  top: 11px;
}

.Loyalty-i .group-9 {
  z-index: 1;
  width: 446px;
  height: 176px;
  position: relative;
  top: 428px;
  left: 365px;
  overflow: hidden;
}

.Loyalty-i .group-2 {
  width: 446px;
  height: 166px;
  position: absolute;
  top: 11px;
}

.Loyalty-i .overlap-wrapper {
  width: 446px;
  height: 500px;
  position: absolute;
  top: 830px;
  left: 370px;
  overflow: hidden;
}

.Loyalty-i .overlap-7 {
  width: 446px;
  height: 176px;
  position: relative;
  top: -2px;
  left: 1px;
}

.Loyalty-i .asset {
  height: 119px;
  left: 11px;
  position: absolute;
  top: 24px;
  width: 382px;
}

.Loyalty-i .clip-path-group {
  height: 98px;
  left: 21px;
  position: absolute;
  top: 34px;
  width: 356px;
}

.Loyalty-i .design-component-instance-node {
  border-radius: 100px !important;
  height: 118px !important;
  left: 143px !important;
  mix-blend-mode: overlay !important;
  position: absolute !important;
  top: 24px !important;
  transform: rotate(-129.01deg) !important;
  width: 118px !important;
}

.Loyalty-i .frame-lock {
  width: 346px;
  height: 176px;
  position: absolute;
  top: 1630px;
  left: 410px;
}

.Loyalty-i .WEB {
  color: #1185c3;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 83px;
  font-weight: 400;
  height: 80px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000026;
  top: -1px;
  white-space: nowrap;
  width: 117px;
}

.Loyalty-i .frame-36 {
  left: 121px !important;
  position: absolute !important;
  top: 0 !important;
}

.Loyalty-i .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 2050px;
  left: 360px;
}

.Loyalty-i .component-instance { 
  position: absolute !important;
  top: 1250px !important;
  left: 410px !important;
}

.Loyalty-i .section-2 {
  align-self: center;
  width: 768px;
  height: 300px;
  position: relative;
  top: 425px;
}

.Loyalty-i .TEXT-wrapper {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 75px;
  position: absolute;
  top: 140px;
  width: 315px;
}

.Loyalty-i .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.Loyalty-i .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-right: -178px;
  margin-top: -1px;
  position: relative;
  width: 493px;
}

.Loyalty-i .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.Loyalty-i .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 315px;
  z-index: 9;
}

.Loyalty-i .text-wrapper-19 {
  color: #272936;
}

.Loyalty-i .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.Loyalty-i .frame-7 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 409px;
  position: absolute;
  top: 140px;
  width: 284px;
}

.Loyalty-i .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: -209px;
  position: relative;
  width: 493px;
}

.Loyalty-i .line-4 {
  height: 1px;
  left: 75px;
  position: absolute;
  top: 74px;
  width: 618px;
}

.Loyalty-i .overlap-8 {
  height: 154px;
  left: 137px;
  position: absolute;
  top: 145px;
  width: 323px;
}

.Loyalty-i .text-wrapper-21 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 66px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 224px;
}

.Loyalty-i .text-wrapper-22 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 66px;
  font-weight: 500;
  height: 79px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 75px;
  white-space: nowrap;
  width: 323px;
}

.Loyalty-i .footer-section {
  height: 344px;
  position: relative; 
  width: 768px;
}

.Loyalty-i .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 307px;
  left: 144px;
  position: relative;
  top: 41px;
}

.Loyalty-i .XG-logo {
  height: 30.71px;
  position: relative;
  width: 156.98px;
}

.Loyalty-i .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  margin-right: -0.98px;
  position: relative;
}

.Loyalty-i .frame-wrapper {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.Loyalty-i .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.Loyalty-i .get-started-wrapper {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.Loyalty-i .get-started-3 {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.Loyalty-i .div-7 {
  height: 39px;
  position: relative;
  width: 45px;
}

.Loyalty-i .shadows {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.Loyalty-i .shadows-2 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.Loyalty-i .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.Loyalty-i .group-4 {
  height: 18px;
  left: 13px;
  position: absolute;
  top: 10px;
  width: 21px;
}
