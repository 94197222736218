.contact-m {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 2110px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contact-m .BG-elements {
  height: 2926px;
  align-self: center;
  position: absolute;
  top: 0px;
  width: 379px;
}

.contact-m .overlap-4 {
  height: 2925px;
  left: 14px;
  position: relative;
  top: 1px;
  width: 360px;
}

.contact-m .footer {
  height: 195px;
  left: 17px;
  position: absolute;
  top: 2730px;
  width: 327px;
}

.contact-m .start {
  height: 200px;
  left: 15px;
  position: absolute;
  top: 2530px;
  width: 327px;
}

.contact-m .mf-container {
  width: 360px;
  top: 120px;
  position: relative;
}

.contact-m .overlap-group-5 {
  height: 638px;
  position: relative;
}

.contact-m .rectangle-8 {
  height: 426px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 709px;
}

.contact-m .gradient {
  height: 198px;
  left: 0;
  position: absolute;
  top: 0;
  width: 327px;
}

.contact-m .hero {
  height: 795px;
  left: 17px;
  position: absolute;
  top: 120px;
  width: 327px;
}

.contact-m .overlap-5 {
  height: 435px;
  position: relative;
}

.contact-m .rectangle-9 {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 327px;
}

.contact-m .gradient-2 {
  height: 435px;
  left: 0;
  position: absolute;
  top: 0;
  width: 327px;
}

.contact-m .noize {
  height: 2925px;
  left: 0;
  position: absolute;
  top: 0;
  width: 358px;
}

.contact-m .nav-spacer {
  height: 175px;
  margin-top: -43px;
  position: relative;
  width: 360px;
}

.contact-m .ABOUT-US {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -6.85px;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
  width: 289px;
  font-family: Oswald, Helvetica;
  font-size: 75px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 75px;
  left: 45px;
  -webkit-background-clip: text !important;
}

.contact-m .div {
  height: 232px;
  position: relative;
}

.contact-m .rectangle {
  height: 230px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 327px;
}

.contact-m .img {
  height: 232px;
  left: 0;
  position: absolute;
  top: 0;
  width: 327px;
}

.contact-m .noize {
  height: 1911px;
  left: 0;
  position: absolute;
  top: 0;
  width: 358px;
}

.contact-m .nav-spacer {
  height: 175px;
  margin-top: -43px;
  position: relative;
  width: 360px;
}

.contact-m .main {
  height: 220px;
  margin-top: -43px;
  position: relative;
  width: 360px;
}

.contact-m .FREE-SIMPLE {
  height: 48px;
  left: 57px;
  position: absolute;
  top: 79px;
  width: 250px;
}

.contact-m .section {
  align-self: center;
  width: 360px;
  height: 884px;
  position: relative;
}

.contact-m .group {
  width: 350px;
  height: 485px;
  position: absolute;
  top: 425px;
  left: 12px;
}

.contact-m .overlap-2 {
  height: 485px;
  position: relative;
}

.contact-m .rectangle-2 {
  background-color: #170d5333;
  border-radius: 23px;
  filter: blur(43px);
  height: 468px;
  left: 13px;
  opacity: 0.5;
  position: absolute;
  top: 17px;
  width: 337px;
}

.contact-m .rectangle-3 {
  background-color: #ededed;
  border-radius: 24px;
  height: 464px;
  left: 0;
  position: absolute;
  top: 0;
  width: 337px;
}

.contact-m .contact-block {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 396px;
  left: 18px;
  position: absolute;
  top: 18px;
  width: 303px;
}

.contact-m .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.contact-m .div-2 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 33px;
  letter-spacing: -0.4px;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  width: 103px;
}

.contact-m .rectangle-4 {
  background-color: #e5e4e4;
  border-radius: 6px;
  height: 33px;
  position: relative;
  width: 200px;
}

.contact-m .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  height: 84px;
  justify-content: center;
  position: relative;
}

.contact-m .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 315px;
}

.contact-m .text-wrapper {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 19.2px;
}

.contact-m .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.contact-m .rectangle-5 {
  background-color: #e5e3e3;
  border-radius: 6px;
  height: 84px;
  position: relative;
  width: 200px;
}

.contact-m .read-button {
  all: unset;
  box-sizing: border-box;
  height: 28px;
  left: 144px;
  position: absolute;
  top: 359px;
  width: 161px;
  cursor: pointer;
}

.contact-m .read-the-docs-wrapper {
  background-color: #7f64fb;
  border-radius: 6px;
  height: 28px;
  position: relative;
  width: 159px;
}

.contact-m .read-the-docs {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 151px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 4px;
}
 
.contact-m .TEXT-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 70px;
  justify-content: center;
  left: 23px;
  position: absolute;
  top: 66px;
  width: 315px;
}

.contact-m .TEXT {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 34px 0px;
  position: relative;
  width: 259px;
}

.contact-m .text-wrapper-2 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 259px;
}

.contact-m .our-team-can-help {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  text-align: center;
  width: 259px;
}

.contact-m .our-team-can-help li {
  margin-bottom: 10px; /* Adjust this value to increase or decrease the spacing */
}

.contact-m .our-team-can-help .custom-margin {
  margin-top: 25px; /* Adjust this value to increase or decrease the spacing */
}

.contact-m .section-2 {
  align-self: center;
  width: 360px;
  height: 356px;
  position: relative;
  top: 100px;
}

.contact-m .div-wrapper {
  align-items: center;
  display: flex;
  gap: 70px;
  justify-content: center;
  left: 23px;
  position: absolute;
  top: 110px;
  width: 315px;
}

.contact-m .TEXT-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.contact-m .xion-docs {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 314px;
}

.contact-m .text-wrapper-3 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.contact-m .explore-guides-and {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  text-align: center;
  width: 314px;
}

.contact-m .text-wrapper-4 {
  color: #272936;
}

.contact-m .text-wrapper-5 {
  color: #14a5ed;
}

.contact-m .frame-3 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 38px;
  position: absolute;
  top: 220px;
  width: 284px;
}

.contact-m .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 284px;
}

.contact-m .technical-and {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 284px;
}

.contact-m .we-re-here-to-help {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  text-align: center;
  width: 284px;
}

.contact-m .line {
  height: 1px;
  left: 17px;
  position: absolute;
  top: 74px;
  width: 326px;
}

.contact-m .start-section {
  height: 252px;
  margin-top: -43px;
  position: relative;
  width: 356px;
}

.contact-m .overlap-3 {
  height: 238px;
  left: 30px;
  position: relative;
  top: 25px;
  width: 328px;
}

.contact-m .phone-image {
  height: 238px;
  left: 85px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 243px;
}

.contact-m .text-wrapper-6 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 30px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 122px;
  width: 87px;
}

.contact-m .text-wrapper-7 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 28px;
  font-weight: 500;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 153px;
  width: 140px;
}

.contact-m .footer-section {
  height: 196px;
  margin-top: -43px;
  position: relative;
  width: 298px;
}

.contact-m .frame-4 {
  align-items: flex-start;
  display: flex;
  gap: 56px;
  left: 3px;
  position: relative;
  top: 95px;
  width: 293px;
}

.contact-m .XG-logo {
  height: 21.89px;
  position: relative;
  width: 107px;
}

.contact-m .follow-us {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  position: relative;
}

.contact-m .follow-us-2 {
  height: 92.8px;
  margin-right: -6px;
  position: relative;
  width: 136px;
}

.contact-m .overlap-group-2 {
  height: 17px;
  left: 69px;
  position: absolute;
  top: 75px;
  width: 61px;
}

.contact-m .text-wrapper-8 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 0;
}

.contact-m .all-rights-reserved {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 13px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 8px;
}

.contact-m .text-wrapper-9 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 11px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 30px;
  width: 130px;
}

.contact-m .social {
  align-items: flex-start;
  display: flex;
  gap: 16.5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 130px;
}

.contact-m .telegram-footer {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-left: -0.5px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.contact-m .twitter-footer {
  height: 21px !important;
  margin-bottom: -0.5px !important;
  margin-top: -0.5px !important;
  position: relative !important;
  width: 21px !important;
}

.contact-m .linkedin-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -0.51px !important;
  margin-bottom: -0.49px !important;
  margin-right: -0.5px !important;
  position: relative !important;
}

.contact-m .medium-footer {
  width: 21px !important;
  height: 21px !important;
  margin-top: -0.51px !important;
  margin-bottom: -0.49px !important;
  position: relative !important;
}

.contact-m .icon-instance-node {
  height: 21px !important;
  margin-bottom: -0.49px !important;
  margin-top: -0.51px !important;
  position: relative !important;
  width: 21px !important;
}

.contact-m .NAV {
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 360px;
}

.contact-m .frame-wrapper {
  background-color: #f3f3f3;
  height: 120px;
  width: 359px;
}

.contact-m .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  gap: 80px;
  left: 19px;
  position: relative;
  top: 53px;
}

.contact-m .xion-logo {
  height: 30.71px;
  position: relative;
  width: 25.4px;
}

.contact-m .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.contact-m .GS-button {
  all: unset;
  box-sizing: border-box;
  height: 30px;
  position: relative;
  width: 176px;
}

.contact-m .overlap-group-3 {
  background-size: 100% 100%;
  height: 30px;
  position: relative;
  width: 174px;
}

.contact-m .rectangle-6 {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 6px;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 174px;
}

.contact-m .get-started {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 30px;
  left: 0;
  letter-spacing: 2.34px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 174px;
}

.contact-m .burger {
  height: 48.53px;
  margin-bottom: -9.81px;
  margin-right: -9px;
  margin-top: -8.72px;
  position: relative;
  width: 52px;
}

.contact-m .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  text-align: center;
  width: 315px;
  z-index: 9;
}

.contact-m .text-wrapper-19 {
  color: #272936;
}

.contact-m .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.contact-m .frame-7 {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 70px;
  left: 23px;
  position: absolute;
  top: 220px;
  width: 315px;
}

.contact-m .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 315px;
}

.contact-m .line-4 {
  height: 1px;
  left: 163px;
  position: absolute;
  top: 74px;
  width: 948px;
}

.contact-m .overlap-8 {
  height: 154px;
  left: 137px;
  position: absolute;
  top: 145px;
  width: 323px;
}

.contact-m .text-wrapper-21 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 66px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 224px;
}

.contact-m .text-wrapper-22 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 66px;
  font-weight: 500;
  height: 79px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 75px;
  white-space: nowrap;
  width: 323px;
}
