.PWC-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  height: 4555px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PWC-d .BG-elements {
  height: 3374px;
  align-self: center;
  position: absolute;
  top: 0;
  width: 1280px;
}

.PWC-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.PWC-d .footer {
  height: 315px;
  left: 50px;
  position: absolute;
  top: 3163px;
  width: 1179px;
}

.PWC-d .start {
  height: 638px;
  left: 50px;
  position: absolute;
  top: 2525px;
  width: 1179px;
}

.PWC-d .overlap-group-5 {
  height: 638px;
  position: relative;
}

.PWC-d .rectangle-8 {
  height: 426px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 1179px;
}

.PWC-d .gradient {
  height: 638px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1179px;
}

.PWC-d .mouse {
  height: 428px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 1179px;
}

.PWC-d .hero {
  height: 607px;
  left: 50px;
  position: absolute;
  top: 120px;
  width: 1180px;
}

.PWC-d .GS-button-3 {
  cursor: pointer;
  z-index: 3;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color 0.3s;
  display: flex;
  position: absolute !important;
  top: 480px !important;
}

.GS-button-3:hover {
  background-color: #7f64fb; /* Hover background color */
}

.GS-button-3-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-5px); /* Move left */
  }
  20%,
  80% {
    transform: translateX(5px); /* Move right */
  }
  30%,
  50%,
  70% {
    transform: translateX(-3px); /* Move left */
  }
  40%,
  60% {
    transform: translateX(3px); /* Move right */
  }
}

.PWC-d .GS-button-3-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-d .overlap-5 {
  height: 598px;
  position: relative;
}

.PWC-d .rectangle-9 {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1179px;
}

.PWC-d .gradient-2 {
  height: 500px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1179px;
}

.PWC-d .noize {
  height: 3524px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1280px;
}

.PWC-d .nav-spacer {
  height: 151px;
  margin-top: -43px;
  position: relative;
  width: 1280px;
}

.PWC-d .main {
  height: 574px;
  margin-top: -43px;
  position: relative;
  width: 1280px;
}

.PWC-d .overlap-6 {
  height: 654px;
  left: 137px;
  position: relative;
  top: 14px;
  width: 1143px;
}

.PWC-d .text-block {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 130px;
  left: 0;
}

.PWC-d .HT {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 200px;
  position: relative;
  width: 611px;
}

.PWC-d .a-SIMPLE {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
  top: 20px;
}

.PWC-d .FREE-SIMPLE {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 57px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  text-fill-color: transparent;
  width: 650px;
}

.PWC-d .payment-solution-you {
  color: #fff;
  letter-spacing: 0;
  width: 611px;
  height: 65px;
  margin-top: -20px;
  font-family: Titillium Web, Helvetica;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: relative;
}

.PWC-d .p {
  color: #fff;
  letter-spacing: 0;
  width: 585px;
  height: 104px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
  margin-top: -40px;
}

.PWC-d .element-of {
  height: 654px;
  left: 543px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 600px;
}

.PWC-d .section {
  align-self: center;
  height: 2500px;
  position: relative;
  width: 1280px;
}

.PWC-d .TEXT-BLOCK {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 35px;
  height: 1525px;
  left: 163px;
  position: absolute;
  top: 207px;
}

.PWC-d .TEXT {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 493px;
}

.PWC-d .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.PWC-d .component-4 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1005px;
  left: 750px;
}

.PWC-d .text-wrapper-15 {
  color: #7f64fb;
  font-family: "Titillium Web", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 20.4px;
  margin-top: -1px;
  position: relative;
  width: 491px;
}

.PWC-d .text-wrapper-16 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  position: relative;
  width: 493px;
}

.PWC-d .text-wrapper-17 {
  color: #272936;
  letter-spacing: -0.35px;
  width: 504px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.PWC-d .line-3 {
  height: 53.17px;
  margin-left: -2.67px;
  object-fit: cover;
  position: relative;
  width: 5.33px;
}

.PWC-d .frame-4 {
  width: 448px;
  height: 177px;
  position: absolute;
  top: 265px;
  left: 605px;
}

.PWC-d .frame-49 {
  left: 0 !important;
  position: absolute !important;
  top: 66px !important;
}

.PWC-d .frame-5 {
  background-size: 100% 100%;
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 250px;
}

.PWC-d .text-wrapper-18 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  left: 15px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 11px;
}

.PWC-d .group-3 {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 11px;
}

.PWC-d .group-9 {
  width: 448px;
  left: 608px;
  position: absolute !important;
  top: 212px !important;
}

.PWC-d .group-9a {
  width: 200px;
  left: 842px;
  position: absolute !important;
  top: 369px !important;
  transition: transform 0.3s ease-in-out;
  z-index: 99;
}

.PWC-d .group-9a:hover {
  transform: scale(1.1);
}

.PWC-d .group-2 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 11px;
  left: -20px;
}

.PWC-d .group-9b {
  width: 180px;
  transition: transform 0.3s ease-in-out;
  left: 630px;
  position: absolute !important;
  top: 369px !important;
}

.PWC-d .group-9b:hover {
  transform: scale(1.1);
}

.PWC-d .referral-code {
  font-weight: bold;
  cursor: pointer;
  user-select: all; /* Makes it easier to copy */
}

.PWC-d .xvoucher-link {
  font-weight: bold;
  text-decoration: none; /* Optional: removes underline */
  color: inherit; /* Optional: keeps the text color consistent with surrounding text */
}

.PWC-d .xvoucher-link:hover {
  text-decoration: underline; /* Optional: adds underline on hover for better UX */
}

.PWC-d .appstore-link {
  font-weight: bold;
  text-decoration: none; /* Optional: removes underline */
  color: inherit; /* Optional: keeps the text color consistent with surrounding text */
}

.PWC-d .appstore-link:hover {
  text-decoration: underline; /* Optional: adds underline on hover for better UX */
}

.PWC-d .googlestore-link {
  font-weight: bold;
  text-decoration: none; /* Optional: removes underline */
  color: inherit; /* Optional: keeps the text color consistent with surrounding text */
}

.PWC-d .googlestore-link:hover {
  text-decoration: underline; /* Optional: adds underline on hover for better UX */
}

.PWC-d .overlap-wrapper {
  height: 176px;
  left: 760px;
  position: absolute;
  top: 726px;
  width: 446px;
}

.PWC-d .overlap-7 {
  width: 338px;
  position: relative;
  top: 310px;
  left: -84px;
}

.PWC-d .overlap-77 {
  width: 264px;
  height: 90px;
  position: absolute;
  top: 918px;
  left: 738px;
}

.PWC-d .asset {
  height: 119px;
  left: 11px;
  position: absolute;
  top: 24px;
  width: 382px;
}

.PWC-d .clip-path-group {
  height: 98px;
  left: 21px;
  position: absolute;
  top: 34px;
  width: 356px;
}

.PWC-d .frame-lock {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1340px;
  left: 752px;
}

.PWC-d .WEB {
  color: #1185c3;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 83px;
  font-weight: 400;
  height: 80px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000026;
  top: -1px;
  white-space: nowrap;
  width: 117px;
}

.PWC-d .frame-36 {
  left: 121px !important;
  position: absolute !important;
  top: 0 !important;
}

.PWC-d .component-3 {
  width: 446px;
  height: 176px;
  position: absolute;
  top: 1600px !important;
  left: 682px !important;
}

.PWC-d .GS-button {
  cursor: pointer;
  background-color: #1185c3;
  border: 0.5px solid #1185c3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 39px;
  padding: 10px 20px;
  transition: background-color 0.3s;
  display: flex;
  position: absolute !important;
  top: 1700px !important;
  left: 865px !important;
}

.GS-button:hover {
  background-color: #7f64fb; /* Hover background color */
}

.GS-button-text:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-5px); /* Move left */
  }
  20%,
  80% {
    transform: translateX(5px); /* Move right */
  }
  30%,
  50%,
  70% {
    transform: translateX(-3px); /* Move left */
  }
  40%,
  60% {
    transform: translateX(3px); /* Move right */
  }
}

.PWC-d .GS-button-text {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Opinion Pro Extended, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.PWC-d .component-instance {
  left: 846px;
  position: absolute;
  top: 1039px;
}

.PWC-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  margin-top: -219px;
  position: relative;
  top: 500px;
}

.PWC-d .TEXT-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 70px;
  left: 163px;
  position: absolute;
  top: 140px;
}

.PWC-d .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 493px;
}

.PWC-d .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1px;
  position: relative;
  width: 493px;
}

.PWC-d .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.PWC-d .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 491px;
  z-index: 9;
}

.PWC-d .text-wrapper-19 {
  color: #272936;
}

.PWC-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.PWC-d .frame-7 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 709px;
  position: absolute;
  top: 140px;
  width: 460px;
}

.PWC-d .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: -33px;
  position: relative;
  width: 493px;
}

.PWC-d .line-4 {
  height: 1px;
  left: 163px;
  position: absolute;
  top: 74px;
  width: 948px;
}

.PWC-d .overlap-8 {
  height: 154px;
  left: 137px;
  position: absolute;
  top: 145px;
  width: 323px;
}

.PWC-d .text-wrapper-21 {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 66px;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 224px;
}

.PWC-d .text-wrapper-22 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 66px;
  font-weight: 500;
  height: 79px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 75px;
  white-space: nowrap;
  width: 323px;
}

.PWC-d .footer-section {
  height: 344px;
  margin-top: -43px;
  position: relative;
  width: 1278px;
}

.PWC-d .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 307px;
  left: 144px;
  position: relative;
  top: 41px;
}

.PWC-d .XG-logo {
  height: 31.84px;
  position: relative;
  width: 155.66px;
}

.PWC-d .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  margin-right: -0.98px;
  position: relative;
}

.PWC-d .frame-wrapper {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.PWC-d .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 39px;
  overflow: hidden;
}

.PWC-d .get-started-wrapper {
  border: 1px solid;
  border-color: #439be4;
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.PWC-d .get-started-3 {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.PWC-d .div-7 {
  height: 39px;
  position: relative;
  width: 45px;
}

.PWC-d .shadows {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.PWC-d .shadows-2 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.PWC-d .rectangle-10 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(171, 47, 254),
      rgb(64, 175, 253) 96.88%
    )
    1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.PWC-d .group-4 {
  height: 18px;
  left: 13px;
  position: absolute;
  top: 10px;
  width: 21px;
}

.PWC-d .section-3s {
  width: 1280px;
  height: 876px;
  margin-top: -21px;
  position: relative;
}

.PWC-d .what-is-scan-to-pay {
  color: #0000;
  letter-spacing: 0;
  width: 963px;
  font-family: Titillium Web, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.7px;
  position: absolute;
  left: 165px;
}

.PWC-d .text-wrapper-9s {
  color: #14a5ed;
  font-weight: 700;
}

.PWC-d .text-wrapper-10s {
  color: #000000;
  font-weight: 100;
}

.PWC-d .FREE-SIMPLE-3s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    #1adbbb 0%,
    #14a5ed 21.88%,
    #7f64fb 41.67%,
    #f33265 61.98%,
    #ffaf84 81.25%,
    #ff766c 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  width: 137px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -100px;
  left: 165px;
  -webkit-background-clip: text !important;
}

.PWC-d .section-2s {
  width: 1280px;
  height: 592px;
  margin-top: 1415px;
  position: relative;
}

.PWC-d .overlap-2s {
  height: 285px;
  left: 145px;
  position: absolute;
  top: 180px;
  width: 1023px;
}

.PWC-d .text-wrapper-8s {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 20px;
  font-weight: 100;
  left: 20px;
  letter-spacing: 0;
  line-height: 22.7px;
  position: absolute;
  top: 254px;
  width: 963px;
  cursor: pointer;
}

.PWC-d .slides-navigation {
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 245px;
  left: 480px;
  cursor: pointer;
}

.PWC-d .click-area {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 8px;
  position: relative;
}

.PWC-d .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.PWC-d .slide-indicator {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.PWC-d .dot-indictaor {
  position: relative !important;
}

.PWC-d .imagetakealot {
  height: 251px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 359px;
}

.PWC-d .imageshell {
  height: 253px;
  left: 336px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 359px;
}

.PWC-d .imagesnapscan {
  height: 253px;
  left: 664px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 359px;
}

.PWC-d .FREE-SIMPLE-2s {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    #1adbbb 0%,
    #14a5ed 21.88%,
    #7f64fb 41.67%,
    #f33265 61.98%,
    #ffaf84 81.25%,
    #ff766c 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  width: 917px;
  font-family: Oswald, Helvetica;
  font-size: 60px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 160px;
  -webkit-background-clip: text !important;
}

.PWC-d .df-container {
  width: 1280px;
  position: relative;
  top: 250px;
}

.PWC-d .roadshow {
  object-fit: cover;
  width: 963px;
  height: 1297px;
  position: absolute;
  top: 550px;
  left: 165px;
}
