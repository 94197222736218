.contact-d {
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  height: 2500px;
  position: relative;
  overflow: hidden;
}

.contact-d .BG-elements {
  height: 3374px;
  align-self: center;
  position: absolute;
  top: 0;
  width: 1280px;
}

.contact-d .overlap-4 {
  height: 3524px;
  position: relative;
}

.contact-d .footer {
  height: 315px;
  left: 50px;
  position: absolute;
  top: 3163px;
  width: 1179px;
}

.contact-d .start {
  height: 638px;
  left: 50px;
  position: absolute;
  top: 2525px;
  width: 1179px;
}

.contact-d .gradient {
  height: 638px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1179px;
}

.contact-d .mouse {
  height: 428px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 1179px;
}

.contact-d .hero {
  height: 607px;
  left: 50px;
  position: absolute;
  top: 15px;
  width: 1180px;
}

.contact-d .overlap-5 {
  height: 598px;
  position: relative;
}

.contact-d .rectangle-9 {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1179px;
}

.contact-d .gradient-2 {
  height: 500px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1179px;
}

.contact-d .noize {
  height: 3524px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1280px;
}

.contact-d .nav-spacer {
  height: 151px;
  margin-top: -43px;
  position: relative;
  width: 1280px;
}

.contact-d .main {
  height: 611px;
  margin-top: -43px;
  position: relative;
  width: 1280px;
}

.contact-d .ABOUT-US {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgb(26, 219, 187) 0%,
    rgb(20, 165, 237) 21.88%,
    rgb(127, 100, 251) 41.67%,
    rgb(243, 50, 101) 61.98%,
    rgb(255, 175, 132) 81.25%,
    rgb(255, 118, 108) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Oswald", Helvetica;
  font-size: 201px;
  font-weight: 500;
  left: 133px;
  letter-spacing: -10.05px;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 182px;
  width: 817px;
}

.contact-d .section {
  align-self: center;
  width: 1280px;
  height: 670px;
  position: relative;
}

.contact-d .overlap-2 {
  height: 489px;
  left: 627px;
  position: absolute;
  top: 140px;
  width: 564px;
}

.contact-d .rectangle-2 {
  background-color: #170d5333;
  border-radius: 23px;
  filter: blur(43px);
  height: 471px;
  left: 21px;
  opacity: 0.5;
  position: absolute;
  top: 18px;
  width: 543px;
}

.contact-d .rectangle-3 {
  background-color: #ededed;
  border-radius: 24px;
  width: 543px;
  height: 468px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-d .contact-block {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 396px;
  left: 31px;
  position: absolute;
  top: 28px;
  width: 479px;
}

.contact-d .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 25px;
  margin-right: -2px;
  position: relative;
}

.contact-d .div-2 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 33px;
  letter-spacing: -0.4px;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  width: 125px;
}

.contact-d .rectangle-4 {
  background-color: #e5e4e4;
  border-radius: 6px;
  height: 33px;
  position: relative;
  width: 331px;
}

.contact-d .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  gap: 25px;
  height: 84px;
  margin-right: -2px;
  position: relative;
}

.contact-d .text-wrapper {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 19.2px;
}

.contact-d .span {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.contact-d .rectangle-5 {
  background-color: #e5e3e3;
  border-radius: 6px;
  height: 84px;
  position: relative;
  width: 331px;
}

.contact-d .read-button {
  all: unset;
  box-sizing: border-box;
  height: 28px;
  left: 322px;
  position: absolute;
  top: 369px;
  width: 161px;
  cursor: pointer;
}

.contact-d .read-the-docs-wrapper {
  background-color: #7f64fb;
  border-radius: 6px;
  height: 28px;
  position: relative;
  width: 159px;
}

.contact-d .read-the-docs {
  color: #e8e8e8;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 151px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 4px;
}

.contact-d .TEXT-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 70px;
  left: 163px;
  position: absolute;
  top: 140px;
}

.contact-d .TEXT {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 34px 0px;
  position: relative;
  width: 443px;
}

.contact-d .text-wrapper-2 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 31px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 35.2px;
  margin-top: -1px;
  position: relative;
  width: 443px;
}

.contact-d .our-team-can-help {
  color: #272936;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 402px;
}

.contact-d .our-team-can-help ul {
  list-style-type: disc;
  padding-left: 20px;
}

.contact-d .our-team-can-help li {
  margin-bottom: 10px; /* Adjust this value to increase or decrease the spacing */
}

.contact-d .our-team-can-help .custom-margin {
  margin-top: 25px; /* Adjust this value to increase or decrease the spacing */
}

.contact-d .section-2 {
  align-self: center;
  width: 1280px;
  height: 398px;
  position: relative;
  top: -43px;
}

.contact-d .TEXT-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 70px;
  left: 163px;
  position: absolute;
  top: 140px;
}

.contact-d .TEXTcomplaints {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 34px 0px;
  position: relative;
  width: 443px;
}

.contact-d .complaints-section {
  position: absolute;
  top: 400px;
  left: 163px;
}

.contact-d .complaints-text {
  color: #272936;
  letter-spacing: -0.35px;
  width: 372px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.8px;
  position: relative;
}

.contact-d .TEXT-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 493px;
}

.contact-d .div-4 {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1px;
  position: relative;
  width: 493px;
}

.contact-d .span {
  color: #000000;
  font-family: "Titillium Web", Helvetica;
  font-size: 27px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30.6px;
}

.contact-d .div-5 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 16.8px;
  position: relative;
  width: 491px;
  z-index: 9;
}

.contact-d .text-wrapper-19 {
  color: #272936;
}

.contact-d .text-wrapper-20 {
  color: #14a5ed;
  cursor: pointer;
}

.contact-d .frame-7 {
  align-items: center;
  display: flex;
  gap: 70px;
  left: 709px;
  position: absolute;
  top: 140px;
  width: 460px;
}

.contact-d .TEXT-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: -33px;
  position: relative;
  width: 493px;
}

.contact-d .line-4 {
  height: 1px;
  left: 163px;
  position: absolute;
  top: 74px;
  width: 948px;
}
