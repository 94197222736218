.Article4-d {
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Article4-d .article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Article4-d .article-header {
  text-align: center;
  margin-bottom: 20px;
}

.Article4-d .article-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.Article4-d .article-title {
  font-size: 2.5em;
  margin: 20px 0 10px;
  color: #333;
}

.Article4-d .article-meta {
  font-size: 0.9em;
  color: #666;
}

.article-date,
.article-author {
  margin-right: 10px;
}

.Article4-d .article-content {
  font-size: 1.1em;
  line-height: 1.6;
  color: #333;
}

.Article4-d .highlighted-text {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}
