.read-button-d {
  border-radius: 10px;
  height: 39px;
  left: 20px;
  position: relative;
  width: calc(100% - 20px);
  cursor: pointer;
  z-index: 10001;
}

.read-button-d .text-wrapper-2 {
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 226px;
  cursor: pointer;
  z-index: 1000;
}

.read-button-d.default {
  background-color: #7f64fb;
  top: 20px;
  cursor: pointer;
}

.read-button-d.variant-2 {
  border: 1px solid;
  border-color: #7f64fb;
  top: 79px;
  cursor: pointer;
}

.read-button-d.default .text-wrapper-2 {
  color: #ffffff;
  cursor: pointer;
}

.read-button-d.variant-2 .text-wrapper-2 {
  color: #7f64fb;
  cursor: pointer;
}
