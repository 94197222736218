.element-ipad-menu {
  align-items: center;
  background-color: #ffffff00;
  display: flex;
  flex-direction: column;
  height: auto;
  z-index: 9999; /* Adjust the value as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
}

.element-ipad-menu .BG-elements {
  height: 1024px;
  left: calc(50% - 384px);
  position: absolute;
  top: 0;
  width: 768px;
}

.element-ipad-menu .NAV {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-around;
  padding: 31px 50px 15px;
  position: relative;
  width: 100%;
}

.element-ipad-menu .nav-bar-imenu {
  align-items: flex-start;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 17.5px 0px 17.5px;
  position: relative;
  width: 708px;
}

.element-ipad-menu .xion-logo {
  height: 30.71px;
  position: relative;
  width: 156.98px;
  cursor: pointer;
}

.element-ipad-menu .menu-buttons {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.element-ipad-menu .GS-BUTTON {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 226.5px;
  cursor: pointer;
}

.element-ipad-menu .GS-button {
  height: 39px;
  margin-right: -2px;
  position: relative;
  width: 228.5px;
}

.element-ipad-menu .div {
  border-radius: 10px;
  height: 39px;
  position: relative;
  width: 226px;
}

.element-ipad-menu .shadows {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 226px;
}

.element-ipad-menu .rectangle-2 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(171, 47, 254), rgb(64, 175, 253) 96.88%) 1;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 226px;
}

.element-ipad-menu .get-started {
  color: #439be4;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 38px;
  left: 0;
  letter-spacing: 3.24px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 226px;
}

.element-ipad-menu .get-started:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.element-ipad-menu .div-2 {
  height: 39px;
  position: relative;
  width: 45px;
  cursor: pointer;
}

.element-ipad-menu .shadows-2 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  box-shadow: 3.27px 3.27px 6.54px #aeaec066, -2.18px -2.18px 6.54px #ffffff;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 45px;
}

.element-ipad-menu .img {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.element-ipad-menu .rectangle-3 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  border: 1px solid;
  border-color: #14a5ed;
  border-radius: 10px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.element-ipad-menu .group {
  height: 19px;
  left: 13px;
  position: absolute;
  top: 9px;
  width: 19px;
}

.element-ipad-menu .menu-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 27px;
  margin-top: -49px;
  padding: 119px 130px 0px;
  position: relative;
  width: 100%;
}

.element-ipad-menu .head-line {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 9px;
  margin-left: -44.5px;
  margin-right: -44.5px;
  position: relative;
  width: 597px;
}

.element-ipad-menu .text-wrapper {
  color: #e9edf3;
  font-family: "Titillium Web", Helvetica;
  font-size: 40px;
  font-weight: 100;
  height: 58px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 107px;
}

.element-ipad-menu .text-wrapper-2 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(90deg, #1adbbb 0%, #14a5ed 21.88%, #7f64fb 41.67%, #f33265 61.98%, #ffaf84 81.25%, #ff766c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 340px;
  height: 45px;
  margin-top: -1px;
  font-family: Oswald, Helvetica;
  font-size: 35px;
  font-weight: 500;
  line-height: 59.2px;
  position: relative;
  -webkit-background-clip: text !important;
}

.element-ipad-menu .blue-line {
  height: 4px;
  margin-left: -47.03px;
  margin-right: -47.03px;
  position: relative;
  width: 602.06px;
}

.element-ipad-menu .frame-imenu { 
  align-items: flex-start;
  align-self: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-left: -48.5px;
  margin-right: -48.5px;
  position: relative;
}

.element-ipad-menu .ONE-CLICK-PAYMENT {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 21px 21px;
  position: relative;
  width: 235px;
}

.element-ipad-menu .text-wrapper-3 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 235px;
}

.element-ipad-menu .frame-2 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
  gap: 23px;
  height: 482px;
  display: flex;
  position: relative;
  align-content: flex-start;
  flex-direction: column;
}

.element-ipad-menu .b {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 147.31px;
  padding: 0px 4.87e-9px 0px 0.91px;
  position: relative;
  width: 181.95px;
}

.element-ipad-menu .img-2 {
  height: 111.59px;
  object-fit: cover;
  position: relative;
  transition: all 0.2s ease;
  width: 181.04px;
}

.element-ipad-menu .text-wrapper-4 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 13px;
  font-weight: 100;
  height: 19.89px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.element-ipad-menu .b-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 149.85px;
  justify-content: flex-end;
  margin-left: -181.95px;
  position: relative;
  width: 181.14px;
}

.element-ipad-menu .text-wrapper-5 {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 13px;
  font-weight: 100;
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 181px;
}

.element-ipad-menu .b-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 146.44px;
  justify-content: flex-end;
  margin-bottom: -7.6px;
  margin-left: -181.95px;
  position: relative;
  width: 181.45px;
}

.element-ipad-menu .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.element-ipad-menu .div-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  padding: 0px 20px;
  position: relative;
}

.element-ipad-menu .text-wrapper-6 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element-ipad-menu .text-wrapper-7 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.element-ipad-menu .text-wrapper-7:hover {
  color: #14a5ed;; /* Change to the color of your choosing on hover */
}

.element-ipad-menu .follow-us {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 6px 20px;
  padding: 0px 20px;
  position: relative;
  width: 106px;
}

.element-ipad-menu .text-wrapper-8 {
  color: #e8e8e8;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -13px;
  margin-top: -1px;
  position: relative;
  width: 79px;
}

.element-ipad-menu .frame-4 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 12px;
  margin-right: -13px;
  position: relative;
  width: 79px;
}

.element-ipad-menu .telegram-instance {
  height: 29.03px !important;
  left: unset !important;
  margin-left: -0.74px !important;
  margin-top: -0.74px !important;
  position: relative !important;
  top: unset !important;
  width: 29.03px !important;
  cursor: pointer;
}

.element-ipad-menu .twitter-instance {
  height: 27.55px !important;
  left: unset !important;
  top: unset !important;
  cursor: pointer;
}

.element-ipad-menu .design-component-instance-node {
  width: 28px !important;
}

.element-ipad-menu .medium-instance {
  height: 29.46px !important;
  left: unset !important;
  margin-bottom: -0.73px !important;
  margin-left: -0.74px !important;
  position: relative !important;
  top: unset !important;
  width: 29.47px !important;
  cursor: pointer;
}

.element-ipad-menu .linkedin-instance {
  height: 29.46px !important;
  left: unset !important;
  margin-bottom: -0.73px !important;
  position: relative !important;
  top: unset !important;
  width: 29.47px !important;
  cursor: pointer;
}
